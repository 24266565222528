import React from "react"
import { Container } from "react-bootstrap"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import { AuthProvider } from "../contexts/AuthContext"
import { DatabaseProvider } from "../contexts/DatabaseContext"
import AddNewUser from "./AddNewUser"
import Analytics from "./Analytics"
import AnalyticsV2 from "./AnalyticsV2"
import BasketCreation from "./BasketCreation"
import BasketCreationTokens from "./BasketCreationTokens"
import BasketReports from "./BasketReports"
import BuyProduct from "./BuyProduct"
import CustomerReports from "./CustomerReports"
import CustomerReportsAppV2 from "./CustomerReportsAppV2"
import Dashboard from "./Dashboard"
import DashboardAppV2 from "./DashboardAppV2"
import Exchange from "./Exchange"
import ExchangeSell from "./ExchangeSell"
import ForgotPassword from "./ForgotPassword"
import InitialPage from "./InitialPage"
import LegalReports from "./LegalReports"
import Login from "./Login"
import ManageAppData from "./ManageAppData"
import ManageAssets from "./ManageAssets"
import ManageAssetsAux from "./ManageAssetsAux"
import ManageHeroes from "./ManageHeroes"
import OrderBuyTokensPage from "./OrderBuyTokensPage"
import OrderSellTokensPage from "./OrderSellTokensPage"
import PrivateRoute from "./PrivateRoute"
import SendBulkEmails from "./SendBulkEmails"
import SetUserBasket from "./SetUserBasket"
import Signup from "./Signup"
import UpdateProfile from "./UpdateProfile"
import UpdateTokensValue from "./UpdateTokensValue"
import UsersBuyOrders from "./UsersBuyOrders"
import UsersSellOrders from "./UsersSellOrders"
import UsersTokensBuyOrders from "./UsersTokensBuyOrders"
import UsersTokensSellOrders from "./UsersTokensSellOrders"
import VerifyAccounts from "./VerifyAccounts"

function App() {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: '45rem' }}>
        <Router>
          <AuthProvider>
            <DatabaseProvider>
              <Switch>
                <PrivateRoute exact path="/" component={InitialPage} />
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/dashboardappv2" component={DashboardAppV2} />
                <PrivateRoute path="/add-new-user" component={AddNewUser} />
                <PrivateRoute path="/exchange" component={Exchange} />
                <PrivateRoute path="/users-buy-orders" component={UsersBuyOrders} />
                <PrivateRoute path="/users-sell-orders" component={UsersSellOrders} />
                <PrivateRoute path="/manage-heroes" component={ManageHeroes} />
                <PrivateRoute path="/update-profile" component={UpdateProfile} />
                <PrivateRoute path="/exchange-sell" component={ExchangeSell} />
                <PrivateRoute path="/manage-assets" component={ManageAssets} />
                <PrivateRoute path="/analytics" component={Analytics} />
                <PrivateRoute path="/buy-product" component={BuyProduct} />
                <PrivateRoute path="/verify-accounts" component={VerifyAccounts} />
                <PrivateRoute path="/manage-assets-aux" component={ManageAssetsAux} />
                <PrivateRoute path="/legal-reports" component={LegalReports} />
                <PrivateRoute path="/send-bulk-emails" component={SendBulkEmails} />
                <PrivateRoute path="/basket-reports" component={BasketReports} />
                <PrivateRoute path="/customer-reports" component={CustomerReports} />
                <PrivateRoute path="/customer-reports-appv2" component={CustomerReportsAppV2} />
                <PrivateRoute path="/basket-creation" component={BasketCreation} />
                <PrivateRoute path="/basket-creation-tokens" component={BasketCreationTokens} />
                <PrivateRoute path="/set-user-basket" component={SetUserBasket} />
                <PrivateRoute path="/users-tokens-buy-orders" component={UsersTokensBuyOrders} />
                <PrivateRoute path="/users-tokens-sell-orders" component={UsersTokensSellOrders} />
                <PrivateRoute path="/order-buy-tokens-page" component={OrderBuyTokensPage} />
                <PrivateRoute path="/order-sell-tokens-page" component={OrderSellTokensPage} />
                <PrivateRoute path="/update-tokens-value" component={UpdateTokensValue} />
                <PrivateRoute path="/manage-app-data" component={ManageAppData} />
                <PrivateRoute path="/analytics-appv2" component={AnalyticsV2} />
                <Route path="/signup" component={Signup} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
              </Switch>
            </DatabaseProvider>
          </AuthProvider>
        </Router>
      </div>
    </Container>
  )
}

export default App