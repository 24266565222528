// Originals
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
// import {getFirestore} from 'firebase/firestore/lite'
import { getFunctions } from 'firebase/functions'
import { getStorage } from 'firebase/storage'
import params from '../src/constants/params.json'

// Emulators

// Exports
export { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, updateEmail, updatePassword } from 'firebase/auth'
export { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore'

const firebaseAdminConfig = {
  apiKey: process.env.REACT_APP_ADMIN_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_ADMIN_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ADMIN_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ADMIN_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ADMIN_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ADMIN_FIREBASE_APP_ID
}
const firebaseAppConfig = {
  apiKey: process.env.REACT_APP_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_APP_FIREBASE_MEASUREMENT_ID
}

const firebaseConfigsAppv2 = {
  dev: {
    apiKey: 'AIzaSyB6BO8DT-cfMvbZl9wJYEggS17vM4iNYoA',
    authDomain: 'ch-app-v2-dev.firebaseapp.com',
    projectId: 'ch-app-v2-dev',
    storageBucket: 'ch-app-v2-dev.appspot.com',
    messagingSenderId: '105749831181',
    appId: '1:105749831181:web:3d5fd97a8f27e21a8dbf44',
    measurementId: 'G-5B0FEWCNWT'
  },
  production: {
    apiKey: 'AIzaSyCb5Ov1rGSoWk-1TzVAaeWaq58Rouldvh8',
    authDomain: 'heroinvest-5fabd.firebaseapp.com',
    projectId: 'heroinvest-5fabd',
    storageBucket: 'heroinvest-5fabd.appspot.com',
    messagingSenderId: '347717291975',
    appId: '1:347717291975:web:37f3181e4f26f4717813e2',
    measurementId: 'G-WQJ4F3N2XQ'
  }
}

const firebaseAppv2Config = params.isProduction ? firebaseConfigsAppv2.production : firebaseConfigsAppv2.dev

const adminApp = initializeApp(firebaseAdminConfig)
const appApp = initializeApp(firebaseAppConfig, 'secondary')
const appV2 = initializeApp(firebaseAppv2Config, 'tertiary')

export const adminAuth = getAuth(adminApp)
export const adminDb = getFirestore(adminApp)
export const adminStorage = getStorage(adminApp)
const adminFunctions = getFunctions(adminApp)

export const appAuth = getAuth(appApp)
export const appDb = getFirestore(appApp)
const appFunctions = getFunctions(appApp)

export const appV2Auth = getAuth(appV2)
export const appV2Db = getFirestore(appV2)
export const appV2Storage = getStorage(appV2)
export const appV2Functions = getFunctions(appV2)

// export const EMULATOR = false
// if(EMULATOR) {
//   connectAuthEmulator(appApp, 'http://localhost:9099')
//   connectFirestoreEmulator(appDb, 'localhost', 8080)
//   connectFunctionsEmulator(appFunctions, 'localhost', 5001)
// }

export default adminApp