import React, { useEffect, useState } from 'react'
import { Card, ListGroup, Button, Alert } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { appDb } from '../firebase'
import { collection, getDocs, query, where } from '../firebase'
import { Link } from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import QRCode from 'qrcode'

import OrderSell from './OrderSell'
import { copyToClipboard, secsToDateTime, formatPrice, clog, formatPhone, round, Pix } from '../auxiliary/helpers'


export default function UsersBuyOrders() {

  const QUOTE_ASSET_SYMBOL = 'USDT'
  const FIAT_SYMBOL = 'BRL'
  const BINANCE_FEE = 3.5

  const {currentAdminUser} = useAuth()
  
  const [ready, setReady] = useState(false)
  const [usersOrders, setUsersOrders] = useState([])
  const [buyingPower, setBuyingPower] = useState('loading...')
  const [bnbBalance, setBnbBalance] = useState('loading...')
  const [exchangeAccountInfo, setExchangeAccountInfo] = useState(null)
  const [totalToWithdraw, setTotalToWithdraw] = useState(0)
  // const [usersOrdersList, setUsersOrdersList] = useState([])
  const [notice, setNotice] = useState('')

  const [qrCodeImageUrl, setQrCodeImageUrl] = useState()

  // clog(JSON.stringify(exchangeAccountInfo, null ,2))
  clog('totalToWithdraw')
  clog(totalToWithdraw.toFixed(2) + '  |  ' + (totalToWithdraw + BINANCE_FEE).toFixed(2))

  clog(round(totalToWithdraw, 2))

  useEffect(() => {
    const execute = async () => {

      const pixInfo = {
        pixKey: 'financeiro@cryptohero.com.br',
        description: 'vendas',
        merchantName:'CRYPTOHERO',
        merchantCity: 'SaoPaulo',
        txid: 'vendas',
        amount: round(totalToWithdraw, 2)
      }

      const pix = new Pix(
        pixInfo.pixKey,
        pixInfo.description,
        pixInfo.merchantName,
        pixInfo.merchantCity,
        pixInfo.txid,
        pixInfo.amount
      )
      const pixCode = pix.getPayload()
      const url = await QRCode.toDataURL(pixCode)
      setQrCodeImageUrl(url)
    }
    execute()
  }, [totalToWithdraw])

  useEffect(() => {
    const getAccountInfo = async () => {
      const authTokenId = await currentAdminUser.getIdToken()
      const options = {
          headers: {
              'Authorization': 'Bearer ' + authTokenId,
          }
      }
      /***************************************************/
      const EMULATOR = false
      /***************************************************/
      const baseUrl = EMULATOR ?
      'http://localhost:5001/ch-admin-v1/southamerica-east1/' :
      'https://southamerica-east1-ch-admin-v1.cloudfunctions.net/'
      
      const cloudFunction = 'getExchangeAccountInfo'
      const url = `${baseUrl}${cloudFunction}?uid=${currentAdminUser.uid}&fiatSymbol=${FIAT_SYMBOL}`
      try {
        const data = await fetch(url, options)
        setExchangeAccountInfo(await data.json())
      }
      catch(error) {
        console.log(error)
      }
    }
    getAccountInfo()
  }, [])

  useEffect(() => {
    const setAccountInfo = () => {
      if(Object.keys(exchangeAccountInfo).length === 0) {
        setBuyingPower('Not available')
        setBnbBalance('Not available')
        return
      }
      const availableFiatMaster = parseFloat(exchangeAccountInfo.availableFiatMaster)
      const fiatBalance = FIAT_SYMBOL in exchangeAccountInfo.availableBalancesSub ? 
        parseFloat(exchangeAccountInfo.availableBalancesSub[FIAT_SYMBOL]) :
        0
      const quoteAssetBalance = QUOTE_ASSET_SYMBOL in exchangeAccountInfo.availableBalancesSub ?
        parseFloat(exchangeAccountInfo.availableBalancesSub[QUOTE_ASSET_SYMBOL]) :
        0
      const bnbBalance = 'BNB' in exchangeAccountInfo.availableBalancesSub ?
        parseFloat(exchangeAccountInfo.availableBalancesSub.BNB) :
        0
      const fiat2QuotePrice = parseFloat(exchangeAccountInfo.fiat2QuotePrice)
      const bnb2QuoteTotal = parseFloat(exchangeAccountInfo.bnbPrice*bnbBalance)
      const totalBuyingPower = availableFiatMaster + fiatBalance + quoteAssetBalance*fiat2QuotePrice

      setBuyingPower(totalBuyingPower.toFixed(2) + ' BRL')
      setBnbBalance(bnbBalance.toFixed(3) + ' (' + bnb2QuoteTotal.toFixed(2) + ' USD)')
    }
    if(exchangeAccountInfo) setAccountInfo()
  }, [exchangeAccountInfo])

  useEffect(() => {
    const getOrdersList = async () => {
      const ordersRef = collection(appDb, 'orders')
      const q1 = query(ordersRef, where('status', '==', 'WAITING'), where('orderSide', '==', 'SELL'))
      const querySnapshot = await getDocs(q1)
      if(querySnapshot.empty) {
        setUsersOrders([])
      }
      querySnapshot.forEach((doc) => {
        const order = doc.data()
        order.orderId = doc.id
        setUsersOrders(item => [...item, order])
      })
      setReady(prev => !prev)
    }
    getOrdersList()
  }, [])

  useEffect(() => {
    if(usersOrders.length > 0) {
      const orderedUsersOrders = usersOrders.sort((a, b) => {
        if(a.timeCreation.seconds > b.timeCreation.seconds) return 1
        if(b.timeCreation.seconds > a.timeCreation.seconds) return -1
        return 0
      })
      setUsersOrders(orderedUsersOrders)
    }
  }, [ready])

  useEffect(() => {
    if(usersOrders.length > 0) {
      let total = 0
      for(let i in usersOrders) {
        if(usersOrders[i].userId === 'YETftQD0Y5goflAqDPAixTY3O2h2' ||
        usersOrders[i].userId === 'sKTnnJRneWWnXZLrCjZvt9zOYZQ2' ||
        usersOrders[i].userId === 'ArNikjTrRuNtLXyqkunlu7MmJBZ2' ||
        usersOrders[i].userId === 'Hc73reLZRgNDqd7h7DJlrjVGG4i1' ||
        usersOrders[i].userId === 'g5duVw1idLVSCUdF6fDXnjGcPZ13' ||
        usersOrders[i].userId === 'mdgT7tTVoJag1Zqt4Eh4kkN3gWX2' ||
        usersOrders[i].userId === 'fv0y4cafUfg73YRT5P7Ye6tpuyj1') {
          continue
        }
        const totalToReceive = usersOrders[i].totalToReceive
        const fee = usersOrders[i].fee
        total += totalToReceive + fee
      }
      setTotalToWithdraw(total)
    }
  }, [ready])

  const copyInfo = (info, value) => {
    copyToClipboard(value)
    setNotice(`${info} copied`)
  }

  const copyAllInfo = () => {
    const info = 'SELL INFO'
    let value = ''
    if(ready) {
      for(let i in usersOrders) {
        const userOrder = usersOrders[i]
        value += 'VENDA\n'
        value += 'Time: ' + secsToDateTime(parseFloat(userOrder.timeCreation.seconds)) + '\n'
        value += 'Order: ' + userOrder.orderId + '\n'
        value += 'User name: ' + userOrder.personalInfo.name + '\n'
        value += 'User phone: ' + ('phone' in userOrder.personalInfo ? formatPhone(userOrder.personalInfo.phone) : '') + '\n'
        value += 'PIX:' + '\n'
        value += userOrder.personalInfo.pixCode + '\n'
        value += 'Valor para CLIENTE:' + '\n'
        value += formatPrice(parseFloat(userOrder.totalToReceive), userOrder.fiatSymbol) + '\n'
        value += 'Taxa:' + '\n'
        value += formatPrice(parseFloat(userOrder.fee), userOrder.fiatSymbol) + '\n'
        value += 'Total:' + '\n'
        value += formatPrice(parseFloat(userOrder.totalToReceive) + parseFloat(userOrder.fee), userOrder.fiatSymbol) + '\n'
        value += '--------------------------------------------------------------------------' + '\n'
      }
      value = `TOTAL: ${formatPrice(totalToWithdraw)}\n--------------------------------------------------------------------------\n` + value
    }
    copyInfo(info, value)
  }

  const orders = (exchangeAccountInfo && usersOrders.length > 0) ? usersOrders.map(order => {
    return(<OrderSell
        key = {order.orderId}
        order = {order}
        buyingPower= {buyingPower}
        bnbBalance= {bnbBalance}
        />
    )
  })
  : null

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className='text-center mb-4'>Users Sell Orders</h2>
          <p className='mb-4'>You have {usersOrders.length} pending order(s):</p>
          <br></br>
          <br></br>
          <Card style={{ width: '40rem' }}>
            <p>Account Info:</p>
            <ListGroup variant='flush'>
            <ListGroup.Item>Buying Power: {buyingPower}</ListGroup.Item>
            <ListGroup.Item>BNB balance: {bnbBalance}</ListGroup.Item>
            </ListGroup>
            <br></br>
            {currentAdminUser.email === 'mauricio@cryptohero.com.br' &&
              <Image style={{width: '30vw', borderRadius: '10px', padding: 0, margin: 0,}} src={qrCodeImageUrl} alt='QR Code'/>}
            <Button onClick={copyAllInfo} disabled={totalToWithdraw === 0 || !ready}>
              COPY ALL INFO
            </Button>
            <br></br>
            {notice && <Alert variant='primary'>{notice}</Alert>}
            <br></br>
            <p>User Order List:</p>
            {orders}
          </Card>
          <br></br>
        </Card.Body>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}