import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useState } from 'react';
import { Alert, Button, Image } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { clog, darkenHexColor, round } from '../auxiliary/helpers';
import mockData from '../data/mockDataCreateBasket.json';
import { appV2Db, appV2Storage } from '../firebase';
import { Link } from 'react-router-dom';
import { formatString } from '../auxiliary/helpers';


const FIAT_SYMBOL = 'BRL'
const STABLE_COIN_SYMBOL = 'USDT'

function BasketCreation() {

  


  const [coins, setCoins] = React.useState(mockData.coins)
  const [quantity, setQuantity] = useState(0)
  const [newBasketName, setNewBasketName] = React.useState('')
  const [newBasketImage, setNewBasketImage] = React.useState(null)

  const [basketSucessfullyCreated, setBasketSucessfullyCreated] = React.useState(false)
  const [basketErrorCreation, setBasketErrorCreation] = React.useState(false)

  const [coinSucessfullyCreated, setCoinSucessfullyCreated] = React.useState(false)
  const [coinErrorCreation, setCoinErrorCreation] = React.useState(false)

  const [newCoinName, setNewCoinName] = React.useState('')
  const [newCoinSymbol, setNewCoinSymbol] = React.useState('')
  const [newCoinImage, setNewCoinImage] = React.useState(null)
  const [newCoinShortDescription, setNewCoinShortDescription] = React.useState('')
  const [newCoinDescription, setNewCoinDescription] = React.useState('')
  const [newCoinProjectUrl, setNewCoinProjectUrl] = React.useState('')
  const [newCoinMainColor, setNewCoinMainColor] = React.useState('')

  const [newCoin, setNewCoin] = React.useState({
    newCoinName: '',
    newCoinSymbol: '',
    newCoinImageUrl: '',
    newCoinShortDescription: '',
    newCoinDescription: '',
    newCoinProjectUrl: '',
  })

  const [lastSelectedCoin, setLastSelectedCoin] = React.useState({
    name: '',
    symbol: '',
    coinImageUrl: '',
  });

  const [newBasket, setNewBasket] = React.useState({
    basketName: '',
    basketImgUrl: '',
    coins: [{
    percentage: 0,
    name: '',
    symbol: '',
    coinImageUrl: '',
}]})


  clog(coins)

  const handleSelection = async (eventKey) => {
    const selectedCoin = coins.find(coin => coin.name === eventKey)
    clog(selectedCoin)
    /* setLastSelectedCoin(selectedCoin) */
    setLastSelectedCoin((prev) => ({name: selectedCoin.name, symbol: selectedCoin.symbol, coinImageUrl: getCoinImageUrl(selectedCoin.symbol)}))
    clog(lastSelectedCoin)
  }

  const addNewCoin = () => {
    

    if(newBasket.coins.length !== 0 && newBasket.coins[0].name === '') {

      setNewBasket((prevBasket) => ({
        ...prevBasket,  // Keep the existing basketName and basketImgUrl
        coins: [
          {
            assetType: 'cryptocurrency',
            percentage: round(quantity/100, 2),
            name: lastSelectedCoin.name,
            symbol: (lastSelectedCoin.symbol).toUpperCase(),
          },
        ],
      }));
      return
    }
    else {

      setNewBasket((prevBasket) => ({
        ...prevBasket,  // Keep the existing basketName and basketImgUrl
        coins: [
          ...prevBasket.coins,  // Keep the existing coins
          {
            assetType: 'cryptocurrency',
            percentage: round(quantity/100, 2),
            name: lastSelectedCoin.name,
            symbol: lastSelectedCoin.symbol,
          },
        ],
      }));
      
    }
    clog("CESTA")
    clog(newBasket)
  }

  const createNewBasket = async () => {
    setBasketSucessfullyCreated(false)
    setBasketErrorCreation(false)

    let totalSum = 0
    newBasket.coins.forEach(coin => {
      totalSum += coin.percentage
    })
    if(round(totalSum, 2) !== 1) {
      alert(`Erro: A soma das porcentagens deve ser igual a 100, atualmente é: ${totalSum}. Altere e tente novamente.`)
      return
    }

    const storageRef = ref(appV2Storage);
    /* const basketImageRef = ref(appV2Storage, `baskets_images/${newBasketImage.name}`); */
    /* const imageRef = storageRef.child(`basket_images/${newBasketName}.jpg`); */

    /* uploadBytes(basketImageRef, newBasketImage).then((snapshot) => {
      console.log('Imagem da cesta enviada com sucesso!');

      
      getDownloadURL(basketImageRef).then((downloadUrl) => {
        console.log("Download Url: ", downloadUrl)
        setNewBasket((prev) => ({basketImgUrl: downloadUrl, basketName: newBasketName, coins: prev.coins}))
        
        const newBasketToUpload = {name: newBasketName, assets: newBasket.coins}
        clog("CESTA CRIADA:")
        clog(newBasketToUpload)
        
        addDoc(collection(appV2Db, "advisorsBaskets"), newBasketToUpload).then((docRef) => {
          console.log('Document written with ID: ', docRef.id);
          setBasketSucessfullyCreated(true);
        }).catch((error) => {
          setBasketErrorCreation(true);
          console.error('Error creating Firestore document:', error);
        })
        

      }).catch((error) => {
        setBasketErrorCreation(true)
        clog(basketErrorCreation)
        console.error('Erro ao baixar imagem da cesta', error)
      })

    }).catch((error) => {
      setBasketErrorCreation(true)
      clog(basketErrorCreation)
      console.error('Erro ao subir imagem da cesta', error)
    }) */

    //outra maneira de fazer isso seria puxar tds as cestas e ai iterar por elas comparando newBasketName.toLowerCase() === doc.data().name.toLowerCase() e se for igual, dar erro, se não, criar a cesta
    //talvez eu mude dps para isso /\, pensei dps q fiz
    const collectionRef = collection(appV2Db, "advisorsBaskets")
    const q1 = query(collectionRef, where("name", "==", newBasketName));
    const q2 = query(collectionRef, where("name", "==", newBasketName.toUpperCase()));
    const capitalizedName = newBasketName.charAt(0).toUpperCase() + newBasketName.slice(1)
    const q3 = query(collectionRef, where("name", "==", capitalizedName));
    const q4 = query(collectionRef, where("name", "==", newBasketName.toLowerCase()));
    const q5 = query(collectionRef, where("name", "==", formatString(newBasketName)));
    const querySnapshot1 = await getDocs(q1);
    const querySnapshot2 = await getDocs(q2);
    const querySnapshot3 = await getDocs(q3);
    const querySnapshot4 = await getDocs(q4);
    const querySnapshot5 = await getDocs(q5);
    if(!querySnapshot1.empty || !querySnapshot2.empty || !querySnapshot3.empty || !querySnapshot4.empty || !querySnapshot5.empty) {
      alert("Uma cesta com esse nome já existe no banco de dados, tente outro nome.")
      return;
    }


    setNewBasket((prev) => ({/* basketImgUrl: downloadUrl,  */basketName: formatString(newBasketName), coins: prev.coins}))
        
    const newBasketToUpload = {name: formatString(newBasketName), assets: newBasket.coins}
    clog("CESTA CRIADA:")
    clog(newBasketToUpload)
        
    addDoc(collectionRef, newBasketToUpload).then((docRef) => {
      console.log('Document written with ID: ', docRef.id);
      setBasketSucessfullyCreated(true);
    }).catch((error) => {
      setBasketErrorCreation(true);
      console.error('Error creating Firestore document:', error);
    })

    //clean the inputs, comment the block of code below if you dont want this.
    setNewBasketName('')
    /* setNewBasketImage(null) */
    const imageInput = document.getElementById('basketImage');
    if (imageInput) {
      imageInput.value = '';
    }
    setNewBasket({basketName: '', /* basketImgUrl: '', */ coins: [{percentage: 0, name: '', symbol: '', coinImageUrl: ''}]})
    setLastSelectedCoin({name: '', symbol: '', coinImageUrl: ''})
  }

  const removeCoin = (coinToRemove) => {
    if(newBasket.coins.lenght === 0){
      clog("CESTA VAZIA")
      return
    }
    clog("feito ")
    const newFilteredBasket = {/* basketImgUrl: newBasket.basketImgUrl,  */basketName: newBasket.basketName, coins: newBasket.coins.filter(coin => coin.symbol !== coinToRemove.symbol)}
    /* const newFilteredBasket = newBasket.coins.filter(coin => coin.symbol !== coinToRemove.symbol) */
    clog(newFilteredBasket)
    setNewBasket(newFilteredBasket)
  }

  const handleNewCoinNameChange = (event) => {
    setNewCoinName(event.target.value);
  };

  const handleNewCoinSymbolChange = (event) => {
    setNewCoinSymbol(event.target.value);
  };

  const handleNewCoinDescriptionChange = (event) => {
    setNewCoinDescription(event.target.value);
  };

  const handleNewCoinShortDescriptionChange= (event) => {
    setNewCoinShortDescription(event.target.value);
  };

  const handleNewCoinProjectUrlChange= (event) => {
    setNewCoinProjectUrl(event.target.value);
  };

  const handleNewCoinMainColorChange= (event) => {
    setNewCoinMainColor(event.target.value);
  };

  const handleNewCoinImageChange = (event) => {
    setNewCoinImage(event.target.files[0]);
  };

  const handleSubmitNewCoin = (event) => {
    event.preventDefault();

    setCoinSucessfullyCreated(false)
    setCoinErrorCreation(false)

    const storageRef = ref(appV2Storage);
    const coinImageRef = ref(appV2Storage, `assets_images/${newCoinImage.name}`);

    const symbolToSearch = newCoinSymbol.toUpperCase();
      getDoc(doc(appV2Db, `cryptocurrencies/${symbolToSearch}`)).then((docSnapshot) => {
        if(docSnapshot.exists()) {
          alert("Essa moeda já existe no banco de dados: ", docSnapshot.id)
          setCoinErrorCreation(true)
          return;
        }
        else {
          if(newCoinImage.name !== `${newCoinSymbol.toLowerCase()}-logo.png`) {
            console.error("ATENÇÃO! A imagem da moeda deve ter o nome no formato: 'symbol-logo', por ex: 'btc-logo'")
            alert("ATENÇÃO! A imagem da moeda deve ter o nome no formato: 'symbol-logo', por ex: 'btc-logo'")
            setCoinErrorCreation(true)
            return
          }
          uploadBytes(coinImageRef, newCoinImage).then((snapshot) => {
            console.log('Imagem da moeda enviada com sucesso!');
      
            getDownloadURL(coinImageRef).then((downloadUrl) => {
              console.log("Coin Image Download Url: ", downloadUrl)
              const capitalizedName = newCoinName.charAt(0).toUpperCase() + newCoinName.slice(1)
              setNewCoin({newCoinName: capitalizedName, imageUrl: downloadUrl, newCoinSymbol: newCoinSymbol.toUpperCase(), newCoinDescription: newCoinDescription, newCoinShortDescription: newCoinShortDescription, newCoinProjectUrl: newCoinProjectUrl})
              //create document in firestore
              const newCoinToAdd = {name: capitalizedName, symbol: newCoinSymbol.toUpperCase(), shortDescription: newCoinShortDescription, description: newCoinDescription, projectUrl: newCoinProjectUrl, mainColor: newCoinMainColor, darkerColor: darkenHexColor(newCoinMainColor, 0.5)}
              

              setDoc(doc(appV2Db, `cryptocurrencies/${newCoinToAdd.symbol}`), newCoinToAdd).then(() => {
                setCoinSucessfullyCreated(true)
                clog(`Document coin written with ID: ${newCoinToAdd.symbol}`)
              }).catch((error) => {
                setCoinErrorCreation(true)
                console.error("Error creating coin document: ", error)
              })
      
      /* 
              clog("Moeda criada com sucesso:")
              clog(newCoin) */
            }).catch((error) => {
              setCoinErrorCreation(true)
              console.error('Erro ao baixar imagem da moeda', error)
            })
      
          }).catch((error) => {
            setCoinErrorCreation(true)
            console.error('Erro ao subir imagem da moeda', error)
          })
        }
      })


    console.log("Coin Name:", newCoinName);
    console.log("Coin Symbol:", newCoinSymbol);

    setNewCoinName("");
    setNewCoinSymbol("");
    setNewCoinImage(null);
  }

  const handleNewBasketNameChange = (event) => {
    setNewBasketName(event.target.value);
  };

  const handleNewBasketImageChange = (event) => {
    setNewBasketImage(event.target.files[0]);
    clog(newBasketImage)
  };

  const loadCoinsFromFirestore = async () => {
    const querySnapshot = await getDocs(collection(appV2Db, "cryptocurrencies"));
    const coinsData = [];
    querySnapshot.forEach((doc) => {
      /* coinsData.push(doc.data()); */
      coinsData.push({name: doc.data().name, symbol: doc.data().symbol, coinImageUrl: ''})
    });
    clog(coinsData)
    setCoins(coinsData);
  };

  // Chame a função para carregar os dados das moedas ao montar o componente
  React.useEffect(() => {
    loadCoinsFromFirestore();
  }, []);

  /* const getCoinImageUrl = (symbol) => {
    getDownloadURL(ref(appV2Storage, `assets_images/${symbol.toLowerCase()}-logo.png`)).then((downloadUrl) => {
      console.log("Coin Image Download Url: ", downloadUrl)
      return downloadUrl
    }).catch((error) => {
      console.error(`Erro ao baixar imagem da moeda ${symbol}`, error)
    })
  } */

  const getCoinImageUrl = (symbol) => {
    const url = `https://firebasestorage.googleapis.com/v0/b/ch-app-v2-dev.appspot.com/o/assets_images%2F${symbol.toLowerCase()}-logo.png?alt=media&token=195f4836-b73f-4e11-bfc2-8922f9850af7&_gl=1*13r85sx*_ga*MTIzMjg0MDYxNC4xNjk3NzE5NzUx*_ga_CW55HF8NVT*MTY5NzcyMjE1MS4yLjEuMTY5NzcyMzQ2Ni4zNS4wLjA.`
    return url;
  }
  
  

  return (
    <>
      
      <h1 className="h3 mb-4 text-gray-800">Criar cesta</h1>
      <p style={{color: 'red'}}>ATENÇÃO! A imagem da cesta deve ter o nome no formato: "nomedacesta-logo", por ex: "investimentoseguro-logo"</p>
      <div>
        <label htmlFor="basketName" className="mr-2">Nome da cesta:</label>
        <input
          type="text"
          id="basketName"
          /* placeholder='Bitcoin' */
          value={newBasketName}
          onChange={handleNewBasketNameChange}
          /* required */
        />
      </div>
      {/* <div>
          <label htmlFor="basketImage" className="mr-2 mb-2">Imagem da cesta:</label>
          <input
            type="file"
            id="basketImage"
            onChange={handleNewBasketImageChange}
            accept="image/*"
          />
        </div> */}
      
      <DropdownButton className="mt-2" onSelect={handleSelection} id="dropdown-basic-button" title="Clique para escolher moeda">
        {coins.map((coin, index) => (
          <Dropdown.Item eventKey={coin.name} key={index} href={`#/coin-${index}`}>{coin.name}</Dropdown.Item>
        ))}
    </DropdownButton>

    {lastSelectedCoin.name && (
    <div style={{marginTop: '5%', display: 'flex', flexDirection: 'row', gap: 20}}>
      <Image style={{height: 40, width: 40}} src={lastSelectedCoin.coinImageUrl} roundedCircle />
      <p className="text-gray-800">{lastSelectedCoin.symbol}</p>
      <input
            type="number"
            placeholder="Quantidade"
            value={quantity}
            min={0}
            max={100}
            onChange={(e) => { 
              let value = parseFloat(e.target.value);
              if (value < 0) {
                value = 0;
              } 
              else if (value > 100) {
                value = 100;
              }
              setQuantity(value)}
            }
          />
      <Button variant='primary' className='w-100' onClick={() => addNewCoin()}>
          Adicionar moeda
      </Button>
    </div>
    )}

    {newBasket.coins.length !== 0 && newBasket.coins[0].name !== '' && (
      <div>
        <h1 className="h4 mt-4 text-gray-600">Cesta atual:</h1>
        {newBasket.coins.map((coin, index) => (
          <div style={{marginTop: '5%', display: 'flex', flexDirection: 'row', gap: 20, justifyContent: 'center', alignItems: 'center'}} key={index}>
            <Image style={{height: 40, width: 40}} src={getCoinImageUrl(coin.symbol)} roundedCircle />
            <p className="text-gray-800">{coin.symbol}</p>
            <p className="text-gray-800">{coin.percentage * 100}%</p>
            <Button variant='danger' className='w-100' onClick={() => removeCoin(coin)}>Remover da cesta</Button>
          </div>
        ))}
      </div>
    )}
    {basketSucessfullyCreated && (
      <Alert variant='success' className='mt-1'>Cesta criada com sucesso!</Alert>
    )}
    {basketErrorCreation && (
      <Alert variant='danger' className='mt-1'>Erro ao criar cesta! Cheque o console.</Alert>
    )}
    <Button variant='primary' className='w-100 mt-3' onClick={createNewBasket}>
      Criar cesta
    </Button>
    

    <h1 className="h3 mb-4 mt-4 text-gray-800">Criar nova moeda</h1>
    <form onSubmit={handleSubmitNewCoin}>
      <p style={{color: 'red'}}>ATENÇÃO! A imagem da moeda deve ter o nome no formato: "symbol-logo", por ex: "btc-logo"</p>
    <div>
      <label htmlFor="name" className="mr-2">Nome da moeda:</label>
      <input
        type="text"
        id="name"
        placeholder='Bitcoin'
        value={newCoinName}
        onChange={handleNewCoinNameChange}
        required
      />
       <div>
          <label htmlFor="symbol" className="mr-2">Simbolo da moeda:</label>
          <input
            type="text"
            id="symbol"
            placeholder='BTC'
            value={newCoinSymbol}
            onChange={handleNewCoinSymbolChange}
            required
          />
        </div>
        <div>
          <label htmlFor="shortDescription" className="mr-2">Descrição curta:</label>
          <input
            type="text"
            id="shortDescription"
            placeholder='O ouro digital.'
            value={newCoinShortDescription}
            onChange={handleNewCoinShortDescriptionChange}
            required
          />
        </div>
        <div>
          <label htmlFor="description" className="mr-2">Descrição detalhada:</label>
          <input
            type="text"
            id="description"
            placeholder='O Bitcoin foi a primeira moeda descentralizada do mundo. Isso significa que...'
            value={newCoinDescription}
            onChange={handleNewCoinDescriptionChange}
            required
          />
        </div>
        <div>
          <label htmlFor="projecturl" className="mr-2">Url do projeto da moeda:</label>
          <input
            type="text"
            id="projecturl"
            placeholder='https://bitcoin.org/en/'
            value={newCoinProjectUrl}
            onChange={handleNewCoinProjectUrlChange}
            required
          />
        </div>
        <div>
          <label htmlFor="coincolor" className="mr-2">Cor principal da moeda (em hex)</label>
          <input
            type="text"
            id="coincolor"
            placeholder='#f7931a'
            value={newCoinMainColor}
            onChange={handleNewCoinMainColorChange}
            required
          />
        </div>
        <div>
          <label htmlFor="coinImage" className="mr-2">Imagem da moeda:</label>
          <input
            type="file"
            id="coinImage"
            onChange={handleNewCoinImageChange}
            accept="image/*"
            required
          />
        </div>
        {coinSucessfullyCreated && (
          <Alert variant='success' className='mt-1'>Moeda criada com sucesso!</Alert>
        )}
        {coinErrorCreation && (
          <Alert variant='danger' className='mt-1'>Erro ao criar moeda! Cheque o console.</Alert>
        )}
        <Button type='submit' variant='primary' className='w-100 mt-4'>Criar moeda</Button>
    </div>
    </form>
    <div className="w-100 text-center mt-2">
        <Link to="/dashboardappv2">Voltar</Link>
      </div>

    {/* {newCoin.name !== '' && <div>
      <p>Nome da Ultima moeda: {newCoin.newCoinName}</p>
      <p>Symbol da ultima moeda: {newCoin.newCoinSymbol}</p>
      <p>Url da ultima moeda: {newCoin.newCoinImageUrl}</p>
      <Image style={{height: 40, width: 40}} src={newCoin.newCoinImageUrl} roundedCircle />
    </div>} */}
    
    </>
  )
}

export default BasketCreation
