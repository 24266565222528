import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { secsToDateTime, formatPrice, formatCpf } from '../auxiliary/helpers'

export default function OrderBuyTokensComponent(props) {

  const amountToPay = 
    formatPrice(parseFloat(props.order.investedValue) + parseFloat(props.order.fee))


  const orderStyle = {
    cursor: 'pointer',
    color: 'blue'
  }

  return (
    <ListGroup variant='flush'>
      <ListGroup.Item style={orderStyle}>
        {props.order.basketInfo[0].assetType !== "token" && <p style={{color: 'black'}}>
          Time: {secsToDateTime(parseFloat(props.order.timeCreation))}
          <br></br>
          OrderId: {props.orderId}
          <br></br>
          Type: {props.order.basketInfo[0].assetType}
          <br></br>
          Name: {props.order.userInfo.name}
          <br></br>
          Email: {props.order.userInfo.email}
          <br></br>
          CPF: {formatCpf(props.order.userInfo.cpf)}
          <br></br>
          Cesta: {props.order.basketName}
          <br></br>
      {/*     Hero: {props.order.heroId}
          <br></br> */}
          Total Payed: {amountToPay}
        </p>}
        {props.order.basketInfo[0].assetType === 'token' && <Link to={{
          pathname: '/order-buy-tokens-page',
          state: {
           orderId: props.orderId,
           basketInfo: props.order.basketInfo,
           investedValue: props.order.investedValue,
          }
          }}>
          Time: {secsToDateTime(parseFloat(props.order.timeCreation))}
          <br></br>
          OrderId: {props.orderId}
          <br></br>
          Type: {props.order.basketInfo[0].assetType}
          <br></br>
          Name: {props.order.userInfo.name}
          <br></br>
          Email: {props.order.userInfo.email}
          <br></br>
          CPF: {formatCpf(props.order.userInfo.cpf)}
          <br></br>
          Cesta: {props.order.basketName}
          <br></br>
      {/*     Hero: {props.order.heroId}
          <br></br> */}
          Total Payed: {amountToPay}
        </Link>}
        </ListGroup.Item>
    </ListGroup>
  )
}