import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Card, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { appV2Db, collection, getDocs, query, where } from '../firebase'

import { clog, fetchUrl } from '../auxiliary/helpers'
import OrderBuyTokensComponent from './OrderBuyTokensComponent'
import { formatPrice } from '@mauchero/helpers'

export default function UsersTokensBuyOrders() {

  const QUOTE_ASSET_SYMBOL = 'USDT'
  const FIAT_SYMBOL = 'BRL'

  const {currentAdminUser, currentAppV2User} = useAuth()
  
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(true)
  const [usersOrders, setUsersOrders] = useState([])
  const [listOpenOrders, setListOpenOrders] = useState(false)
  const [listOpenPayedOrders, setListOpenPayedOrders] = useState(false)
  const [listFulfilledOrders, setListFulfilledOrders] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [error, setError] = useState('')
  const [tokensToBuy, setTokensToBuy] = useState([])

  const inititalDateRef = useRef('')

  useEffect(() => {
    const fetchOrders = async () => {
      let ordersTotal = 0
        setUsersOrders([])
        setTokensToBuy([])
        setSuccessMessage('')
        setError('')
        setWaitMessage('Aguarde...')
        clog(`BUSCANDO ORDENS...`)
      
        const ordersRef = collection(appV2Db, 'ordersBuy')
        const q1 = query(ordersRef, where('pixStatus', '==', 'PAYED'), where('status', '!=', 'FULFILLED'))
        const querySnapshot = await getDocs(q1)
        
        if(querySnapshot.empty) {
          clog(`querySnapshot.empty = ${querySnapshot.empty}`)
          setUsersOrders([])
          setTokensToBuy([])
        }
        
        const tokensTotal = []
        querySnapshot.forEach((doc) => {
          const order = doc.data();
          order.orderId = doc.id;
          clog(` order.orderId = ${order.orderId}`)
          /* order.type = 'basket' */
          setUsersOrders(item => [...item, order])
          ordersTotal++

          if(order.basketInfo[0].assetType === 'token'){
            for(let i = 0; i < order.basketInfo.length; i++){
              const asset = order.tokensFulfillmentResponse[i];
              const symbol = asset.symbol;
              tokensTotal[symbol] = tokensTotal[symbol] ? {value: tokensTotal[symbol].value + (asset.baseOrderQty * asset.tradedAveragePrice), qty: asset.baseOrderQty} : {value: (asset.baseOrderQty * asset.tradedAveragePrice), qty: asset.baseOrderQty};
            }
          }

          setTokensToBuy(tokensTotal);
          clog(tokensTotal)

        }) 
  
        setReady(prev => !prev)
        setWaitMessage('')
        const finalMessage = ordersTotal <= 1 ? `Foi encontrada ${ordersTotal} ordem` : `Foram encontradas ${ordersTotal} ordens`
        setSuccessMessage(() => finalMessage)
      
    }
    fetchOrders();
  }, [])


  

  useEffect(() => {
    if(usersOrders.length > 0) {
      const orderedUsersOrders = usersOrders.sort((a, b) => {
        if(a.timeCreation > b.timeCreation) return 1
        if(b.timeCreation > a.timeCreation) return -1
        return 0
      })
      setUsersOrders(orderedUsersOrders)
      setLoading(false)
    }
  }, [ready])

  const orders = (usersOrders.length > 0) ? usersOrders.map(order => {
    return(<OrderBuyTokensComponent
        key = {order.orderId}
        orderId = {order.orderId}
        order = {order}
        />
    )
  })
  : null

  const tokens = Object.entries(tokensToBuy).map(([symbol, value]) => {
    return (
      <ListGroup.Item key={symbol}>
        {symbol}: Quantidade: {value.qty}, valor: {formatPrice(value.value)}
      </ListGroup.Item>
    );
  });
  

  const confirmBuyAll = async () => {
    clog(JSON.stringify(usersOrders, null, 2))
    // return
    const ordersIdsList = usersOrders.map(order => {
      return order.orderId
    })
    clog(JSON.stringify(ordersIdsList, null, 2))

    /***************************************************/
    const EMULATOR = true
    /***************************************************/
    const cloudFunction = 'autoBuyBaskets'
    const queryData = [
      {
        name: 'ordersIdsList',
        // value: JSON.stringify({list: ordersIdsList}),
        value: JSON.stringify(ordersIdsList),
      },
    ]
    const response = await fetchUrl(currentAppV2User, 'app', EMULATOR, cloudFunction, queryData)
    // clog(`resp = ${response.resp}`)
  }

  return (
    <>
      <Card>
      <br></br>
      <br></br>
      <br></br>
        {waitMessage && <Alert variant='warning'>{waitMessage}</Alert>}
        {successMessage && <Alert variant='success'>{successMessage}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        <br></br>
        <br></br>
        <Card.Body>
          <h2 className='text-center mb-4'>Users Payed Buy Orders</h2>
          <p className='mb-4'>You have {usersOrders.length} pending order(s):</p>
          <br></br>
          <br></br>
          <Card style={{ width: '40rem' }}>
            <p>Tokens total:</p>
            <ListGroup variant='flush'>
              <ListGroup.Item>{tokens}</ListGroup.Item>
            </ListGroup>
            <p>User Order List:</p>
            {orders}
          </Card>
          {/* <br></br>
          <Button variant='success' disabled={loading} className='w-100' onClick={confirmBuyAll}>
            Buy All
          </Button> */}
          <br></br>
        </Card.Body>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Link to='/dashboardappv2'>Voltar</Link>
      </div>
    </>
  )
}