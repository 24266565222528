import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { secsToDateTime, formatPrice, formatCpf } from '../auxiliary/helpers'

export default function OrderSellTokensComponent(props) {

  const amountToPay = 
    formatPrice(parseFloat(props.order.totalToReceive) + parseFloat(props.order.fee))


  const orderStyle = {
    cursor: 'pointer',
    color: 'blue'
  }

  return (
    <ListGroup variant='flush'>
      <ListGroup.Item style={orderStyle}>
        <Link to={{
          pathname: '/order-sell-tokens-page',
          state: {
           orderId: props.orderId,
           basketInfo: props.order.basketInfo,
           investedValue: props.order.investedValue,
           userInfo: {
              name: props.order.userInfo.name,
              email: props.order.userInfo.email,
              cpf: props.order.userInfo.cpf,
              pixKey: props.order.userPixKey
            },
            totalToReceive: props.order.totalToReceive,
            actualDate: Math.floor(new Date().getTime() / 1000),
            tokensFulfillmentResponse: props.order.tokensFulfillmentResponse,
          }
          }}>
          Time: {secsToDateTime(parseFloat(props.order.timeCreation))}
          <br></br>
          OrderId: {props.orderId}
          <br></br>
          Type: {props.order.basketInfo[0].assetType}
          <br></br>
          Name: {props.order.userInfo.name}
          <br></br>
          Email: {props.order.userInfo.email}
          <br></br>
          CPF: {formatCpf(props.order.userInfo.cpf)}
          <br></br>
          {/* Cesta: {props.order.basketName}
          <br></br> */}
      {/*     Hero: {props.order.heroId}
          <br></br> */}
          Total to Receive: {amountToPay}
        </Link>
        </ListGroup.Item>
    </ListGroup>
  )
}