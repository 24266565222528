import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Card, Form, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { appDb, collection, getDocs, query, where } from '../firebase'

import { clog, fetchUrl, toTimestamp } from '../auxiliary/helpers'
import OrderBuy from './OrderBuy'

export default function UsersBuyOrders() {

  const QUOTE_ASSET_SYMBOL = 'USDT'
  const FIAT_SYMBOL = 'BRL'

  const {currentAdminUser, currentAppUser} = useAuth()
  
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(true)
  const [usersOrders, setUsersOrders] = useState([])
  const [buyingPower, setBuyingPower] = useState('loading...')
  const [buyingUsdPower, setBuyingUsdPower] = useState(0)
  const [bnbBalance, setBnbBalance] = useState('loading...')
  const [exchangeAccountInfo, setExchangeAccountInfo] = useState(null)
  const [listOpenOrders, setListOpenOrders] = useState(false)
  const [listOpenPayedOrders, setListOpenPayedOrders] = useState(false)
  const [listFulfilledOrders, setListFulfilledOrders] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [error, setError] = useState('')

  const inititalDateRef = useRef('')

  useEffect(() => {
    const getAccountInfo = async () => {
      const authTokenId = await currentAdminUser.getIdToken()
      const options = {
          headers: {
              'Authorization': 'Bearer ' + authTokenId,
          }
      }
      /***************************************************/
      const EMULATOR = false
      /***************************************************/
      const baseUrl = EMULATOR ?
      'http://localhost:5001/ch-admin-v1/southamerica-east1/' :
      'https://southamerica-east1-ch-admin-v1.cloudfunctions.net/'
      
      const cloudFunction = 'getExchangeAccountInfo'
      const url = `${baseUrl}${cloudFunction}?uid=${currentAdminUser.uid}&fiatSymbol=${FIAT_SYMBOL}`
      try {
        const data = await fetch(url, options)
        setExchangeAccountInfo(await data.json())
      }
      catch(error) {
        console.log(error)
      }
    }
    getAccountInfo()
  }, [])

  useEffect(() => {
    const setAccountInfo = () => {
      if(Object.keys(exchangeAccountInfo).length === 0) {
        setBuyingPower('Not available')
        setBnbBalance('Not available')
        return
      }
      const availableFiatMaster = parseFloat(exchangeAccountInfo.availableFiatMaster)
      const fiatBalance = FIAT_SYMBOL in exchangeAccountInfo.availableBalancesSub ? 
        parseFloat(exchangeAccountInfo.availableBalancesSub[FIAT_SYMBOL]) :
        0
      const quoteAssetBalance = QUOTE_ASSET_SYMBOL in exchangeAccountInfo.availableBalancesSub ?
        parseFloat(exchangeAccountInfo.availableBalancesSub[QUOTE_ASSET_SYMBOL]) :
        0
      const bnbBalance = 'BNB' in exchangeAccountInfo.availableBalancesSub ?
        parseFloat(exchangeAccountInfo.availableBalancesSub.BNB) :
        0
      const fiat2QuotePrice = parseFloat(exchangeAccountInfo.fiat2QuotePrice)
      const bnb2QuoteTotal = parseFloat(exchangeAccountInfo.bnbPrice*bnbBalance)
      const totalBuyingPower = availableFiatMaster + fiatBalance + quoteAssetBalance*fiat2QuotePrice

      setBuyingPower(totalBuyingPower.toFixed(2) + ' BRL')
      setBuyingUsdPower((totalBuyingPower/fiat2QuotePrice))
      setBnbBalance(bnbBalance.toFixed(3) + ' (' + bnb2QuoteTotal.toFixed(2) + ' USD)')
    }
    if(exchangeAccountInfo) setAccountInfo()
  }, [exchangeAccountInfo])

  const fetchOrders = async () => {
    let ordersTotal = 0
    if(inititalDateRef.current.value) {
      setUsersOrders([])
      setSuccessMessage('')
      setError('')
      setWaitMessage('Aguarde...')
      clog(`BUSCANDO ORDENS...`)
      const inititalDate = toTimestamp(inititalDateRef.current.value)
      const ordersRef = collection(appDb, 'orders')
      let q1
      if(listOpenOrders) {
        q1 = query(ordersRef, where('status', '==', 'OPEN'), where('orderSide', '==', 'BUY'), where('userId', '!=', 'YETftQD0Y5goflAqDPAixTY3O2h2'))
      }
      else if(listOpenPayedOrders) {
        q1 = query(ordersRef, where('status', '==', 'OPEN'), where('orderSide', '==', 'BUY'), where('pixStatus', '==', 'CONCLUIDA'), where('userId', '!=', 'YETftQD0Y5goflAqDPAixTY3O2h2'))
      }
      else if(listFulfilledOrders) {
        q1 = query(ordersRef, where('status', '==', 'FULFILLED'), where('orderSide', '==', 'BUY'), where('pixStatus', '==', 'CONCLUIDA'), where('userId', '!=', 'YETftQD0Y5goflAqDPAixTY3O2h2'))
      }
      else {
        setWaitMessage('')
        setError('Escolha uma opção')
        return
      }
      clog(q1)
      const querySnapshot = await getDocs(q1)
      if(querySnapshot.empty) {
        clog(`querySnapshot.empty = ${querySnapshot.empty}`)
        setUsersOrders([])
      }
      querySnapshot.forEach((doc) => {
        const order = doc.data()
        order.orderId = doc.id
        clog(` order.orderId = ${order.orderId}`)
        clog(` inititalDate = ${inititalDate}`)
        clog(`order.timeCreation.seconds = ${order.timeCreation.seconds}`)
        order.type = 'basket'
        if(order.timeCreation.seconds >= inititalDate) {
          setUsersOrders(item => [...item, order])
          ordersTotal++
        }
      })
  
      const productsOrdersRef = collection(appDb, 'productsOrders')
      const q2 = query(productsOrdersRef, where('status', '==', 'OPEN'), where('pixStatus', '==', 'CONCLUIDA'))
      // const q2 = query(productsOrdersRef, where('status', '==', 'OPEN'))
      const querySnapshot2 = await getDocs(q2)
      ordersTotal += querySnapshot2.size
      querySnapshot2.forEach((doc) => {
        const productOrder = doc.data()
        productOrder.orderId = doc.id
        productOrder.type = 'infoProduct'
        if(productOrder.timeCreation.seconds >= inititalDate) {
          setUsersOrders(item => [...item, productOrder])
          ordersTotal++
        }
      })
      setReady(prev => !prev)
      setWaitMessage('')
      const finalMessage = ordersTotal <= 1 ? `Foi encontrada ${ordersTotal} ordem` : `Foram encontradas ${ordersTotal} ordens`
      setSuccessMessage(() => finalMessage)
    }
    else {
      setWaitMessage('')
      setError('Insira uma data')
    }
  }

  useEffect(() => {
    if(usersOrders.length > 0) {
      const orderedUsersOrders = usersOrders.sort((a, b) => {
        if(a.timeCreation.seconds > b.timeCreation.seconds) return 1
        if(b.timeCreation.seconds > a.timeCreation.seconds) return -1
        return 0
      })
      setUsersOrders(orderedUsersOrders)
      setLoading(false)
    }
  }, [ready])

  const orders = (exchangeAccountInfo && usersOrders.length > 0) ? usersOrders.map(order => {
    return(<OrderBuy
        key = {order.orderId}
        orderId = {order.orderId}
        order = {order}
        buyingPower= {buyingPower}
        buyingUsdPower= {buyingUsdPower}
        bnbBalance= {bnbBalance}
        />
    )
  })
  : null

  const confirmBuyAll = async () => {
    clog(JSON.stringify(usersOrders, null, 2))
    // return
    const ordersIdsList = usersOrders.map(order => {
      return order.orderId
    })
    clog(JSON.stringify(ordersIdsList, null, 2))

    /***************************************************/
    const EMULATOR = true
    /***************************************************/
    const cloudFunction = 'autoBuyBaskets'
    const queryData = [
      {
        name: 'ordersIdsList',
        // value: JSON.stringify({list: ordersIdsList}),
        value: JSON.stringify(ordersIdsList),
      },
    ]
    const response = await fetchUrl(currentAppUser, 'app', EMULATOR, cloudFunction, queryData)
    // clog(`resp = ${response.resp}`)
  }

  const checkOpenOrders = () => {
    setListOpenOrders(true)
    setListOpenPayedOrders(false)
    setListFulfilledOrders(false)
  }
  const checkOpenPayedOrders = () => {
    setListOpenOrders(false)
    setListOpenPayedOrders(true)
    setListFulfilledOrders(false)
  }
  const checkFulfilledOrders = () => {
    setListOpenOrders(false)
    setListOpenPayedOrders(false)
    setListFulfilledOrders(true)
  }

  return (
    <>
      <Card>
      <br></br>
      <br></br>
      <br></br>
        {waitMessage && <Alert variant='warning'>{waitMessage}</Alert>}
        {successMessage && <Alert variant='success'>{successMessage}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        <Form>
            <Form.Group id='inititalDate' style={{justifyContent: 'center'}}>
              <Form.Label>Data inicial (a partir de 00:00:00)</Form.Label>
              <Form.Control
                type='date'
                ref={inititalDateRef}
                required
                autoFocus
                style={{width: '30%'}}
              />
            </Form.Group>
            <Form.Check
              type={'radio'}
              name="group1"
              id={`default-${'switch'}2`}
              label={`Ordens abertas (pagas e não pagas)`}
              onChange={checkOpenOrders}
              style={{cursor: 'pointer'}}
            />
            <Form.Check
              type={'radio'}
              name="group1"
              id={`default-${'switch'}2`}
              label={`Ordens abertas e pagas`}
              onChange={checkOpenPayedOrders}
              style={{cursor: 'pointer'}}
            />
            <Form.Check
              type={'radio'}
              name="group1"
              id={`default-${'switch'}3`}
              label={`Ordens completas e pagas`}
              onChange={checkFulfilledOrders}
              style={{cursor: 'pointer'}}
            />
        </Form>
        <br></br>
        <Button variant='primary' className='w-100' onClick={fetchOrders}>
          Buscar Ordens
        </Button>
        <br></br>
        <Card.Body>
          <h2 className='text-center mb-4'>Users Buy Orders</h2>
          <p className='mb-4'>You have {usersOrders.length} pending order(s):</p>
          <br></br>
          <br></br>
          <Card style={{ width: '40rem' }}>
            <p>Account Info:</p>
            <ListGroup variant='flush'>
            <ListGroup.Item>Buying Power: {buyingPower} ({buyingUsdPower.toFixed(2)} USD)</ListGroup.Item>
            <ListGroup.Item>BNB balance: {bnbBalance}</ListGroup.Item>
            </ListGroup>
            <p>User Order List:</p>
            {orders}
          </Card>
          {/* <br></br>
          <Button variant='success' disabled={loading} className='w-100' onClick={confirmBuyAll}>
            Buy All
          </Button> */}
          <br></br>
        </Card.Body>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}