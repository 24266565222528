import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { getPairSymbolPrice } from '../auxiliary/broker'
import { clog, cloj, formatCpf, getTimestampLastMonth, getUnixTimeOfFirstDayOfMonth, round, toDateTime } from '../auxiliary/helpers'
import { useAuth } from '../contexts/AuthContext'

import { collection, getDocs, query, where } from '@firebase/firestore'
import { appDb } from '../firebase'
// import ordersTest from '../mock/orders-test2.json'

const STABLE_COIN_SYMBOL = 'USDT'
const FIAT_SYMBOL = 'BRL'

function CustomerReports() {

  const symbolPrices = useRef({})
  const { currentAdminUser } = useAuth()
  const [initialDate, setInitialDate] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [error, setError] = useState('')
  const [dataToWrite, setDataToWrite] = useState('')
  const [month, setMonth] = useState(0)
  const [year, setYear] = useState(0)

  useEffect(() => {
      const timestampLastMonth = getTimestampLastMonth()
      setInitialDate(timestampLastMonth)
  }, [])

  useEffect(() => {
    if(initialDate !== '') {
      const initialDateTimestamp = Math.floor(initialDate.getTime() / 1000 )
      const initialDateMilisecond = new Date(initialDateTimestamp * 1000)
      setMonth(initialDateMilisecond.getMonth() + 1)
      setYear(initialDateMilisecond.getFullYear())
    }
  }, [initialDate])

  const getPairPrice = async (pairSymbol, timePriceReference) => {
    if(pairSymbol in symbolPrices.current) {
      return symbolPrices.current[pairSymbol]
    }
    const pairPrice = await getPairSymbolPrice(currentAdminUser, pairSymbol, timePriceReference)
    const prevSymbolPrices = symbolPrices.current
    symbolPrices.current = {
      ...prevSymbolPrices,
      [pairSymbol]: pairPrice
    }
    return pairPrice
  }

  async function generateReport() {
    setError('')
    setSuccessMessage('')

    const currentDate = new Date()
    const unixCurrentTime = currentDate.getTime() / 1000; // Convert to seconds
    clog(currentDate)
    
    if(!currentDate.toString().includes('Brasilia Standard Time') && !currentDate.toString().includes('Horário Padrão de Brasília')) {
      setWaitMessage('')
      setError("Você deve estar no fuso de Brasília para usar essa funcionalidade");
      return;
    }

    // first day of the next month minus 1 sec to get 23:59:59 of the current month
    const finalDate = getUnixTimeOfFirstDayOfMonth(year, month + 1) - 1
    
    setWaitMessage(`Baixando relatório para: ${toDateTime(finalDate)}`)

    clog(`finalDate = ${finalDate} = ${toDateTime(finalDate)}`)
    
    if(finalDate > unixCurrentTime) {
      setWaitMessage('')
      setError("Tente novamente com uma data anterior ao mês atual");
      return;
    }

    
    const pairStableFiatSymbol = STABLE_COIN_SYMBOL + FIAT_SYMBOL
    const priceStableInFiat = await getPairPrice(pairStableFiatSymbol, finalDate)
    clog(`priceStableInFiat = ${priceStableInFiat}`)


    const ordersRef = collection(appDb, 'orders')
    const q1 = query(ordersRef,
      where('status', '==', 'FULFILLED'),
      where('userId', '!=', 'YETftQD0Y5goflAqDPAixTY3O2h2'))
    const querySnapshot = await getDocs(q1)
    let userOrdersList = []

    if(!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        const order = doc.data()
        order.orderId = doc.id
        if(order.timeUpdate.seconds <= finalDate) {
          userOrdersList.push(order)
        }
      })
    }

    // const userOrdersList = ordersTest.filter(order => order.status === 'FULFILLED' && order.userId !== 'YETftQD0Y5goflAqDPAixTY3O2h2' && order.timeUpdate.seconds <= finalDate);
    // /\ if you want to test with the mock data, uncomment the line above and comment the firebase code above of it

    let csvReportData = [
      ["IDs da ordens", "ID do usuário", "CPF", "Nome", "Moeda", "Quantidade", "Valor em BRL" ,"Valor total"],
    ]

    const userOrderTotals = {}
    const userOrderSell = {}

    //CHECK SELL ORDERS
    for(let i in userOrdersList) {
      const order = userOrdersList[i]
      const orderSide = order.orderSide    // 'BUY' or 'SELL'
      if(orderSide === 'BUY') continue     //If orderSide is buy, go to the next iteration and skip the remaining code
      const userId = order.userId
      const basketRefId = order.basketRefId
      const heroId = order.heroId
      if(!userOrderSell[userId]) {
        userOrderSell[userId] = [{
            basketRefId: basketRefId,
            heroId: heroId,
            timeUpdate: order.timeUpdate.seconds,
        }]
      }
      else {
        userOrderSell[userId].push({
          basketRefId: basketRefId,
          heroId: heroId,
          timeUpdate: order.timeUpdate.seconds,
        })
      }        
    }

    //CHECK BUY ORDERS
    for(let i in userOrdersList) {
      let shouldContinue = true;
      const order = userOrdersList[i]
      const orderSide = order.orderSide    // 'BUY' or 'SELL'
      if(orderSide === 'SELL') continue     //If orderSide is sell, go to the next iteration
      const userId = order.userId
      const basketRefId = order.basketRefId
      const heroId = order.heroId
      const userCpf = order.personalInfo.cpf ? formatCpf(order.personalInfo.cpf) : 'Não informado'
      const userName = order.personalInfo.name
      const fulfilledCoins = order.fulfilledCoins ? order.fulfilledCoins : order.fulfilledCoinsList

      for(const i in userOrderSell[userId]) {
        const orderSell = userOrderSell[userId][i]
        if(orderSell.basketRefId === basketRefId && orderSell.heroId === heroId && orderSell.timeUpdate >= order.timeUpdate.seconds && orderSell.timeUpdate <= finalDate) {
          shouldContinue = false
          break
        }
      }
      if(!shouldContinue) continue
        
      for(let j in fulfilledCoins) {
        const symbol = fulfilledCoins[j].symbol   // pair symbol
        const coinAmount = fulfilledCoins[j].fulfilledAmount
        if(!userOrderTotals[userId]) {
          userOrderTotals[userId] = {
            cpf: userCpf,
            name: userName,
            totalValue: 0,
            fulfilledCoins: {},
          }
        }

        if (!userOrderTotals[userId].fulfilledCoins[symbol]) {
          userOrderTotals[userId].fulfilledCoins[symbol] = {
            amount: 0,
            ordersIds: ''
          };
        }

        userOrderTotals[userId].fulfilledCoins[symbol] = {
          amount: userOrderTotals[userId].fulfilledCoins[symbol].amount + coinAmount,
          ordersIds: userOrderTotals[userId].fulfilledCoins[symbol].ordersIds + ' | ' + order.orderId
        }
      }
    }

    for (const userId in userOrderTotals) {
      const { fulfilledCoins } = userOrderTotals[userId];
      for (const symbol in fulfilledCoins) {
        const coinAmount = fulfilledCoins[symbol].amount;
        const pairPrice = await getPairPrice(symbol, finalDate);
        const totalValueInFiat = coinAmount * pairPrice * priceStableInFiat;

        clog(`pairPrice of ${symbol} = ${pairPrice}`)

        userOrderTotals[userId].fulfilledCoins[symbol].coinValue = round(totalValueInFiat, 2)
        userOrderTotals[userId].totalValue += round(totalValueInFiat, 2);
      }
    }

    clog('userOrderTotals')
    cloj(userOrderTotals)

    for(const userId in userOrderTotals) {
      const { cpf, totalValue, fulfilledCoins, name } = userOrderTotals[userId]

      const totalOfCoins = Object.keys(fulfilledCoins).length
      let cont = 1
      for(const pairSymbol in fulfilledCoins) {
        const amount = fulfilledCoins[pairSymbol].amount
        const ordersIds = fulfilledCoins[pairSymbol].ordersIds
        const coinValue = fulfilledCoins[pairSymbol].coinValue

        const newLine = [
          ordersIds,
          userId,
          cpf,
          name,
          pairSymbol.replace(STABLE_COIN_SYMBOL, ''),
          amount,
          round(coinValue, 2),
        ];
        if(totalOfCoins === cont) {
          newLine.push(round(totalValue, 2))
        }
        csvReportData.push(newLine)
        cont++
      }
    }
    setDataToWrite(csvReportData)
    setWaitMessage('')
    setSuccessMessage('Report ready to download')
  }

  
  async function generateReportCurrentValues() {
    setError('')
    setSuccessMessage('')

    const currentDate = new Date()
    // const unixCurrentTime = currentDate.getTime() / 1000; // Convert to seconds
    const unixCurrentTime = 1709262000
    clog(currentDate)
    

    // first day of the next month minus 1 sec to get 23:59:59 of the current month
    const finalDate = unixCurrentTime - 1
    
    setWaitMessage(`Baixando relatório para: ${toDateTime(finalDate)}`)

    clog(`finalDate = ${finalDate} = ${toDateTime(finalDate)}`)
        
    const pairStableFiatSymbol = STABLE_COIN_SYMBOL + FIAT_SYMBOL
    const priceStableInFiat = await getPairPrice(pairStableFiatSymbol, finalDate)
    clog(`priceStableInFiat = ${priceStableInFiat}`)


    const ordersRef = collection(appDb, 'orders')
    const q1 = query(ordersRef,
      where('status', '==', 'FULFILLED'),
      where('userId', '!=', 'YETftQD0Y5goflAqDPAixTY3O2h2'))
    const querySnapshot = await getDocs(q1)
    let userOrdersList = []

    if(!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        const order = doc.data()
        order.orderId = doc.id
        if(order.timeUpdate.seconds <= finalDate) {
          userOrdersList.push(order)
        }
      })
    }

    // const userOrdersList = ordersTest.filter(order => order.status === 'FULFILLED' && order.userId !== 'YETftQD0Y5goflAqDPAixTY3O2h2' && order.timeUpdate.seconds <= finalDate);
    // /\ if you want to test with the mock data, uncomment the line above and comment the firebase code above of it

    let csvReportData = [
      ["IDs da ordens", "ID do usuário", "CPF", "Nome", "Moeda", "Quantidade", "Valor em BRL" ,"Valor total"],
    ]

    const userOrderTotals = {}
    const userOrderSell = {}

    //CHECK SELL ORDERS
    for(let i in userOrdersList) {
      const order = userOrdersList[i]
      const orderSide = order.orderSide    // 'BUY' or 'SELL'
      if(orderSide === 'BUY') continue     //If orderSide is buy, go to the next iteration and skip the remaining code
      const userId = order.userId
      const basketRefId = order.basketRefId
      const heroId = order.heroId
      if(!userOrderSell[userId]) {
        userOrderSell[userId] = [{
            basketRefId: basketRefId,
            heroId: heroId,
            timeUpdate: order.timeUpdate.seconds,
        }]
      }
      else {
        userOrderSell[userId].push({
          basketRefId: basketRefId,
          heroId: heroId,
          timeUpdate: order.timeUpdate.seconds,
        })
      }        
    }

    //CHECK BUY ORDERS
    for(let i in userOrdersList) {
      let shouldContinue = true;
      const order = userOrdersList[i]
      const orderSide = order.orderSide    // 'BUY' or 'SELL'
      if(orderSide === 'SELL') continue     //If orderSide is sell, go to the next iteration
      const userId = order.userId
      const basketRefId = order.basketRefId
      const heroId = order.heroId
      const userCpf = order.personalInfo.cpf ? formatCpf(order.personalInfo.cpf) : 'Não informado'
      const userName = order.personalInfo.name
      const fulfilledCoins = order.fulfilledCoins ? order.fulfilledCoins : order.fulfilledCoinsList

      for(const i in userOrderSell[userId]) {
        const orderSell = userOrderSell[userId][i]
        if(orderSell.basketRefId === basketRefId && orderSell.heroId === heroId && orderSell.timeUpdate >= order.timeUpdate.seconds && orderSell.timeUpdate <= finalDate) {
          shouldContinue = false
          break
        }
      }
      if(!shouldContinue) continue
        
      for(let j in fulfilledCoins) {
        const symbol = fulfilledCoins[j].symbol   // pair symbol
        const coinAmount = fulfilledCoins[j].fulfilledAmount
        if(!userOrderTotals[userId]) {
          userOrderTotals[userId] = {
            cpf: userCpf,
            name: userName,
            totalValue: 0,
            fulfilledCoins: {},
          }
        }

        if (!userOrderTotals[userId].fulfilledCoins[symbol]) {
          userOrderTotals[userId].fulfilledCoins[symbol] = {
            amount: 0,
            ordersIds: ''
          };
        }

        userOrderTotals[userId].fulfilledCoins[symbol] = {
          amount: userOrderTotals[userId].fulfilledCoins[symbol].amount + coinAmount,
          ordersIds: userOrderTotals[userId].fulfilledCoins[symbol].ordersIds + ' | ' + order.orderId
        }
      }
    }

    for (const userId in userOrderTotals) {
      const { fulfilledCoins } = userOrderTotals[userId];
      for (const symbol in fulfilledCoins) {
        const coinAmount = fulfilledCoins[symbol].amount;
        const pairPrice = await getPairPrice(symbol, finalDate);
        const totalValueInFiat = coinAmount * pairPrice * priceStableInFiat;

        clog(`pairPrice of ${symbol} = ${pairPrice}`)

        userOrderTotals[userId].fulfilledCoins[symbol].coinValue = round(totalValueInFiat, 2)
        userOrderTotals[userId].totalValue += round(totalValueInFiat, 2);
      }
    }

    clog('userOrderTotals')
    cloj(userOrderTotals)

    for(const userId in userOrderTotals) {
      const { cpf, totalValue, fulfilledCoins, name } = userOrderTotals[userId]

      const totalOfCoins = Object.keys(fulfilledCoins).length
      let cont = 1
      for(const pairSymbol in fulfilledCoins) {
        const amount = fulfilledCoins[pairSymbol].amount
        const ordersIds = fulfilledCoins[pairSymbol].ordersIds
        const coinValue = fulfilledCoins[pairSymbol].coinValue

        const newLine = [
          ordersIds,
          userId,
          cpf,
          name,
          pairSymbol.replace(STABLE_COIN_SYMBOL, ''),
          amount,
          round(coinValue, 2),
        ];
        if(totalOfCoins === cont) {
          newLine.push(round(totalValue, 2))
        }
        csvReportData.push(newLine)
        cont++
      }
    }
    setDataToWrite(csvReportData)
    setWaitMessage('')
    setSuccessMessage('Report ready to download')
  }

  return (
    <>
      <Form>
        
        <Form.Group id='finalDate'>
          <Form.Label>Selecione o mês e ano desejado</Form.Label>
          <DatePicker
            selected={initialDate}
            onChange={(date) => setInitialDate(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            minDate={new Date("11-01-2022")}
            />
        </Form.Group>

        {waitMessage && <Alert variant='warning'>{waitMessage}</Alert>}
        {successMessage && <Alert variant='success'>{successMessage}</Alert>}
        {error && <Alert variant='danger'>{error}</Alert>}
        <Button variant='primary' className='w-100' onClick={generateReport}>
          Gerar RELATÓRIO
        </Button>
        <br/>
        <br/>
        <Button variant='primary' className='w-100' onClick={generateReportCurrentValues}>
          Gerar RELATÓRIO VALORES ATUAIS
        </Button>
        <br/>
        <br />
        {successMessage &&
          <CSVLink
          data={dataToWrite}
          onClick={() => window.location.reload()}
          separator={','}
          filename={`customerReports_${month}-${year}.csv`}   //CHANGE THIS TO A DINAMIC ONE
          className='btn btn-primary'>
          Download
        </CSVLink>}
      <br></br>
      <br></br>
      </Form>
      <div className='w-100 text-center mt-2'>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}

export default CustomerReports
