import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { clog, confirmTokensBasketBuy } from '../auxiliary/helpers';
import { useAuth } from "../contexts/AuthContext";
import { formatPrice } from '@mauchero/helpers';

const FIAT_SYMBOL = 'BRL'
const STABLE_COIN_SYMBOL = 'USDT'


function OrderBuyTokensPage() {
  
  const [tokensUnitValues, setTokensUnitValues] = React.useState([])
  const [buttonDisabled, setButtonDisabled] = React.useState(true)

  const location = useLocation()
  const { basketInfo, investedValue, orderId } = location.state

  useEffect(() => {
    const size = basketInfo.length
    const tempTokensUnitValues = []
    for(let i = 0; i < size; i++){
      tempTokensUnitValues[i] = 1;
    }
    setTokensUnitValues(tempTokensUnitValues)
  }, [])

  const { currentAppV2User } = useAuth()

  const buyTokens = async () => {
    const tradedAveragePrices = basketInfo.map((item, index) => {
      return parseFloat(tokensUnitValues[index])
    })
    const fiatPrices = basketInfo.map((item, index) => {
      return 1
    })
    clog('>> tradedAveragePrices')
    clog(tradedAveragePrices)
    clog('>> fiatPrices')
    clog(fiatPrices)
    clog('>> orderId')
    clog(orderId)

    try{
      const resp = await confirmTokensBasketBuy(currentAppV2User, orderId, tradedAveragePrices, fiatPrices)
      clog('>> resp')
      clog(JSON.stringify(resp))
      if(resp.response === 'OK') {
        alert('Operação realizada com sucesso!')
      }
      else {
        const errorMsg = resp.response.error?.message
        switch(errorMsg) {
          case 'user not authorized':
            alert('Erro: usuário não autorizado.')
            break
          case 'invalid orderId':
            alert('Erro: orderId inválido.')
            break
          case 'order already fulfilled':
            alert('Erro: ordem já foi executada.')
            break
          case 'inputs are invalid':
            alert('Erro: inputs são inválidos. Tente novamente.')
            break
          case 'the basket must contain only tokens':
            alert('Erro: a cesta deve conter apenas tokens.')
            break
          default:
            alert('Erro desconhecido: tente novamente. Caso o erro persista, entre em contato.')
        }
      }
    } catch (error) {
      console.error('>> error')
      console.error(JSON.stringify(error))
      alert('Erro: tente novamente. Caso o erro persista, entre em contato.')
    }
  }

  useEffect(() => {
    const checkIfAllTokensUnitValuesAreFilled = () => {
      if(tokensUnitValues.length === basketInfo.length) {
        const allFilled = tokensUnitValues.every((value) => value !== '' && value !== undefined && value !== null)
        clog(allFilled)
        setButtonDisabled(!allFilled)
      }
    }
    checkIfAllTokensUnitValuesAreFilled() 
  }, [tokensUnitValues])
  


  const assets = basketInfo.length > 0 ? basketInfo.map((item, index) => {
    return (<div key={index}>
      <p>
        Ativo: {item.symbol}
      </p>
      <p>
        Valor: {formatPrice(investedValue * item.percentage)}
      </p>
      <div>
        <label htmlFor="tokenUnityValue" className="mr-2">Preço de compra de uma unidade do ativo:</label>
        <input
          type="number"
          id="tokenUnityValue"
          /* placeholder='1' */
          value={tokensUnitValues[index]}
          onChange={(event) => {
            const newTokensUnitValues = [...tokensUnitValues]
            const inputValue = event.target.value;
            newTokensUnitValues[index] = inputValue !== '' ? parseFloat(inputValue) : ''
            setTokensUnitValues(newTokensUnitValues)
            clog(newTokensUnitValues)
          }}
          required
        />
      </div>
      <br></br>
    </div>)
  }) : null


  return (
    <>
    <h1 className="h3 mb-4 mt-4 text-gray-800">Ordens de compra a serem executadas</h1>
    <div style={{fontWeight: 'bold'}}>{assets}</div>
      <Button type='submit' variant='primary' disabled={buttonDisabled} className='w-100 mt-4' onClick={buyTokens}>Executar ordem</Button>
    <div className="w-100 text-center mt-2">
        <Link to="/users-tokens-buy-orders">Voltar</Link>
      </div>
    
    </>
  )
}

export default OrderBuyTokensPage
