import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { clog, confirmPayedSellOrder, confirmTokensBasketBuy, copyToClipboard, secsToDateTime } from '../auxiliary/helpers';
import { useAuth } from "../contexts/AuthContext";
import { formatPrice, secsToLocaleDateTime } from '@mauchero/helpers';

const FIAT_SYMBOL = 'BRL'
const STABLE_COIN_SYMBOL = 'USDT'


function OrderSellTokensPage() {
  
  const [tokensUnitValues, setTokensUnitValues] = React.useState([])

  const location = useLocation()
  const { orderId, userInfo, actualDate, totalToReceive, tokensFulfillmentResponse } = location.state

  const formatedDate = secsToLocaleDateTime(actualDate)

  const { currentAppV2User } = useAuth()

  const sellTokens = async () => {
    try{
      const resp = await confirmPayedSellOrder(currentAppV2User, orderId)
      clog('>> resp')
      clog(JSON.stringify(resp))
      if(resp.response === 'OK') {
        alert('Operação realizada com sucesso!')
      }
      else {
        const errorMsg = resp.response.error?.message
        switch(errorMsg) {
          case 'user not authorized':
            alert('Erro: usuário não autorizado.')
            break
          case 'invalid orderId':
            alert('Erro: orderId inválido.')
            break
          case 'order already payed':
            alert('Erro: ordem já foi paga.')
            break
          default:
            alert('Erro desconhecido: tente novamente. Caso o erro persista, entre em contato.')
        }
      }
    } catch (error) {
      console.error('>> error')
      console.error(JSON.stringify(error))
      alert('Erro: tente novamente. Caso o erro persista, entre em contato.')
    }
  }

  

  return (
    <>
    <h1 className="h3 mb-4 mt-4 text-gray-800">Ordem de venda a ser executada</h1>
    <div>
      <p>Data atual: {formatedDate}</p>
      <p>Nome: {userInfo.name}</p>
      <p>Email: {userInfo.email}</p>
      <p>CPF: {userInfo.cpf}</p>
      <p>Valor a ser transferido: {formatPrice(totalToReceive)}</p>
      <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap: 20, alignItems: 'flex-start'}}>
        <p>Chave Pix do usuário: {userInfo.pixKey}</p>
        <Button onClick={() => {copyToClipboard(userInfo.pixKey); alert('Chave Pix copiada com sucesso')}}>Copiar Pix</Button>
      </div>
    </div>
      <Button type='submit' variant='primary' className='w-100 mt-4' onClick={sellTokens}>Executar ordem</Button>
    <div className="w-100 text-center mt-2">
        <Link to="/dashboardappv2">Voltar</Link>
      </div>
    
    </>
  )
}

export default OrderSellTokensPage
