import { collection, doc, getDocs, query, setDoc } from "firebase/firestore"
import React, { useEffect, useState } from "react"
import { Alert, Button, Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

import { arraySum, clog, fetchUrl } from "../auxiliary/helpers"
import { appDb } from '../firebase'


const HERO_ID = 'carolinvest'
const heroData = require(`../data/${HERO_ID}/${HERO_ID}.json`)

export default function ManageHeroes() {

  const QUOTE_ASSET_SYMBOL = 'USDT'

  const { currentAdminUser } = useAuth()
  const [errorPercentage, setErrorPercentage] = useState("")
  const [error, setError] = useState("")
  const [orderMessage, setOrderMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('') 
  const [loading, setLoading] = useState(false)
  const [permitted, setPermitted] = useState(false)

  // clog(JSON.stringify(heroData, null, 2))
  
  useEffect(() => {
    const PERMMITED_UID = 'tsM6UtAGmAcx0YHI1a2rh0BpkOB2'
    const uid = currentAdminUser.uid
    const url = new URL(window.location.href)
    const hostname = url.hostname
    if(uid === PERMMITED_UID && hostname === 'localhost') setPermitted(true)
    else setError('User not allowed!')
  }, [])

  const addHero = async () => {
    setLoading(true)
    setWaitMessage('Wait for the recording end')
    const info = heroData.info
    clog(info.id)
    clog(JSON.stringify(info, null, 2))
    const baskets = heroData.baskets
    const products = heroData.products

    try {
      await setDoc(doc(appDb, `/heroes/${info.id}`), info)
      baskets.forEach(async basket => {
        const publicData = {}
        publicData.hideBasket = basket.hideBasket ?? false
        publicData.order = basket.order
        publicData.id = basket.id
        publicData.isPaidContent = basket.isPaidContent
        publicData.publicContent = basket.publicContent
        await setDoc(doc(appDb, `/heroes/${info.id}/baskets/${basket.id}`), publicData)

        const privateData = {}
        privateData.isPaidContent = basket.isPaidContent
        privateData.publicContent = basket.publicContent
        privateData.privateContent = basket.privateContent
        await setDoc(doc(appDb, `/heroes/${info.id}/baskets/${basket.id}/privateBaskets/${basket.id}`), privateData)
      })
      
      for(let productName in products) {
        products[productName].forEach(async product => {
          const publicData = {}
          publicData.id = product.id
          publicData.isPaidContent = product.isPaidContent
          publicData.price = product.price
          publicData.publicContent = product.publicContent
          const collectionName = productName
          const docName = info.id + '_' + collectionName + '_' + product.id
          clog('collectionName')
          clog(collectionName)
          clog('docName')
          clog(docName)
          await setDoc(doc(appDb, `/heroes/${info.id}/${collectionName}/${docName}`), publicData)
          
          const privateData = {}
          privateData.isPaidContent = product.isPaidContent
          privateData.price = product.price
          privateData.privateContent = product.privateContent
          await setDoc(doc(appDb, `/heroes/${info.id}/${collectionName}/${docName}/privateData/${docName}`), privateData)
        })
      }
    }
    catch(error) {
      setWaitMessage('')
      setError(error)
      return
    }   
    setWaitMessage('')
    setOrderMessage('Success -- all recorded')
    setLoading(false)
  }

  const checkHeroesBaskets = async () => {
    setLoading(true)
    let herosIdsList = []
    const heroesColRef = collection(appDb, 'heroes')
    const heroesSnap = await getDocs(query(heroesColRef))
    heroesSnap.forEach(doc => {
      herosIdsList.push(doc.id)
    })
    for(let i in herosIdsList) {
      const heroId = herosIdsList[i]
      const basketsColRef = collection(appDb, `/heroes/${heroId}/baskets`)
      const basketsSnap = await getDocs(query(basketsColRef))
      let basketsIdsList = []
      basketsSnap.forEach(doc => {
        basketsIdsList.push(doc.id)
      })
      for(let j in basketsIdsList) {
        const basketId = basketsIdsList[j]
        const privateBasketsColRef = collection(appDb, `/heroes/${heroId}/baskets/${basketId}/privateBaskets`)
        const privateBasketsSnap = await getDocs(query(privateBasketsColRef))
        
        let coins = []
        privateBasketsSnap.forEach(doc => {
          const privateBasket = doc.data()
          coins = privateBasket.privateContent.coins
        })
        const coinsList = coins.map(coinData => {
          return coinData.symbol
        })
        const percentagesList = coins.map(coinData => {
          return coinData.percentage
        })
        clog(`coinsList - ${heroId} - ${basketId}`)
        // clog(JSON.stringify(coinsList, null, 2))
        // clog(JSON.stringify(percentagesList, null, 2))
        checkCoinsList(coinsList, heroId, basketId)
        clog(`arraySum(percentagesList) = ${arraySum(percentagesList)}`)
        if(arraySum(percentagesList) < 0.9999 || arraySum(percentagesList) > 1.0001) {
          clog(`ATENÇÃO! ${heroId} - ${basketId} - SOMA DAS %s DIFERENTE DE 1`)
          setErrorPercentage(prev => {
            return prev + '   |   ' + `${heroId} - ${basketId} - %s`
          })
        }
      }
    }
    setLoading(false)
  }

  const checkCoinsList = async (coinsList, heroId, basketId) => {

    /***************************************************/
    const EMULATOR = false
    /***************************************************/
    const cloudFunction = 'exchangeInfoSymbolListed'
    const queryData = [
      {
        name: 'coinsList',
        value: JSON.stringify(coinsList),
      },
      {
        name: 'quoteAsset',
        value: QUOTE_ASSET_SYMBOL,
      },
    ]

    const response = await fetchUrl(currentAdminUser, 'admin', EMULATOR, cloudFunction, queryData)

    // clog(JSON.stringify(response, null, 2))

    for(let symbol in response.coinsTradingInfo) {
      if(!response.coinsTradingInfo[symbol]) {
        clog(`ATENÇÃO! - ${symbol} NÃO ESTÁ LISTADO NA BINANCE!!!`)
        setError(prev => {
          return prev + '   |   ' + `${symbol} NAO LISTADO - ${heroId} - ${basketId}`
        })
      }
    }


  }

  return (
    <>
      <Card>
        {errorPercentage && <Alert variant='danger'>{errorPercentage}</Alert>}
        {error && <Alert variant='danger'>{error}</Alert>}
        {orderMessage && <Alert variant='success'>{orderMessage}</Alert>}
        {waitMessage && <Alert variant='primary'>{waitMessage}</Alert>}
        <Button disabled={loading || !permitted} className="w-100" onClick={addHero}>
          Update {heroData.info.personalInfo.name}
        </Button>
        <br></br>
        <br></br>
        <Button disabled={loading} className="w-100" onClick={checkHeroesBaskets}>
          Check Heroes Baskets
        </Button>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/">Cancel</Link>
      </div>
    </>
  )
}