import { collection, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

import { clog, fetchUrl } from '../auxiliary/helpers'
import { appDb } from '../firebase'

const MIN_DIF_PERCENTAGE = 0.001  // Tolerance for basket assets percentage
const MIN_DIF_INVESTED_VALUES = 0.2  // Tolerance for invested values in USD

const quoteAmount = 5
const assetToBuySymbol = 'XRP'
const QUOTE_ASSET_SYMBOL = 'USDT'

export default function VerifyAccounts() {

  const { currentAdminUser } = useAuth()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [permitted, setPermitted] = useState(false)
  const [coinsAmount, setCoinsAmount] = useState({})
  // const [checkFinished, setCheckFinished] = useState(false)

  useEffect(() => {
    const PERMMITED_UID = 'tsM6UtAGmAcx0YHI1a2rh0BpkOB2'
    const uid = currentAdminUser.uid
    const url = new URL(window.location.href)
    const hostname = url.hostname
    if(uid === PERMMITED_UID && hostname === 'localhost') {
      setPermitted(true)
      setLoading(false)
    }
    else setError('User not allowed!')
  }, [])

  // useEffect(() => {
  //   if(checkFinished){
  //     clog(JSON.stringify(coinsAmount, null, 2))
  //   }
  // }, [coinsAmount, checkFinished])

  // Verify all fulfilled orders with users baskets
  const verifyFulfilledOrders = async () => {
    try {
      const usersRef = collection(appDb, 'users')
      const usersQuerySnap = await getDocs(usersRef)
      // For each user
      let usersIds = []
      usersQuerySnap.forEach(async doc => {
        const userId = doc.id
        const userName = doc.data().personalInfo.name
        // Tester user (gaspar.csales)
        if(userId === 'YETftQD0Y5goflAqDPAixTY3O2h2') {
          clog('PULAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
          return
        }
        usersIds.push(userId)
        try {
          const userBasketsRef = collection(appDb, `/users/${userId}/userBaskets`)
          const q1 = query(userBasketsRef,
            where('status', '==', 'FULFILLED'))
          const usersBasketsQuerySnap = await getDocs(q1)
          usersBasketsQuerySnap.forEach(async doc => {
            const userBasket = doc.data()
            const basketRefId = userBasket.basketRefId
            const heroId = userBasket.heroId
            const investedValueBrl = userBasket.investedValue
            const coins = userBasket.coins

            let totalBasketValueUsd = 0
            for(const coin in coins) {
              totalBasketValueUsd += coins[coin].amount * coins[coin].buyPrice
            }
            if(totalBasketValueUsd === 0) {
              setError(prev => prev + `\nCOMPRA NAO EXECUTADA. User: ${userId} | basketRefId: ${basketRefId}`)
              clog(`ERRO GRAVE!!! COMPRA NAO EXECUTADA. User: ${userId} | basketRefId: ${basketRefId}`)
            }
            for(const coin in coins) {
              const percentage = coins[coin].percentage
              const partialValueUsd = coins[coin].amount * coins[coin].buyPrice
              // clog('INI ------------------------------------------------------------')
              // clog(`${userName} - ID ${userId}`)
              // clog(percentage)
              // clog(partialValueUsd/totalBasketValueUsd)
              // clog(Math.abs(partialValueUsd/totalBasketValueUsd - percentage))
              if(Math.abs(partialValueUsd/totalBasketValueUsd - percentage) > MIN_DIF_PERCENTAGE) {
                clog('ERRO - PORCENTAGEM NÃO BATE COM A CESTA')
                clog(`${userName} - ID ${userId}`)
                clog(percentage)
                clog(partialValueUsd/totalBasketValueUsd)
                clog(Math.abs(partialValueUsd/totalBasketValueUsd - percentage))
              }
              else clog(`PERCENTAGE PASS`)
            }

            // For each fulfilled order of that user and a specific basket
            const ordersRef = collection(appDb, 'orders')
            const q2 = query(ordersRef,
              where('status', '==', 'FULFILLED'),
              where('userId', '==', userId),
              where('orderSide', '==', 'BUY'),
              where('heroId', '==', heroId),
              where('basketRefId', '==', basketRefId))
            const buyOrdersQuerySnap = await getDocs(q2)
            let buyOrders = []
            let totalBuyValueBrl = 0
            let totalBuyValueUsd = 0
            buyOrdersQuerySnap.forEach(doc => {
              const order = doc.data()
              const usdtBrl = order.usdtBrl
              if('fulfilledCoins' in order && order.fulfilledCoins.length === 0) {
                setError(prev => prev + `\nfulfilledCoins VAZIO | orderId: ${doc.id}`)
                clog(`ERRO GRAVE!!! fulfilledCoins VAZIO | orderId: ${doc.id}`)
              }
              buyOrders.push(order)
              totalBuyValueBrl += order.intendedInvestedValue
              totalBuyValueUsd += order.intendedInvestedValue / usdtBrl
            })
            const usdtBrlMean = totalBuyValueBrl / totalBuyValueUsd
            const q3 = query(ordersRef,
              where('userId', '==', userId),
              where('orderSide', '==', 'SELL'),
              where('heroId', '==', heroId),
              where('basketRefId', '==', basketRefId))
            const sellOrdersQuerySnap = await getDocs(q3)
            let sellOrders = []
            let totalSellValueBrl = 0
            sellOrdersQuerySnap.forEach(doc => {
              const order = doc.data()
              const usdtBrl = order.usdtBrl
              sellOrders.push(order)
              totalSellValueBrl += order.investedValue
            })
            totalBuyValueUsd -= totalSellValueBrl / usdtBrlMean
            
            
            // clog('INI ------------------------------------------------------------')
            // clog(`${userName} - ID ${userId}`)
            // clog(`usdtBrlMean = ${usdtBrlMean}`)
            // clog(`totalBasketValueUsd = ${totalBasketValueUsd}`)
            // clog(`totalBuyValueUsd = ${totalBuyValueUsd}`)
            // clog(`totalBasketValueUsd - totalBuyValueUsd = ${totalBasketValueUsd - totalBuyValueUsd}`)
            if(Math.abs(totalBasketValueUsd - totalBuyValueUsd) >= MIN_DIF_INVESTED_VALUES) {
              clog('ERRO 1 - HÁ DIFERENÇAS ENTRE VALORES DA CESTA E SOMA DAS ORDENS')
              clog(`${userName} - ID ${userId}`)
              clog(`Diferença = ${Math.abs(totalBasketValueUsd - totalBuyValueUsd)}`)
            }
            
            // clog('INI ------------------------------------------------------------')
            // clog(`${userName} - ID ${userId}`)
            // clog(`investedValueBrl = ${investedValueBrl}`)
            // clog(`totalBuyValueBrl - totalSellValueBrl = ${totalBuyValueBrl - totalSellValueBrl}`)
            if(investedValueBrl - (totalBuyValueBrl - totalSellValueBrl) >= MIN_DIF_INVESTED_VALUES) {
              clog('ERRO 2 - HÁ DIFERENÇAS')
              clog(`investedValueBrl = ${investedValueBrl}`)
              clog(`totalBuyValueBrl = ${totalBuyValueBrl}`)
              clog(`totalSellValueBrl = ${totalSellValueBrl}`)
              clog(`investedValueBrl - (totalBuyValueBrl - totalSellValueBrl) = ${investedValueBrl - (totalBuyValueBrl - totalSellValueBrl)}`)
              clog(`${userName} - ID ${userId}`)
              clog('buyOrders')
              clog(JSON.stringify(buyOrders, null, 2))
              clog('sellOrders')
              clog(JSON.stringify(sellOrders, null, 2))
            }
            else clog('VALORES INVESTIDOS E ORDENS - PASS')
            // clog('FIM ------------------------------------------------------------')
          })
        }
        catch(error) {
          clog(error)
        }
      })
    }
    catch(error) {
      clog(error)
    }
  }

  const verifyBalances = async () => {

    let userBasketsCoins = []
    try {
      clog('INICIOU VERIFICAÇÃO DE BALANÇOS')
      const usersRef = collection(appDb, 'users')
      const usersQuerySnap = await getDocs(usersRef)
      // For each user
      let usersIds = []
      usersQuerySnap.forEach(doc => {
        clog(`doc.id = ${doc.id}`)
        usersIds.push(doc.id)
      })
      for(const index in usersIds) {
        const userId = usersIds[index]
        const userBasketsRef = collection(appDb, `/users/${userId}/userBaskets`)
        const q1 = query(userBasketsRef,
          where('status', '==', 'FULFILLED'))
        const usersBasketsQuerySnap = await getDocs(q1)
        usersBasketsQuerySnap.forEach(doc => {
          const userBasket = doc.data()
          const coins = userBasket.coins
          userBasketsCoins.push(coins)
        })
      }
    }
    catch(error) {
      clog(error)
    }
    let coinsAmount = {}
    clog('userBasketsCoins = ')
    clog(JSON.stringify(userBasketsCoins, null, 2))
    for(const index2 in userBasketsCoins) {
      const coins = userBasketsCoins[index2]
      // clog(JSON.stringify(coins, null, 2))
      for(const coin in coins) {
        // clog(`coin = ${coin}`)
        const prevCoinAmount = coin in coinsAmount ? coinsAmount[coin] : 0
        coinsAmount[coin] = prevCoinAmount + coins[coin].amount
      }
    }
    clog('coinsAmount = ')
    clog(JSON.stringify(coinsAmount, null, 2))


    const cloudFunction = 'getExchangeAccountInfo'
    const queryData = [
      {
        name: 'fiatSymbol',
        value: 'BRL'
      }
    ]
    const exchangeAccountInfo = await fetchUrl(currentAdminUser, 'admin', false, cloudFunction, queryData)
    clog(JSON.stringify(exchangeAccountInfo, null, 2))
    const subAccountBalances = exchangeAccountInfo.availableBalancesSub
    
    for(const coin in coinsAmount) {
      const usersCoinAmount = coinsAmount[coin]
      const accountCoinAmount = subAccountBalances[coin]
      clog('-------------------------------------------')
      if(usersCoinAmount > accountCoinAmount) {
        setError(`ERRO - ALGO DE ERRADO NÃO ESTÁ CERTO!!!`)
        clog('ERRO - ALGO DE ERRADO NÃO ESTÁ CERTO!!!')
        clog(`usersCoinAmount ${coin} = ${usersCoinAmount}`)
        clog(`accountCoinAmount ${coin} = ${accountCoinAmount}`)
        clog(`Tem menos ${coin} do que deveria - Diferença de ${usersCoinAmount - accountCoinAmount}`)
      }
      else {
        clog(`TUDO CERTO COM ${coin}`)
      }
    }
  }

  const buyAsset = async () => {
    const cloudFunction = 'simpleBuyCoin'
    // contato | withdraw_virtual | auxiliary_virtual | subaccount_virtual
    const accountEmail = 'contato'
    const queryData = [
      {
        name: 'baseSymbol',
        value: assetToBuySymbol,
      },
      {
        name: 'quoteSymbol',
        value: QUOTE_ASSET_SYMBOL,
      },
      {
        name: 'quoteAmount',
        value: quoteAmount,
      },
      {
        name: 'accountEmail',
        value: accountEmail,
      },
    ]
    const backend = 'admin'
    const EMULATOR = false
    const response = await fetchUrl(currentAdminUser, backend, EMULATOR, cloudFunction, queryData)

    clog(JSON.stringify(response, null, 2))
  }

  return (
    <>
      <Card>
        {error && <Alert variant='danger'>{error}</Alert>}
        <Button disabled={loading || !permitted} className='w-100' onClick={verifyFulfilledOrders}>
          Verify Fulfilled Orders
        </Button>
        <br></br>
        <Button disabled={loading || !permitted} className='w-100' onClick={verifyBalances}>
          Verify Balances
        </Button>
        <br></br>
        <Button disabled={loading || !permitted} className='w-100' onClick={buyAsset}>
          Buy {quoteAmount} {QUOTE_ASSET_SYMBOL} of {assetToBuySymbol}
        </Button>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}