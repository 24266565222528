import React, { useEffect, useState } from 'react'
import { Form, Button, Card, Alert, ListGroup } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import { doc, getDoc, updateDoc } from '../firebase'

import { appDb, adminDb } from '../firebase'
import { getPrice, fetchUrl, clog, balanceOf } from '../auxiliary/helpers'

export default function ManageAssetsAux() {

  const [adminItauDeb, setAdminItauDeb] = useState(0)
  const [successMessage, setSuccessMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    const getBinanceDebt = async () => {
      const adminItauDebtRef = doc(adminDb, 'adminDebts/toItau')
      const adminItauDebtSnap = await getDoc(adminItauDebtRef)
      let toItauDebt = 0
      if(adminItauDebtSnap.exists()) {
        toItauDebt = adminItauDebtSnap.data().value
      }
      setAdminItauDeb(toItauDebt)
    }
    getBinanceDebt()
  }, [successMessage])

  const confirmTransfer = async () => {
    try {
      setWaitMessage('Wait...')
      const adminItauDebtRef = doc(adminDb, 'adminDebts/toItau')
      await updateDoc(adminItauDebtRef, {
        value: 0
      })
      setWaitMessage('')
      setSuccessMessage('Valor zerado com sucesso')
    }
    catch(error) {
      setError(`Erro: ${error}`)
    }
  }

  return (
    <>
      <Card>
      <Card.Body>
          <h2 className='text-center mb-4'>Manage CH Assets</h2>
          <br></br>
          <br></br>
          <Card style={{ width: '40rem' }}>
            <p>Account Info:</p>
            <ListGroup variant='flush'>
              <ListGroup.Item>Dívida Binace para Itau: {adminItauDeb}</ListGroup.Item>
            </ListGroup>
          </Card>
      </Card.Body>
        {successMessage && <Alert variant='success'>{successMessage}</Alert>}
        {error && <Alert variant='danger'>{error}</Alert>}
        {waitMessage && <Alert variant='primary'>{waitMessage}</Alert>}
        <Button variant='success' disabled={false} className='w-100' onClick={confirmTransfer}>
          PAGO (ZERAR DIVIDA)
        </Button>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}