import React, { useEffect, useState, useRef } from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import { useAuth } from "../contexts/AuthContext"
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore'
import { clog, float2INFormat, sendEmailToList, toDateTime, toTimestamp } from '../auxiliary/helpers'
import { appDb } from '../firebase'
import { Link } from 'react-router-dom'


export default function SendBulkEmails() {

  const { currentAdminUser } = useAuth()

  const templateIdRef = useRef()
  const testEmailRef = useRef()

  const [isReadyToSend, setIsReadyToSend] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [error, setError] = useState('')

  const sendTestEmail = async () => {
    setSuccessMessage('')
    setError('')
    setWaitMessage('Wait for it...')
    if(!checkTemplateId(templateIdRef.current.value)) {
      setWaitMessage('')
      return
    }
    if(testEmailRef.current.value === '') {
      setWaitMessage('')
      return setError('Insira o e-mail teste')
    }
    const allEmailsSent = await sendAllEmails(templateIdRef.current.value, testEmailRef.current.value)
    setWaitMessage('')
    if(allEmailsSent) {
      setSuccessMessage('Email Teste enviado com sucesso')
      setIsReadyToSend(true)
    }
    else {
      setError(prev => 'ERRO AO ENVIAR EMAILS' + ' | ' + prev)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSuccessMessage('')
    setError('')
    setWaitMessage('Wait for it...')
    if(!checkTemplateId(templateIdRef.current.value)) {
      setWaitMessage('')
      return
    }
    const allEmailsSent = await sendAllEmails(templateIdRef.current.value)
    setWaitMessage('')
    if(allEmailsSent) {
      setSuccessMessage('Emails enviados com sucesso')
    }
    else {
      setError(prev => 'ERRO AO ENVIAR EMAILS' + ' | ' + prev)
    }
  }

  const sendAllEmails = async (templateId, testEmail='') => {
    try {
      const response = await sendEmailToList(currentAdminUser, templateId, testEmail)
      clog(`response`)
      clog(JSON.stringify(response, null, 2))
      if('error' in response) {
        setError(`Erro ao enviar: ${response.error}`)
        return false
      }
      if(!checkResponse(response)) {
        return false
      }
      return true
    }
    catch(error) {
      setError(`Mensagem de erro: ${error}`)
      return false
    }
  }

  const checkTemplateId = (templateId) => {
    if(templateIdRef.current.value === '') {
      setError('Preencha o campo Template Id')
      return false
    }
    if(templateId.length !== 34 || templateId.charAt(0) !== 'd' || templateId.charAt(1) !== '-') {
      setError('templateId deve conter 34 caracteres e iniciar com "d-"')
      return false      
    }
    return true
  }

  const checkResponse = (resposne) => {
    const responsesList = resposne.responsesList
    let responsesOk = true
    for(let i in responsesList) {
      if(!responsesList[i][0]) {
        setError(prev => prev + ' | ' + `EMAIL NÃO ENVIADO PARA: ${responsesList[i][1]}`)
        responsesOk = false
      }
    }
    return responsesOk
  }

  return (
    <>
      {waitMessage && <Alert variant='warning'>{waitMessage}</Alert>}
      {successMessage && <Alert variant='success'>{successMessage}</Alert>}
      {error && <Alert variant='danger'>{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group className='mb-3' controlId='templateId'>
          <Form.Label>Template Id</Form.Label>
          <Form.Control type='text' placeholder='d-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx' ref={templateIdRef} />
        </Form.Group>
        <Form.Group className='mb-3' controlId='email'>
          <Form.Label>Email Teste</Form.Label>
          <Form.Control type='email' placeholder='fulano@cryptohero.com.br' ref={testEmailRef} />
        </Form.Group>
        <Button variant='primary'
          className='w-100'
          type='submit'
          disabled={!isReadyToSend}>
          ENVIAR
        </Button>
        <br></br>
        <br></br>
      </Form>
      <Button variant='btn btn-outline-primary w-100 mt-3' className='w-100' onClick={sendTestEmail}>
        ENVIAR EMAIL TESTE
      </Button>
      <div className='w-100 text-center mt-2'>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}