// Third party imports
import React, { createContext, useContext, useEffect, useState } from 'react'
import { adminAuth, appAuth, appV2Auth, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, updateEmail, updatePassword } from '../firebase'

// My custom imports

// My contexts
const AuthContext = createContext()

// My custom hooks
export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    
  const [currentAdminUser, setCurrentAdminUser] = useState()
  const [currentAppUser, setCurrentAppUser] = useState()
  const [currentAppV2User, setCurrentAppV2User] = useState()
  const [loading, setLoading] = useState(true)

  const signup = async (email, password) => {
    await createUserWithEmailAndPassword(appAuth, email, password)
    await createUserWithEmailAndPassword(appV2Auth, email, password)
    return createUserWithEmailAndPassword(adminAuth, email, password)
  }

  const login = async (email, password) => {
    await signInWithEmailAndPassword(appAuth, email, password)
    await signInWithEmailAndPassword(appV2Auth, email, password)
    return signInWithEmailAndPassword(adminAuth, email, password)
  }

  const logout = async () => {
    await appAuth.signOut()
    await appV2Auth.signOut()
    return adminAuth.signOut()
  }

  const resetPassword = async (email) => {
    await sendPasswordResetEmail(appAuth, email)
    await sendPasswordResetEmail(appV2Auth, email)
    return sendPasswordResetEmail(adminAuth, email)
  }

  const emailUpdate = async (email) => {
    await updateEmail(currentAppUser, email)
    await updateEmail(currentAppV2User, email)
    return updateEmail(currentAdminUser, email)
  }

  const passwordUpdate = async (password) => {
    await updatePassword(currentAppUser, password)
    await updatePassword(currentAppV2User, password)
    return updatePassword(currentAdminUser, password)
  }

  useEffect(() => {
    const unsubscribeAdmin = adminAuth.onAuthStateChanged(user => {
      setCurrentAdminUser(user)
      setLoading(false)
    })
    return unsubscribeAdmin
  }, [])

  useEffect(() => {
    const unsubscribeApp = appAuth.onAuthStateChanged(user => {
      setCurrentAppUser(user)
      setLoading(false)
    })
    return unsubscribeApp
  }, [])

  useEffect(() => {
    const unsubscribeAppV2 = appV2Auth.onAuthStateChanged(user => {
      setCurrentAppV2User(user)
      setLoading(false)
    })
    return unsubscribeAppV2
  }, [])

  const value = {
    currentAdminUser,
    currentAppUser,
    currentAppV2User,
    login,
    signup,
    logout,
    resetPassword,
    emailUpdate,
    passwordUpdate,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}