// Third party imports
import React from 'react'
import { createContext, useContext } from 'react'
import { adminDb } from '../firebase'

// My custom imports

// My contexts
const DatabaseContext = createContext()

// My custom hooks
export function useDatabase() {
    return useContext(DatabaseContext)
}

export function DatabaseProvider({children}) {

  const value = {
    adminDb
  }

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  )
}