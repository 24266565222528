import React, { useState, useEffect } from 'react'
import { Form, Button, Card, Alert, ListGroup } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
// import { useDatabase } from '../contexts/DatabaseContext'
import { appDb } from '../firebase'
import { doc, getDoc, updateDoc, setDoc } from '../firebase'
import { Timestamp } from 'firebase/firestore'
import { Link, useLocation } from 'react-router-dom'

// import buyResponseMock from '../mocks/buyResponse2.json'
import Coin from './Coin'
import { clog, recordError, secsToDateTime, formatPrice, formatCpf, sendEmail, copyToClipboard } from '../auxiliary/helpers'

export default function BuyProduct() {

  const location = useLocation()
  const { userOrder } = location.state

  const { currentAdminUser, currentAppUser } = useAuth()

  const [orderMessage, setOrderMessage] = useState('')
  const [error, setError] = useState('')
  const [notice, setNotice] = useState('')
  const [disabledButton, setDisabledButton] = useState(true)
  const [finalizedBuy, setFinalizedBuy] = useState(false)


  const recordUpdatedOrder = async () => {
    try {
      const userProductsRef = doc(appDb, `/users/${userOrder.userId}/userProducts/${userOrder.heroId}_${userOrder.productId}`)
      const userProductsDoc = await getDoc(userProductsRef)
      console.log('userProductsDoc.exists')
      console.log(userProductsDoc.exists)
      if (!userProductsDoc.exists()) {
        const recordData = {
          access: true,
        }
        await setDoc(userProductsRef, recordData, { merge: true })
      }

      const docId = userOrder.orderId
      const orderRef = doc(appDb, 'productsOrders', docId)
      await updateDoc(orderRef, {
        status: 'FULFILLED',
        timeUpdate: Timestamp.now()
      })

      const userEmail = userOrder.personalInfo.email
      const templateId = 'd-7c77769a55994affbcb9718c18701771'
      const dynamicTemplateData = {
        name: userOrder.personalInfo.name,
        productTitle: userOrder.productTitle,
        prodPageName: userOrder.prodPageName,
        productId: userOrder.productId,
        hero: userOrder.heroId,
      }
      const emailSent = await sendEmail(currentAppUser, userEmail, templateId, dynamicTemplateData)
      setFinalizedBuy(true)
      setOrderMessage('Success! -- Click "Back to Orders List" link to return to list')
    }
    catch(error) {
      // If there is an error or a partial fulfillment
      setError(error)
      return false
    }
  }

  const handleValueChange = (event) => {
    const target = event.target
    if(target.type === 'checkbox') {
      setDisabledButton(!target.checked)
      return
    }
  }

  const copyInfo = (info, value) => {
    copyToClipboard(value)
    setNotice(`${info} copied`)
  }

  const globalStatusStyle = {
    color: userOrder.status === 'ERROR' ? 'red' :
    (userOrder.status === 'FULFILLED' ? 'green' :
    (userOrder.status === 'PARTIAL' ? 'orange' : 'default'))
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className='text-center mb-4'>Buy InfoProduct</h2>
          <br></br>
          <br></br>

          <Card style={{ width: '40rem' }}>
            <p>Account Info:</p>
            <p>User Order Info:</p>
            <ListGroup variant='flush'>
              <ListGroup.Item>Time: {secsToDateTime(parseFloat(userOrder.timeCreation.seconds))}</ListGroup.Item>
              <ListGroup.Item style={globalStatusStyle}>Status: {userOrder.status}</ListGroup.Item>
              <Card onClick={() => copyInfo('Order ID', userOrder.orderId)} style={{cursor: 'pointer'}}>
                {notice && <Alert variant='primary'>{notice}</Alert>}
                <ListGroup.Item>Order: {userOrder.orderId}</ListGroup.Item>
              </Card>
              <Card onClick={() => copyInfo('User ID', userOrder.userId)} style={{cursor: 'pointer'}}>
                {notice && <Alert variant='primary'>{notice}</Alert>}
                <ListGroup.Item>User ID: {userOrder.userId}</ListGroup.Item>
              </Card>
              <ListGroup.Item>User name: {userOrder.personalInfo.name}</ListGroup.Item>
              <ListGroup.Item>User Email: {userOrder.personalInfo.email}</ListGroup.Item>
              <ListGroup.Item>User CPF: {formatCpf(userOrder.personalInfo.cpf)}</ListGroup.Item>
              <ListGroup.Item>Product: {userOrder.productId}</ListGroup.Item>
              <ListGroup.Item>Hero: {userOrder.heroId}</ListGroup.Item>
              <Card onClick={() => copyInfo('Investment Value', userOrder.productValue)} style={{cursor: 'pointer'}}>
                {notice && <Alert variant='primary'>{notice}</Alert>}
                <ListGroup.Item>Investment Value: {userOrder.productValue} {userOrder.fiatSymbol}</ListGroup.Item>
              </Card>
            </ListGroup>
          </Card>
          <br></br>
          <Form>
            <Form.Check
              type={'checkbox'}
              id={`default-${'checkbox'}`}
              label={`PIX of ${
                formatPrice(parseFloat(userOrder.productValue), userOrder.fiatSymbol)
              } confirmed`}
              onChange={handleValueChange}
              style={{cursor: 'pointer'}}
            />
            <br></br>
            {error && <Alert variant='danger'>{error}</Alert>}
            {orderMessage && <Alert variant='success'>{orderMessage}</Alert>}
            {/* {waitMessage && <Alert variant='primary'>{waitMessage}</Alert>} */}
            <Button variant='success' disabled={disabledButton || finalizedBuy} className='w-100' onClick={recordUpdatedOrder}>
              Buy
            </Button>
            <br></br>
            <br></br>
          </Form>
        </Card.Body>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Link to='/users-buy-orders'>Back to Orders List</Link>
        <br></br>
        <br></br>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}


/*
https://app.cryptohero.com.br/${prodPageName}?hero=mauricio2&productId=${productId}
*/