// Third party imports
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import params from '../constants/params.json'


// My custom imports

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow)
        newWindow.opener = null
}

export const onClickUrl = (url) => {
    return () => openInNewTab(url)
}

export function clog(text) {
    console.log(text)
}

export function cloj(value) {
    console.log(JSON.stringify(value, null, 2))
}

export function varToString(varObj) {
    return Object.keys(varObj)[0]
}

export function buildMap(keys, values) {
    const map = {}
    for(let i = 0; i < keys.length; i++)
        map[keys[i]] = values[i]
    return map
}

export function isHex(hex) {
    if (typeof hex === 'string' || hex instanceof String) {
        hex = hex.replace('0x','')
        hex = hex.replace('0X','')
        let hexInt = parseInt(hex, 16)
        return (hexInt.toString(16) === hex)
    }
    else
        return false
}

export function hex2Int(hex) {
    if (typeof hex === 'string' || hex instanceof String)
        return parseInt(hex, 16)
    else
        return hex
}

export function sleep(milliseconds) {
    const date = Date.now()
    let currentDate = null
    do {
        currentDate = Date.now()
    } while (currentDate - date < milliseconds)
}

export function string2Float(strNumber) {
    const newStrNumber = strNumber.replace(',', '.')
    return parseFloat(newStrNumber)
}

export function round(number, decimals) {
    return parseFloat(number.toFixed(decimals))
}

export async function fetchImage(imageUrl) {
    const response = await fetch(imageUrl)
    const imageBlob = await response.blob()
    const reader = new FileReader()
    reader.readAsDataURL(imageBlob)
    reader.onloadend = () => {
        const base64data = reader.result
        console.log(base64data)
    }
}

export function getImageCryptoLogos(coinName, symbol) {
    const url = 'https://cryptologos.cc/logos/'
    let coinNameAdjusted = ''
    let coinSymbolAdjusted = ''
    coinSymbolAdjusted = coinName === 'Ethereum' ? 'eth' : symbol.toLowerCase()
    switch(coinSymbolAdjusted) {
        case 'usdt':
            coinNameAdjusted = 'tether'
            break
        case 'sushi':
            coinNameAdjusted = 'sushiswap'
            break
        case 'uni':
            coinNameAdjusted = 'uniswap'
            break
        default:
            coinNameAdjusted = coinName.toLowerCase().replace(' ', '-')
    }
    
    coinSymbolAdjusted = coinName === 'Ethereum' ? 'eth' : symbol.toLowerCase()


    return url + coinNameAdjusted + '-' + coinSymbolAdjusted + '-logo.png'
}

export function average(arr) {
    const average = arr.reduce((a, b) => a + b, 0) / arr.length
    return average
}

export function reloadPage() {
    window.location.reload()
}

export function shortenAddress(address) {
    return address.substr(0, 5) + '...' + address.substr(38, 4)
}

export function isEVMAddress(stringValue) {
    return stringValue.substr(0, 2) === '0x' && stringValue.length === 42
}

export function isEVMAddressInit(stringValue) {
    return stringValue.substr(0, 2) === '0x'
}

export function handleError(error) {
    clog(error)
}

export async function recordError(db, errorInfo) {
  const error = {}
  error.timestamp = serverTimestamp()
  error.info = errorInfo
  const docRef = await addDoc(collection(db, 'errors'), error)
  console.log('Document written with ID: ', docRef.id)
}

export const formatPrice = (numFloat, fiatSymbol='BRL') => {
  const currencySymbol = {
    'BRL': 'R$',
    'USD': '$'
  }
  let numString = numFloat.toFixed(2).replace('.', ',')
  const len = numString.length
  let count = 1
  for(let i = len-4; i > 0; i-- ) {
    if(count % 3 === 0 && i > 0) {
      numString = numString.slice(0, i) + '.' + numString.slice(i)
    }
    count++
  }
  return currencySymbol[fiatSymbol] + '' + numString
}

export const formatPercentage = (numFloat) => {
  return (numFloat*100).toFixed(1).replace('.', ',') + ' %'
}

export const secsToDateTime = (secs) => {
  var dateTime = new Date(secs * 1000)
  return dateTime.toString()
}

export const formatCpf = (cpf) => {
  return cpf.substr(0, 3) + '.' + cpf.substr(3, 3) + '.' + cpf.substr(6, 3) + '-' + cpf.substr(9, 2)
}

export const formatPhone = (phone) => {
  return '(' + phone.substr(0, 2) + ')' + ' ' + phone.substr(2, 5) + '-' + phone.substr(7)
}

export const sendEmail = async (currentUser, userEmail, templateId, dynamicTemplateData) => {
  // return true
  /***************************************************/
  const EMULATOR = false
  /***************************************************/

  const baseUrl = EMULATOR ?
  'http://localhost:5002/ch-app-v1/southamerica-east1/' :
  'https://southamerica-east1-ch-app-v1.cloudfunctions.net/'

  const authTokenId = await currentUser.getIdToken()

  const options = {
    headers: {
      'Authorization': 'Bearer ' + authTokenId,
    }
  }
  const cloudFunction = 'sendGrid'
  const url = `${baseUrl}${cloudFunction}?uid=${currentUser.uid}&userEmail=${userEmail}&templateId=${templateId}&dynamicTemplateData=${JSON.stringify(dynamicTemplateData)}`  
  try {
    const response = await fetch(url, options)
    if(response.status === 200) return true
    return false
  }
  catch(err) {
    console.log(err)
    return false
  }
}

export const sendEmailToList = async (currentUser, templateId, testEmail='') => {

  const backend = 'admin'
  /***************************************************/
  const EMULATOR = false
  /***************************************************/
  const cloudFunction = 'sendEmailToList'
  const queryData = [
    {
      name: 'templateId',
      value: templateId,
    },
    {
      name: 'testEmail',
      value: testEmail,
    },
  ] 
  try {
    const response = await fetchUrl(currentUser, backend, EMULATOR, cloudFunction, queryData)
    return response
  }
  catch(err) {
    console.log(err)
    return {error: err}
  }
}

export const copyToClipboard = async (copyText) => {
  try {
    await navigator.clipboard.writeText(copyText)
    return true
  }
  catch(error) {
    console.log(error)
    return false
  }
}

export const getPrice = async (pairSymbol, priceType, binanceAccount) => {
  return new Promise((resolve, reject) => {
    binanceAccount.bookTickers(pairSymbol, (error, ticker) => {
      if(error) reject('error: ' + JSON.stringify(error, null, 2));
      else {
        resolve(parseFloat(ticker[priceType]))
      }
    })
  })
}

export const balanceOf = async (symbol, binanceAccount) => {
  return new Promise((resolve, reject) => {
    binanceAccount.balance((error, balances) => {
      if(error) reject('error: ' + JSON.stringify(error, null, 2));
      else resolve(parseFloat(balances[symbol].available));
    });
  })
}

export const fetchUrl = async (currentUser, backend, emulator, cloudFunction, queryData) => {
  const authTokenId = await currentUser.getIdToken()
    const options = {
      headers: {
        'Authorization': 'Bearer ' + authTokenId,
        'Access-Control-Request-Headers': 'Content-Type, Authorization',
      }
    }
    let baseUrl
    if(backend === 'admin') {
      baseUrl = emulator ?
      'http://localhost:5001/ch-admin-v1/southamerica-east1/' :
      'https://southamerica-east1-ch-admin-v1.cloudfunctions.net/'
    }
    if(backend === 'app') {
      baseUrl = emulator ?
      'http://localhost:5002/ch-app-v1/southamerica-east1/' :
      'https://southamerica-east1-ch-app-v1.cloudfunctions.net/'
    }
    let url = `${baseUrl}${cloudFunction}?uid=${currentUser.uid}`
    // queryData = [
    //   {
    //   name: 'NAMEE',
    //   value: VALUEE,
    //   },
    //   {
    //   name: 'NAMEE',
    //   value: VALUEE,
    //   },
    // ]
    queryData.forEach(param => {
      url += '&' + param.name + '=' + param.value
    })
    try {
      console.log('FETCH INITIATED...')
      console.log(`URL: ${url}`)
      const data = await fetch(url, options)
      const response = await data.json()
      return response
    }
    catch(error) {
      console.log(error)
      return {error: error}
    }
}

export const fetchCloudFunction = async (currentUser, backend, emulator, cloudFunction, queryData) => {
  const authTokenId = await currentUser.getIdToken()
    const options = {
      headers: {
        'Authorization': 'Bearer ' + authTokenId,
        'Access-Control-Request-Headers': 'Content-Type, Authorization',
      }
    }
    let baseUrl
    if(backend === 'admin') {
      baseUrl = emulator ?
      'http://localhost:5001/ch-admin-v1/southamerica-east1/' :
      'https://southamerica-east1-ch-admin-v1.cloudfunctions.net/'
    }
    if(backend === 'app') {
      baseUrl = emulator ?
      'http://localhost:5002/ch-app-v1/southamerica-east1/' :
      'https://southamerica-east1-ch-app-v1.cloudfunctions.net/'
    }
    let url = `${baseUrl}${cloudFunction}?uid=${currentUser.uid}`
    const queryDataKeys = Object.keys(queryData)
    queryDataKeys.forEach(key => {
      url += '&' + key + '=' + queryData[key]
    })
    try {
      console.log('FETCH INITIATED...')
      const data = await fetch(url, options)
      const response = await data.json()
      return response
    }
    catch(error) {
      console.log('error fetchCloudFunction')
      console.error(error)
      throw new Error(error)
    }
}

const getBaseUrl = (backend, emulator) => {
  let baseUrl
  if(backend === 'admin') {
    baseUrl = emulator ?
    'http://localhost:5001/ch-admin-v1/southamerica-east1/' :
    'https://southamerica-east1-ch-admin-v1.cloudfunctions.net/'
  }
  if(backend === 'app') {
    baseUrl = emulator ?
    'http://localhost:5002/ch-app-v1/southamerica-east1/' :
    'https://southamerica-east1-ch-app-v1.cloudfunctions.net/'
  }
  return baseUrl
}


export const callUrlCloudFunction = async (
  baseUrl,
  cloudFunction,
  queryData,
  options = {},
  method = 'GET'
) => {
  try {
    let url = `${baseUrl}/${cloudFunction}`;
    let isFirst = true;
    for (const paramName in queryData) {
      url +=
        (isFirst ? '?' : '&') +
        paramName.toString() +
        '=' +
        String(queryData[paramName]);
      isFirst = false;
    }
    clog(url)
    const data = await fetch(url, {...options, method: method});
    const response = await data.json();
    return response;
  } catch (error) {
    clog('>>> error callUrlCloudFunction');
    clog(error);
    throw new Error(error);
  }
};

export const axiosFetchCloudFunction = async (currentUser, backend, emulator, cloudFunction, method='get', queryData={}, headersData={}, bodyData={}, timeout=0) => {
  try {
    const authTokenId = await currentUser.getIdToken()
    const baseUrl = getBaseUrl(backend, emulator)
    
    const url = cloudFunction
    const config = {
      method: method,
      headers: {
        'Authorization': 'Bearer ' + authTokenId,
        ...headersData,
      },
      url: url,
      baseURL: baseUrl,
      params: {
        uid: currentUser.uid,
        ...queryData,
      },
      data: bodyData,
      timeout: timeout
    }
    const axios = require('axios').default;
    console.log('FETCH INITIATED...')
    const response = await axios(config)
    return response
  }
    catch(error) {
      console.log('error fetchCloudFunction')
      console.error(error)
      throw new Error(error)
    }
}

export const httpRequest = async (url, method='get', queryData={}, headersData={}, bodyData={}, timeout=0) => {
  try {
    const config = {
      method: method,
      headers: headersData,
      url: url,
      params: queryData,
      data: bodyData,
      timeout: timeout
    }
    const axios = require('axios').default;
    console.log('FETCH INITIATED...')
    const response = await axios(config)
    return response
  }
  catch(error) {
    console.log('error httpRequest')
    console.error(error)
    throw new Error(error)
  }
}

export const toDateTime = (secs) => {
  const myDate = new Date(secs * 1000)
  return myDate.toLocaleString()
}

export function convertTimestampToBrazil(secs) {
  const data = new Date(secs * 1000);

  function adicionaZero(numero) {
    return numero < 10 ? `0${numero}` : numero;
  }

  const dia = adicionaZero(data.getDate());
  const mes = adicionaZero(data.getMonth() + 1);
  const ano = data.getFullYear();
  const horas = adicionaZero(data.getHours());
  const minutos = adicionaZero(data.getMinutes());
  const segundos = adicionaZero(data.getSeconds());

  const formatoBrasileiro = `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;

  return formatoBrasileiro;
}

export const toDateTimeWithoutTime = (secs) => {
  const myDate = new Date(secs * 1000)
  return myDate.toLocaleDateString()
}

export const getCurrentTimestampInSeconds = () => {
  const currentDate = new Date();
  const timestampInSeconds = Math.floor(currentDate.getTime() / 1000);
  return timestampInSeconds;
}

export function getUnixTimeOfFirstDayOfMonth(year, month) {
  // Create a Date object for the 1st day of the specified month and year
  const firstDayOfMonth = new Date(year, month - 1, 1); // Note: Months are 0-based in JavaScript

  // Get the Unix time (in milliseconds) for the 1st day of the month
  const unixTime = firstDayOfMonth.getTime() / 1000; // Convert to seconds

  return unixTime;
}

export function getCurrentMonth() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are 0-based
  return currentMonth;
}


export const getTimestampLastMonth = () => {
  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() - 1)
  currentDate.setDate(1)
  currentDate.setHours(0, 0, 0, 0);
  return currentDate
}

// TODO -> essa função está errada
// o cálculo é feito no timezone local. CUIDADO!!
export const toLongFormatDateTime = (secs) => {
  const t = new Date(1970, 0, 1) // Epoch
  t.setSeconds(secs)
  return t
}

export const toTimestamp = (strDate, timezone=-3) => {
  var datum = Date.parse(strDate)
  return datum / 1000 - timezone * 60 * 60
}

export const  getTimeDifferenceToGMT = () => {
  const now = new Date(); //
  const offsetMinutes = now.getTimezoneOffset(); 

  
  const offsetHours = -offsetMinutes / 60;

  return offsetHours;
}

const timeDifference = getTimeDifferenceToGMT();
console.log(`Time difference to GMT: ${timeDifference} hours`);

export const variableToString = varObj => Object.keys(varObj)[0]

export const exchangeInfo = async (pairSymbolList, binanceAccount) => {
  return new Promise((resolve, reject) => {
    binanceAccount.exchangeInfo((error, data) => {
      if (error) {
        console.log('error')
        console.log(error)
        reject('error: ' + JSON.stringify(error, null, 2));
      }
      else {
        console.log('ENTROU 3')
        const exchangeInfoList = data.symbols.filter(elelemt => pairSymbolList.includes(elelemt.symbol));
        resolve(exchangeInfoList)
      }
    });
  });
}

export const arraySum = (array) => {
  return array.reduce((partialSum, a) => partialSum + a, 0)
}

export const getOnlyNumbers = (str) => {
  return str.replace(/\D/g, '')
}

export const reorderList = (oldList, orderKeyParam) => {
  let newList = oldList
  newList.sort(function (a, b) {
    const keyA = a[orderKeyParam]
    const keyB = b[orderKeyParam]
    // Compare the 2 dates
    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  })
  return newList
}

export const reorderListSubParam = (oldList, orderKeyParam, orderKeySubParam) => {
  let newList = oldList
  newList.sort(function (a, b) {
    const keyA = a[orderKeyParam][orderKeySubParam]
    const keyB = b[orderKeyParam][orderKeySubParam]
    // Compare the 2 dates
    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  })
  return newList
}

// Ex.:
// (132.1568, 2) -> 13216
// (123456.456789, 5) -> 12345645679
// (123456, 5) -> 12345600000
// (123456.123, 5) -> 12345612300
// (0.00343459447936738, 10) -> 00034345945
export const float2INFormat = (number, decimals) => {
  const roundedNumber = round(number, decimals)
  const numberInStringFormat = String(roundedNumber)
  if(numberInStringFormat.includes('.')) {
    const pointIndex = numberInStringFormat.indexOf('.')
    const lastindex = numberInStringFormat.length - 1
    const totalDecimals = lastindex - pointIndex
    let zerosAfterPoint = ''
    
    if(decimals > totalDecimals) {
      zerosAfterPoint = '0'.repeat(decimals - totalDecimals)
    }
    const removedPoint = numberInStringFormat.replace('.', '')
    return removedPoint + zerosAfterPoint
  }
  const zerosAfterPoint = '0'.repeat(decimals)
  return numberInStringFormat + zerosAfterPoint
}

export const darkenHexColor = (hexColor, factor) => {
  // Remove the # symbol if it exists
  hexColor = hexColor.replace('#', '');

  // Parse the hexadecimal color value
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Calculate the darkened color values
  const newR = Math.max(0, Math.round(r * factor));
  const newG = Math.max(0, Math.round(g * factor));
  const newB = Math.max(0, Math.round(b * factor));

  // Convert the darkened color values back to hexadecimal
  const darkenedColor = `#${newR.toString(16).padStart(2, '0')}${newG
    .toString(16)
    .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

  return darkenedColor;
};

export class Pix {
  constructor(pixKey, description, merchantName, merchantCity, txid, amount) {
    this.pixKey = pixKey
    this.description = description
    this.merchantName = merchantName
    this.merchantCity = merchantCity
    this.txid = txid
    this.amount = amount.toFixed(2)

    this.ID_PAYLOAD_FORMAT_INDICATOR = '00'
    this.ID_MERCHANT_ACCOUNT_INFORMATION = '26'
    this.ID_MERCHANT_ACCOUNT_INFORMATION_GUI = '00'
    this.ID_MERCHANT_ACCOUNT_INFORMATION_KEY = '01'
    this.ID_MERCHANT_ACCOUNT_INFORMATION_DESCRIPTION = '02'
    this.ID_MERCHANT_CATEGORY_CODE = '52'
    this.ID_TRANSACTION_CURRENCY = '53'
    this.ID_TRANSACTION_AMOUNT = '54'
    this.ID_COUNTRY_CODE = '58'
    this.ID_MERCHANT_NAME = '59'
    this.ID_MERCHANT_CITY = '60'
    this.ID_ADDITIONAL_DATA_FIELD_TEMPLATE = '62'
    this.ID_ADDITIONAL_DATA_FIELD_TEMPLATE_TXID = '05'
    this.ID_CRC16 = '63'
  }

  _getValue(id, value) {
    const size = String(value.length).padStart(2, '0')
    return id + size + value
  }

  _getMechantAccountInfo() {
    const gui = this._getValue(
      this.ID_MERCHANT_ACCOUNT_INFORMATION_GUI,
      'br.gov.bcb.pix'
    )
    const key = this._getValue(
      this.ID_MERCHANT_ACCOUNT_INFORMATION_KEY,
      this.pixKey
    )
    const description = this._getValue(
      this.ID_MERCHANT_ACCOUNT_INFORMATION_DESCRIPTION,
      this.description
    )

    return this._getValue(
      this.ID_MERCHANT_ACCOUNT_INFORMATION,
      gui + key + description
    )
  }

  _getAdditionalDataFieldTemplate() {
    const txid = this._getValue(
      this.ID_ADDITIONAL_DATA_FIELD_TEMPLATE_TXID,
      this.txid
    )
    return this._getValue(this.ID_ADDITIONAL_DATA_FIELD_TEMPLATE, txid)
  }

  getPayload() {
    const payload =
    this._getValue(this.ID_PAYLOAD_FORMAT_INDICATOR, '01') +
    this._getMechantAccountInfo() +
    this._getValue(this.ID_MERCHANT_CATEGORY_CODE, '0000') +
    this._getValue(this.ID_TRANSACTION_CURRENCY, '986') +
    this._getValue(this.ID_TRANSACTION_AMOUNT, this.amount) +
    this._getValue(this.ID_COUNTRY_CODE, 'BR') +
    this._getValue(this.ID_MERCHANT_NAME, this.merchantName) +
    this._getValue(this.ID_MERCHANT_CITY, this.merchantCity) +
    this._getAdditionalDataFieldTemplate()

    // return payload + this._getCRC16(payload)
    return payload + this._getCRC16(payload)
  }

  // _getCRC16(payload) {
  //   var crc = require('crc');
  //   const result = crc.crc16(payload).toString(16)

  //   clog(`result = ${result}`)

  //   return this.ID_CRC16 + '04' + result
  // }

  _getCRC16(payload) {
    function ord(str) {
      return str.charCodeAt(0)
    }
    function dechex(number) {
      if (parseFloat(String(number)) < 0) {
        number = 0xffffffff + number + 1
      }
      let finalNumber = parseInt(number, 10).toString(16)
      if(finalNumber.length === 3) finalNumber = '0' + finalNumber
      if(finalNumber.length === 2) finalNumber = '00' + finalNumber
      if(finalNumber.length === 1) finalNumber = '000' + finalNumber
      return finalNumber
    }

    //ADICIONA DADOS GERAIS NO PAYLOAD
    payload = payload + this.ID_CRC16 + '04'

    //DADOS DEFINIDOS PELO BACEN
    let polinomio = 0x1021
    let resultado = 0xffff
    let length

    //CHECKSUM
    if ((length = payload.length) > 0) {
      for (let offset = 0; offset < length; offset++) {
        resultado ^= ord(payload[offset]) << 8
        for (let bitwise = 0; bitwise < 8; bitwise++) {
            if ((resultado <<= 1) & 0x10000) resultado ^= polinomio
                resultado &= 0xffff
        }
      }
    }

    //RETORNA CÓDIGO CRC16 DE 4 CARACTERES
    return this.ID_CRC16 + '04' + dechex(resultado).toUpperCase()
  }
}

export const getUserBrlBalance = async (
  currentAppV2User,
  userId,
  time
) => {
  const baseUrl = params.isProduction ?
  'https://southamerica-east1-heroinvest-5fabd.cloudfunctions.net' :
  'https://southamerica-east1-ch-app-v2-dev.cloudfunctions.net'
  const cloudFunction = 'getUserBrlBalance'

  const queryData = {
    requestUserId: currentAppV2User.uid,
    userId:userId,
    time: time
  }

  const resp = await callUrlCloudFunction(
    baseUrl,
    cloudFunction,
    queryData,
  )
  return resp
}

export const confirmTokensBasketBuy = async (
  currentAppV2User,
  orderId,
  tradedAveragePrices,
  fiatPrices
) => {
  const baseUrl = params.isProduction ?
  'https://southamerica-east1-heroinvest-5fabd.cloudfunctions.net' :
  'https://southamerica-east1-ch-app-v2-dev.cloudfunctions.net'
  const cloudFunction = 'confirmTokensBasketBuy'

  const queryData = {
    requestUserId: currentAppV2User.uid,
    orderId: orderId,
    tradedAveragePrices: JSON.stringify(tradedAveragePrices),
    fiatPrices: JSON.stringify(fiatPrices)
  }

  const resp = await callUrlCloudFunction(
    baseUrl,
    cloudFunction,
    queryData,
  )
  return resp
}

export const confirmPayedSellOrder = async (
  currentAppV2User,
  orderId,
) => {
  const baseUrl = params.isProduction ?
  'https://southamerica-east1-heroinvest-5fabd.cloudfunctions.net' :
  'https://southamerica-east1-ch-app-v2-dev.cloudfunctions.net'
  const cloudFunction = 'confirmPayedSellOrder'

  const queryData = {
    requestUserId: currentAppV2User.uid,
    orderId: orderId
  }

  const resp = await callUrlCloudFunction(
    baseUrl,
    cloudFunction,
    queryData,
  )
  return resp
}

export const confirmOrderPayed = async (
  currentAppV2User,
  orderId,
  amount,
  depositId,
  taxId,
  discountedFee,
  cameFromUserBalance,
) => {
  const baseUrl = params.isProduction ?
  'https://southamerica-east1-heroinvest-5fabd.cloudfunctions.net' :
  'https://southamerica-east1-ch-app-v2-dev.cloudfunctions.net'
  const cloudFunction = 'confirmOrderPayed'

  const queryData = {
    requestUserId: currentAppV2User.uid,
    orderId: orderId,
    amount: amount,
    depositId: depositId,
    taxId: taxId,
    discountedFee: discountedFee,
    cameFromUserBalance: cameFromUserBalance,
  }

  const resp = await callUrlCloudFunction(
    baseUrl,
    cloudFunction,
    queryData,
  )
  return resp
}

export function formatString(nome) {
  // Divide a string em palavras usando o espaço como delimitador
  let palavras = nome.split(' ');

  // Capitaliza a primeira letra de cada palavra
  let palavrasFormatadas = palavras.map(function(palavra) {
      return palavra.charAt(0).toUpperCase() + palavra.slice(1);
  });

  // Junta as palavras de volta em uma string e retorna o resultado
  return palavrasFormatadas.join(' ');
}

// export function wei2Float(numberWei, tokenDecimals=18) {
//     return string2Float(Moralis.Units.FromWei(numberWei, tokenDecimals))
// }

// export function number2Wei(numberFloat, tokenDecimals=18) {
//     return Moralis.Units.Token(numberFloat, tokenDecimals)
// }