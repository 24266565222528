import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { getPairSymbolPrice } from '../auxiliary/broker'
import { clog, cloj, formatCpf, getTimestampLastMonth, getUnixTimeOfFirstDayOfMonth, getUserBrlBalance, round, toDateTime } from '../auxiliary/helpers'
import { useAuth } from '../contexts/AuthContext'

import { collection, getDocs, query, where } from '@firebase/firestore'
import { appV2Db } from '../firebase'
// import ordersTest from '../mock/orders-test2.json'

const STABLE_COIN_SYMBOL = 'USDT'
const FIAT_SYMBOL = 'BRL'

function CustomerReportsAppV2() {

  const symbolPrices = useRef({})
  const { currentAdminUser, currentAppV2User } = useAuth()
  const [initialDate, setInitialDate] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [error, setError] = useState('')
  const [dataToWrite, setDataToWrite] = useState('')
  const [dataToWriteCrypto, setDataToWriteCrypto] = useState('')
  const [dataToWriteTokens, setDataToWriteTokens] = useState('')
  const [month, setMonth] = useState(0)
  const [year, setYear] = useState(0)

  useEffect(() => {
      const timestampLastMonth = getTimestampLastMonth()
      clog(`timestampLastMonth = ${timestampLastMonth}`)
      setInitialDate(timestampLastMonth)
  }, [])

  useEffect(() => {
    if(initialDate !== '') {
      const initialDateTimestamp = Math.floor(initialDate.getTime() / 1000 )
      const initialDateMilisecond = new Date(initialDateTimestamp * 1000)
      setMonth(initialDateMilisecond.getMonth() + 1)
      setYear(initialDateMilisecond.getFullYear())
    }
  }, [initialDate])

  const getPairPrice = async (pairSymbol, timePriceReference) => {
    if(pairSymbol in symbolPrices.current) {
      return symbolPrices.current[pairSymbol]
    }
    const pairPrice = await getPairSymbolPrice(currentAdminUser, pairSymbol, timePriceReference)
    const prevSymbolPrices = symbolPrices.current
    symbolPrices.current = {
      ...prevSymbolPrices,
      [pairSymbol]: pairPrice
    }
    return pairPrice
  }

  async function generateReport() {
    setError('')
    setSuccessMessage('')

    const currentDate = new Date()
    const unixCurrentTime = currentDate.getTime() / 1000; // Convert to seconds
    clog(currentDate)
    
    if(!currentDate.toString().includes('Brasilia Standard Time') && !currentDate.toString().includes('Horário Padrão de Brasília') && !currentDate.toString().includes('Brasília') && !currentDate.toString().includes('Brasília')) {
      setWaitMessage('')
      setError("Você deve estar no fuso de Brasília para usar essa funcionalidade");
      return;
    }

    // first day of the next month minus 1 sec to get 23:59:59 of the current month
    const finalDate = getUnixTimeOfFirstDayOfMonth(year, month + 1) - 1
    
    setWaitMessage(`Baixando relatório para: ${toDateTime(finalDate)}`)

    clog(`finalDate = ${finalDate} = ${toDateTime(finalDate)}`)
    
    if(finalDate > unixCurrentTime) {
      setWaitMessage('')
      setError("Tente novamente com uma data anterior ao mês atual");
      return;
    }

    
    const pairStableFiatSymbol = STABLE_COIN_SYMBOL + FIAT_SYMBOL
    const priceStableInFiat = await getPairPrice(pairStableFiatSymbol, finalDate)
    clog(`priceStableInFiat = ${priceStableInFiat}`)

    const ordersBuyRef = collection(appV2Db, 'ordersBuy')
    const ordersSellRef = collection(appV2Db, 'ordersSell')

    const q1Buy = query(ordersBuyRef, where('status', '==', 'FULFILLED'), where('pixStatus', '==', 'PAYED'), where('timeStatusFulfilled', '<=', finalDate))
    const q1Sell = query(ordersSellRef, where('status', '==', 'FULFILLED'), where('pixStatus', '==', 'PAYED'), where('timeStatusFulfilled', '<=', finalDate))

    const querySnapshotBuy = await getDocs(q1Buy)
    const querySnapshotSell = await getDocs(q1Sell)
    

    let userOrdersList = []

    if(!querySnapshotBuy.empty) {
      querySnapshotBuy.forEach(doc => {
        const order = doc.data()
        order.orderId = doc.id
        order.orderSide = 'BUY'
        userOrdersList.push(order)
      })
    }
    if(!querySnapshotSell.empty) {
      querySnapshotSell.forEach(doc => {
        const order = doc.data()
        order.orderId = doc.id
        order.orderSide = 'SELL'
        userOrdersList.push(order)
      })

    }

    clog('userOrdersList:')
    clog(userOrdersList)

    // const userOrdersList = ordersTest.filter(order => order.status === 'FULFILLED' && order.userId !== 'YETftQD0Y5goflAqDPAixTY3O2h2' && order.timeUpdate.seconds <= finalDate);
    // /\ if you want to test with the mock data, uncomment the line above and comment the firebase code above of it

    let csvReportDataCrypto = [
      ["IDs das ordens compra", "IDs das ordens venda", "ID do usuário", "CPF", "Nome", "Moeda", "Quantidade", "Valor em BRL" ,"Valor total"],
    ]

    let csvReportDataTokens = [
      ["IDs das ordens compra", "IDs das ordens venda", "ID do usuário", "CPF", "Nome", "Moeda", "Quantidade", "Valor em BRL", "Valor total"],
    ]

    const userOrderTotals = {}

    try {

    
    //CHECK BUY ORDERS
    for(let i in userOrdersList) {
      const order = userOrdersList[i]
      const orderId = order.orderId
      const orderSide = order.orderSide    // 'BUY' or 'SELL'
      if(orderSide === 'SELL') continue     //If orderSide is sell, go to the next iteration
      const userId = order.userId
      const userCpf = order.userInfo.cpf ? formatCpf(order.userInfo.cpf) : 'Não informado'
      const userName = order.userInfo.name
      const fulfilledCoins = order.basketInfo
      const type = fulfilledCoins[0].assetType
      let fulfillmentResponse = [];
      if(type === 'cryptocurrency') {
        fulfillmentResponse = order.finalBuyBasketResponse
      } else if(type === 'token') {
        fulfillmentResponse = order.tokensFulfillmentResponse
      }

      clog(type)

      if(type !== 'cryptocurrency' && type !== 'token'){
        alert(`Erro ao gerar relatório. Tipo de ativo não identificado na order: ${orderId}. Entre em contato com o Mauricio ou o Enzo.`)
        setError(`Erro ao gerar relatório. Tipo de ativo não identificado na order: ${orderId}. Entre em contato com o Mauricio ou o Enzo.`)
        return
      }


        
      for(let j in fulfilledCoins) {
        const symbol = fulfilledCoins[j].symbol   // pair symbol
        /* const coinAmount = fulfilledCoins[j].baseOrderQty */
        const assetType = fulfilledCoins[j].assetType
        /* const brlValue = fulfilledCoins[j].percentage * order.investedValue
        const PAIR = fulfilledCoins[j].symbol + STABLE_COIN_SYMBOL;
        const pairPrice = assetType === 'cryptocurrency' ? await getPairPrice(PAIR, finalDate) : 0; */
        const coinAmount = assetType === 'cryptocurrency' ? fulfillmentResponse.buyBasketResponsesList[j].userExecutedBaseQty : fulfillmentResponse[j].baseOrderQty

        if(!userOrderTotals[userId]) {
          userOrderTotals[userId] = {
            cpf: userCpf,
            name: userName,
            totalValue: 0,
            fulfilledCoins: {},
          }
        }

        if(assetType === 'cryptocurrency') {

          if (!userOrderTotals[userId].fulfilledCoins[symbol]) {
            userOrderTotals[userId].fulfilledCoins[symbol] = {
              amount: 0,
              ordersIdsBuy: '',
              ordersIdsSell: '',
              type: assetType,
              /* price: 0, */
            };
          }
          
          userOrderTotals[userId].fulfilledCoins[symbol] = {
            amount: userOrderTotals[userId].fulfilledCoins[symbol].amount + coinAmount,
            ordersIdsBuy: userOrderTotals[userId].fulfilledCoins[symbol].ordersIdsBuy + ' | ' + order.orderId,
            ordersIdsSell: userOrderTotals[userId].fulfilledCoins[symbol].ordersIdsSell,
            type: assetType,
            /* price: assetType === 'token' ? fulfillmentResponse[j].tradedAveragePrice : 0, */
          }
        } else if(assetType === 'token') {

          if (!userOrderTotals[userId].fulfilledCoins[symbol]) {
            userOrderTotals[userId].fulfilledCoins[symbol] = {
              amount: 0,
              ordersIdsBuy: '',
              ordersIdsSell: '',
              type: assetType,
              price: 0,
            };
          }
          
          userOrderTotals[userId].fulfilledCoins[symbol] = {
            amount: userOrderTotals[userId].fulfilledCoins[symbol].amount + coinAmount,
            ordersIdsBuy: userOrderTotals[userId].fulfilledCoins[symbol].ordersIdsBuy + ' | ' + order.orderId,
            ordersIdsSell: userOrderTotals[userId].fulfilledCoins[symbol].ordersIdsSell,
            type: assetType,
            price: fulfillmentResponse[j].tradedAveragePrice
          }
        }
      }
    }

    //CHECK SELL ORDERS
    for(let i in userOrdersList) {
      const order = userOrdersList[i]
      const orderSide = order.orderSide    // 'BUY' or 'SELL'
      if(orderSide === 'BUY') continue     //If orderSide is buy, go to the next iteration and skip the remaining code
      const userId = order.userId
      const userCpf = order.userInfo.cpf ? formatCpf(order.userInfo.cpf) : 'Não informado'
      const userName = order.userInfo.name
      const fulfilledCoins = order.basketInfo
      const type = fulfilledCoins[0].assetType
      let fulfillmentResponse = []
      if(type === 'cryptocurrency') {
        fulfillmentResponse = order.finalSellBasketResponse
      } else if(type === 'token') {
        fulfillmentResponse = order.tokensFulfillmentResponse
      }
      
      for(let j in fulfilledCoins) {
        const symbol = fulfilledCoins[j].symbol   // pair symbol
        /* const coinAmount = fulfilledCoins[j].baseOrderQty */
        const assetType = fulfilledCoins[j].assetType
        /* const brlValue = fulfilledCoins[j].percentage * order.investedValue
        const PAIR = fulfilledCoins[j].symbol + STABLE_COIN_SYMBOL;
        const pairPrice = assetType === 'cryptocurrency' ? await getPairPrice(PAIR, finalDate) : 0; */
        const coinAmount = fulfilledCoins[j].baseOrderQty;

        if(!userOrderTotals[userId]) {
          userOrderTotals[userId] = {
            cpf: userCpf,
            name: userName,
            totalValue: 0,
            fulfilledCoins: {},
          }
        }

        if(assetType === 'cryptocurrency') {
          if (!userOrderTotals[userId].fulfilledCoins[symbol]) {
            userOrderTotals[userId].fulfilledCoins[symbol] = {
              amount: 0,
              ordersIdsSell: '',
              ordersIdsBuy: '',
              type: assetType,
              /* price: 0, */
            };
          }
          
          userOrderTotals[userId].fulfilledCoins[symbol] = {
            amount: userOrderTotals[userId].fulfilledCoins[symbol].amount - coinAmount, //subtrai o valor da order de compra
            ordersIdsSell: userOrderTotals[userId].fulfilledCoins[symbol].ordersIdsSell + ' | ' + order.orderId,
            ordersIdsBuy: userOrderTotals[userId].fulfilledCoins[symbol].ordersIdsBuy,
          type: assetType,
          /* price: assetType === 'token' ? fulfillmentResponse[j].tradedAveragePrice : 0, */
        }
      }

      else if(assetType === 'token') {
        if (!userOrderTotals[userId].fulfilledCoins[symbol]) {
          userOrderTotals[userId].fulfilledCoins[symbol] = {
            amount: 0,
            ordersIdsSell: '',
            ordersIdsBuy: '',
            type: assetType,
            price: 0,
          };
        }
        
        userOrderTotals[userId].fulfilledCoins[symbol] = {
          amount: userOrderTotals[userId].fulfilledCoins[symbol].amount - coinAmount, //subtrai o valor da order de compra
          ordersIdsSell: userOrderTotals[userId].fulfilledCoins[symbol].ordersIdsSell + ' | ' + order.orderId,
          ordersIdsBuy: userOrderTotals[userId].fulfilledCoins[symbol].ordersIdsBuy,
        type: assetType,
        price: fulfillmentResponse[j].tradedAveragePrice
      }
    }
    } 

    }
    

    for (const userId in userOrderTotals) {
      const { fulfilledCoins } = userOrderTotals[userId];
      for (const symbol in fulfilledCoins) {
        if(fulfilledCoins[symbol].type === 'cryptocurrency') {
          const coinAmount = fulfilledCoins[symbol].amount;
          const PAIR = symbol + STABLE_COIN_SYMBOL;
          clog(`PAIR = ${PAIR}`)
          const pairPrice = await getPairPrice(PAIR, finalDate);
          const totalValueInFiat = coinAmount * pairPrice * priceStableInFiat;
          clog(`pairPrice of ${symbol} = ${pairPrice}`)
          userOrderTotals[userId].fulfilledCoins[symbol].coinValue = round(totalValueInFiat, 2)
          userOrderTotals[userId].fulfilledCoins[symbol].type = 'cryptocurrency'
          userOrderTotals[userId].totalValue += round(totalValueInFiat, 2);

        } else if(fulfilledCoins[symbol].type === 'token') {
          const tokenAmount = fulfilledCoins[symbol].amount;
          const tokenPrice = fulfilledCoins[symbol].price;
          const totalValue = tokenAmount * tokenPrice;
          userOrderTotals[userId].fulfilledCoins[symbol].coinValue = round(totalValue, 2)
          userOrderTotals[userId].fulfilledCoins[symbol].type = 'token'
          userOrderTotals[userId].totalValue += round(totalValue, 2);
        }
      }
    }

    clog('userOrderTotals')
    cloj(userOrderTotals)

    for(const userId in userOrderTotals) {
      const { cpf, totalValue, fulfilledCoins, name } = userOrderTotals[userId]

      const totalOfCoins = Object.keys(fulfilledCoins).length
      const totalOfCryptos = Object.keys(fulfilledCoins).filter(symbol => fulfilledCoins[symbol].type === 'cryptocurrency').length
      const totalOfTokens = Object.keys(fulfilledCoins).filter(symbol => fulfilledCoins[symbol].type === 'token').length

      let contCryptos = 1
      let contTokens = 1
      let totalValueOfCryptos = 0
      let totalValueOfTokens = 0

      for(const pairSymbol in fulfilledCoins) {
        const amount = fulfilledCoins[pairSymbol].amount
        const ordersIdsBuy = fulfilledCoins[pairSymbol].ordersIdsBuy
        const ordersIdsSell = fulfilledCoins[pairSymbol].ordersIdsSell
        const coinValue = fulfilledCoins[pairSymbol].coinValue
        const type = fulfilledCoins[pairSymbol].type

        const newLine = [
          ordersIdsBuy,
          ordersIdsSell,
          userId,
          cpf,
          name,
          pairSymbol.replace(STABLE_COIN_SYMBOL, ''),
          amount,
          round(coinValue, 2),
        ];

        if(totalOfCryptos === contCryptos && type === 'cryptocurrency') {
          csvReportDataCrypto.push(newLine)
          totalValueOfCryptos += coinValue
          const response = await getUserBrlBalance(currentAppV2User, userId, finalDate);
          const userBrlBalance = response.response.userBrlBalance;
          const totalValueWithBrl = totalValueOfCryptos + userBrlBalance
          const newLineWithBrl = ['', '', userId, cpf, name, 'BRL', userBrlBalance, round(userBrlBalance, 2)]
          newLineWithBrl.push(round(totalValueWithBrl, 2))
          csvReportDataCrypto.push(newLineWithBrl)
        } else if (totalOfTokens === contTokens && type === 'token') {
          totalValueOfTokens += coinValue
          newLine.push(round(totalValueOfTokens, 2))
          csvReportDataTokens.push(newLine)
        } else if(type === 'cryptocurrency') {
          csvReportDataCrypto.push(newLine)
          totalValueOfCryptos += coinValue
          contCryptos++
        } else if(type === 'token') {
          csvReportDataTokens.push(newLine)
          totalValueOfTokens += coinValue
          contTokens++
        }
      }
    }
    setDataToWriteCrypto(csvReportDataCrypto)
    setDataToWriteTokens(csvReportDataTokens)
    setWaitMessage('')
    setSuccessMessage('Report ready to download') 
  } catch(err) {
      console.error(err)
      setError('Erro ao gerar relatório. Entre em contato com o Mauricio ou o Enzo.')
    }
  }

  return (
    <>
      <Form>
        
        <Form.Group id='finalDate'>
          <Form.Label>Selecione o mês e ano desejado</Form.Label>
          <DatePicker
            selected={initialDate}
            onChange={(date) => setInitialDate(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            minDate={new Date("11-01-2022")}
            />
        </Form.Group>

        {waitMessage && <Alert variant='warning'>{waitMessage}</Alert>}
        {successMessage && <Alert variant='success'>{successMessage}</Alert>}
        {error && <Alert variant='danger'>{error}</Alert>}
        <Button variant='primary' className='w-100' onClick={generateReport}>
          Gerar RELATÓRIO
        </Button>
        <br/>
        <br />
        {successMessage &&
          <CSVLink
          data={dataToWriteCrypto}
          separator={','}
          filename={`customerReportsCrypto_${month}-${year}.csv`}   //CHANGE THIS TO A DINAMIC ONE
          className='btn btn-primary mr-3'>
          Download Crypto
        </CSVLink>}
        {successMessage &&
          <CSVLink
          data={dataToWriteTokens}
          separator={','}
          filename={`customerReportsTokens_${month}-${year}.csv`}   //CHANGE THIS TO A DINAMIC ONE
          className='btn btn-primary'>
          Download Token
        </CSVLink>}
      <br></br>
      <br></br>
      </Form>
      <div className='w-100 text-center mt-2'>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}

export default CustomerReportsAppV2
