import { collection, getDocs } from 'firebase/firestore'
import React, { useRef, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import { Link } from 'react-router-dom'
import { getPairSymbolPrice } from '../auxiliary/broker'
import { clog, toTimestamp } from '../auxiliary/helpers'
import { useAuth } from '../contexts/AuthContext'
import { appDb } from '../firebase'

const FIAT_SYMBOL = 'BRL'
const STABLE_COIN_SYMBOL = 'USDT'

function BasketReports() {

  const [dataToWrite, setDataToWrite] = useState('')
  const inititalDateRef = useRef('')
  const finalDateRef = useRef('')

  const [successMessage, setSuccessMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [error, setError] = useState('')
  const [filename, setFilename] = useState('')
  const [documentReady, setDocumentReady] = useState(false)

  const { currentAdminUser } = useAuth()
  const symbolPrices = useRef({})

  const getPairPrice = async (pairSymbol, timePriceReference) => {
    if(pairSymbol + timePriceReference in symbolPrices.current) {
      return symbolPrices.current[pairSymbol + timePriceReference]
    }
    try {
      const pairPrice = await getPairSymbolPrice(currentAdminUser, pairSymbol, timePriceReference)
      const prevSymbolPrices = symbolPrices.current
      symbolPrices.current = {
        ...prevSymbolPrices,
        [pairSymbol + timePriceReference]: pairPrice
      }
      return pairPrice
    }
    catch(error) {
      try {
        clog('ERROR:')
        clog(error)
        const pairPrice = await getPairSymbolPrice(currentAdminUser, pairSymbol, timePriceReference)
        const prevSymbolPrices = symbolPrices.current
        symbolPrices.current = {
          ...prevSymbolPrices,
          [pairSymbol + timePriceReference]: pairPrice
        }
        return pairPrice
      }
      catch(error) {
        clog('ERROR 2:')
        console.error(error)
      }
    }
  }

  async function generateBasketReport() {
    try {
      setFilename('RELATORIO_RENDIMENTO_CESTAS')
      setError('')
      setSuccessMessage('')
      setWaitMessage('Wait for it...')

      if(!inititalDateRef || !finalDateRef){
        setError('Insira as datas')
        return
      }

      const inititalDate = toTimestamp(inititalDateRef.current.value)
      const finalDate = toTimestamp(finalDateRef.current.value) + 24 * 60 * 60

      const currentTimeDate = Math.round(Date.now() / 1000)
      if(!inititalDate || !finalDate || inititalDate >= currentTimeDate || finalDate >= currentTimeDate) {
        setError('Data inválida')
        setWaitMessage('')
        return
      }

      clog('inititalDate')
      clog(inititalDate)
      clog('finalDate')
      clog(finalDate)

      const pairStableFiatSymbol = STABLE_COIN_SYMBOL + FIAT_SYMBOL
      let csvData = [['HERO', 'CESTA', 'RENDIMENTO']]
      const heroesRef = collection(appDb, 'heroes')
      const heroesQuerysnap = await getDocs(heroesRef)

      let heroesList = []
        for(const heroDoc of heroesQuerysnap.docs){
          const heroData = heroDoc.data()
          heroesList.push({...heroData, heroId: heroDoc.id})
        }
        
        for(const heroData of heroesList) {
          const heroFullname = heroData.personalInfo.name + heroData.personalInfo.surname
          const heroId = heroData.heroId
          const basketsRef = collection(appDb, `/heroes/${heroId}/baskets`)
          const basketsQuerysnap = await getDocs(basketsRef)
          let basketsList = []
          for(const basket of basketsQuerysnap.docs) {
            const basketData = basket.data()
            basketsList.push({...basketData, basketId: basket.id})
          }
          for(const basket of basketsList) {
            const basketId = basket.id
            const privateBasketsRef = collection(appDb, `/heroes/${heroId}/baskets/${basketId}/privateBaskets`)
            const privateBasketsQuerysnap = await getDocs(privateBasketsRef)
            let privateBasketsList = []
            for(const privateBaskets of privateBasketsQuerysnap.docs) {
              const privateBasketsData = privateBaskets.data()
              privateBasketsList.push({...privateBasketsData, privateBasketId: privateBaskets.id})
            }
            for(const privateBaskets of privateBasketsList) {
              const coins = privateBaskets.privateContent.coins
              let privateBasketVariation = 0
              for(const coin of coins) {
                const symbol = coin.symbol
                const percentage = coin.percentage
                const pairSymbol = symbol + STABLE_COIN_SYMBOL
                const pairPriceInitial = await getPairPrice(pairSymbol, inititalDate)
                const priceStableInFiatInitial = await getPairPrice(pairStableFiatSymbol, inititalDate)
                const totalValueInFiatInitial = 1 * pairPriceInitial * priceStableInFiatInitial
                const pairPriceFinal = await getPairPrice(pairSymbol, finalDate)
                const priceStableInFiatFinal = await getPairPrice(pairStableFiatSymbol, finalDate)
                const totalValueInFiatFinal = 1 * pairPriceFinal * priceStableInFiatFinal
                const coinPriceVariation = (totalValueInFiatFinal / totalValueInFiatInitial) - 1
                const coinPriceVariationWeighted = coinPriceVariation * percentage
                privateBasketVariation += coinPriceVariationWeighted
              }
              const formattedPrivateBasketVariation = (privateBasketVariation * 100).toFixed(2) + "%"
              const newLine = [heroFullname, basketId, formattedPrivateBasketVariation]
              csvData.push(newLine)
            }
          }
        }
        setDataToWrite(csvData)
        setWaitMessage('')
        setSuccessMessage('Report ready to download')
        setDocumentReady(true)
    }
    catch(err) {
      setError('Erro ao gerar relatório')
      clog(err)
    }
  }

  return (
    <>
      <Form>
        <Form.Group id='inititalDate' style={{justifyContent: 'center'}}>
          <Form.Label>Data inicial (a partir de 00:00:00)</Form.Label>
          <Form.Control
            type='date'
            ref={inititalDateRef}
            required
            autoFocus
            style={{width: '30%'}}
          />
        </Form.Group>
        <Form.Group id='finalDate'>
          <Form.Label>Data final (até 23:59:59)</Form.Label>
          <Form.Control
            type='date'
            ref={finalDateRef}
            required
            style={{width: '30%'}}
          />
        </Form.Group>
        {waitMessage && <Alert variant='warning'>{waitMessage}</Alert>}
        {successMessage && <Alert variant='success'>{successMessage}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        <Button variant='primary' className='w-100'onClick={generateBasketReport}>
          Gerar RELATÓRIO RENDIMENTO DAS CESTAS
        </Button>
        <br></br>
        <br></br>
        {documentReady &&
          <CSVLink
          data={dataToWrite}
          separator={','}
          filename={`${filename}_${inititalDateRef.current.value}|00:00___${finalDateRef.current.value}|23:59.csv`}
          className='btn btn-primary'>
          Download
        </CSVLink>}
      </Form>
      <div className='w-100 text-center mt-2'>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}

export default BasketReports
