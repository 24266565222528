import React, { useState, useEffect } from 'react'
import { Form, Button, Card, Alert, ListGroup } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
// import { useDatabase } from '../contexts/DatabaseContext'
import { appDb, adminDb } from '../firebase'
import { doc, getDoc, updateDoc } from '../firebase'
import { setDoc, Timestamp } from 'firebase/firestore'
import { Link, useLocation } from 'react-router-dom'
// import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'
import Image from 'react-bootstrap/Image'
import QRCode from 'qrcode'


import Coin from './Coin'
import { copyToClipboard, secsToDateTime, formatPrice, sendEmail, clog, round, Pix, getOnlyNumbers } from '../auxiliary/helpers'

export default function ExchangeSell() {

  const location = useLocation()
  const { buyingPower, bnbBalance, userOrder } = location.state

  const { currentAdminUser, currentAppUser } = useAuth()

  const [orderMessage, setOrderMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [error, setError] = useState('')
  const [disabledButton, setDisabledButton] = useState(true)
  const [notice, setNotice] = useState('')
  const [paidByItau, setPaidByItau] = useState(false)
  const [finished, setFinished] = useState(false)
  
  const [pixCodeOnlyNumbers, setPixCodeOnlyNumbers] = useState(false)
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState()

  clog(currentAdminUser.email)
  clog(userOrder.totalToReceive + userOrder.fee)
  clog(userOrder.totalToReceive)
  
  useEffect(() => {
    const execute = async () => {

      const pixInfoCH = {
        pixKey: 'financeiro@cryptohero.com.br',
        description: 'vendas',
        merchantName:'CRYPTOHERO',
        merchantCity: 'SaoPaulo',
        txid: 'vendas',
        amount: round(userOrder.totalToReceive + userOrder.fee, 2)
      }

      const userPixCode = pixCodeOnlyNumbers ?
        getOnlyNumbers(userOrder.personalInfo.pixCode) :
        userOrder.personalInfo.pixCode

      const pixInfoUser = {
        pixKey: userPixCode,
        description: '',
        merchantName: '',
        merchantCity: '',
        txid: '',
        amount: round(userOrder.totalToReceive, 2)
      }

      let pixInfo = {}

      if(currentAdminUser.email === 'mauricio@cryptohero.com.br') {
        pixInfo =  pixInfoCH
      }
      else if(currentAdminUser.email === 'fernando.lopes@cryptohero.com.br') {
        pixInfo = pixInfoUser
      }

      const pix = new Pix(
        pixInfo.pixKey,
        pixInfo.description,
        pixInfo.merchantName,
        pixInfo.merchantCity,
        pixInfo.txid,
        pixInfo.amount
      )
      const pixCode = pix.getPayload()
      const url = await QRCode.toDataURL(pixCode)
      setQrCodeImageUrl(url)
    }
    execute()
  }, [pixCodeOnlyNumbers])

  // useEffect(() => {
  //   const PERMMITED_UID = 'tsM6UtAGmAcx0YHI1a2rh0BpkOB2'
  //   const uid = currentAdminUser.uid
  //   const url = new URL(window.location.href)
  //   const hostname = url.hostname
  //   if(uid === PERMMITED_UID && hostname === 'localhost') setPermitted(true)
  //   else setError('User not allowed!')
  // }, [])

  const confirmSell = async () => {
    setWaitMessage('Wait to finish')
    const updatedOrderRecorded = await recordUpdatedOrder(userOrder.orderId)
    if(!updatedOrderRecorded) setError('Ordem não foi atualizada corretamente no BD')
    const userEmail = userOrder.personalInfo.email
    const templateId = 'd-339ce012b81d4ff9817e6d28d168f909'
    const dynamicTemplateData = {
      name: userOrder.personalInfo.name,
    }
    if(paidByItau) {
      await registerBinanceDebt(userOrder.totalToReceive + userOrder.fee)
    }
    const emailSent = await sendEmail(currentAppUser, userEmail, templateId, dynamicTemplateData)
    setWaitMessage('')
    if(!emailSent) setError('Email de "Venda concluída" não foi enviado para o cliente. Faça manualmente')
    setFinished(true)
    setOrderMessage('Success! -- Click "Back to Orders List" link to return to list')
  }

  const recordUpdatedOrder = async (docId) => {
    try {
      const orderRef = doc(appDb, 'orders', docId)
      const response = await updateDoc(orderRef, {
        status: 'FULFILLED',
        timeUpdate: Timestamp.now()
      })
      clog('*** response')
      clog(JSON.stringify(response, null, 2))
      return true
    }
    catch(error) {
      // If there is an error or a partial fulfillment
      setError(error)
      return false
    }
  }

  const registerBinanceDebt = async (debtToSum) => {
    clog(`debtToSum = ${debtToSum}`)
    clog(typeof debtToSum)
    const adminItauDebtRef = doc(adminDb, 'adminDebts/toItau')
    const adminItauDebtSnap = await getDoc(adminItauDebtRef)
    let toItauDebt = 0
    if(adminItauDebtSnap.exists()) {
      toItauDebt = adminItauDebtSnap.data().value
      await updateDoc(adminItauDebtRef, {
        value: toItauDebt + debtToSum
      })
    }
    else {
      await setDoc(adminItauDebtRef, {
        value: toItauDebt + debtToSum
      })
    }
  }

  const copyInfo = (info, value) => {
    copyToClipboard(value)
    setNotice(`${info} copied`)
  }

  const copyAllInfo = () => {
    const info = 'SELL INFO'
    let value = 'VENDA\n'
    value += 'Time: ' + secsToDateTime(parseFloat(userOrder.timeCreation.seconds)) + '\n'
    value += 'Order: ' + userOrder.orderId + '\n'
    value += 'User name: ' + userOrder.personalInfo.name + '\n'
    value += 'PIX:' + '\n'
    value += userOrder.personalInfo.pixCode + '\n'
    value += 'Valor para CLIENTE:' + '\n'
    value += formatPrice(parseFloat(userOrder.totalToReceive), userOrder.fiatSymbol) + '\n'
    value += 'Taxa:' + '\n'
    value += formatPrice(parseFloat(userOrder.fee), userOrder.fiatSymbol) + '\n'
    value += 'Total:' + '\n'
    value += formatPrice(parseFloat(userOrder.totalToReceive) + parseFloat(userOrder.fee), userOrder.fiatSymbol) + '\n'
    value += '--------------------------------------------------------------------------' + '\n'
    copyInfo(info, value)
  }

  const handleValueChange = (event) => {
    const target = event.target
    if(target.type === 'checkbox' && target.id === 'pixSentCheckbox') {
      setDisabledButton(!target.checked)
      return
    }
    if(target.type === 'checkbox' && target.id === 'paidByItauCheckbox') {
      setPaidByItau(target.checked)
      return
    }
    if(target.type === 'checkbox' && target.id === 'isPixCodeOnlyNumbers') {
      setPixCodeOnlyNumbers(target.checked)
      return
    }
  }

  const coins = userOrder.coins.map(item => {
    return(<Coin
        key={item.symbol}
        symbol={item.symbol}
        percentage={item.percentage}
        />
    )
  })

  const globalStatusStyle = {
    color: userOrder.status === 'ERROR' ? 'red' :
    (userOrder.status === 'FULFILLED' ? 'green' :
    (userOrder.status === 'PARTIAL' ? 'orange' : 'default'))
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className='text-center mb-4'>Exchange</h2>
          <br></br>
          <br></br>
          <Card style={{ width: '40rem' }}>
            <p>Account Info:</p>
            <ListGroup variant='flush'>
            <ListGroup.Item>Buying Power: {buyingPower}</ListGroup.Item>
            <ListGroup.Item>BNB balance: {bnbBalance}</ListGroup.Item>
            </ListGroup>
            <p>User Order Info:</p>
            <ListGroup variant='flush'>
              <ListGroup.Item>Time: {secsToDateTime(parseFloat(userOrder.timeCreation.seconds))}</ListGroup.Item>
              <ListGroup.Item style={globalStatusStyle}>Status: {userOrder.status}</ListGroup.Item>
              <ListGroup.Item>Order: {userOrder.orderId}</ListGroup.Item>
              <ListGroup.Item>User ID: {userOrder.userId}</ListGroup.Item>
              <ListGroup.Item>User name: {userOrder.personalInfo.name}</ListGroup.Item>
              <ListGroup.Item>User Email: {userOrder.personalInfo.email}</ListGroup.Item>
              <Card onClick={() => copyInfo('PIX', userOrder.personalInfo.pixCode)} style={{cursor: 'pointer'}}>
              {notice && <Alert variant='primary'>{notice}</Alert>}
              <ListGroup.Item>User Pix: {userOrder.personalInfo.pixCode}</ListGroup.Item>
              </Card>
              <Card onClick={() => copyInfo('Value', userOrder.totalToReceive)} style={{cursor: 'pointer'}}>
              <ListGroup.Item>Pay to the client: {formatPrice(parseFloat(userOrder.totalToReceive), userOrder.fiatSymbol)}</ListGroup.Item>
              </Card>
            </ListGroup>
            <p>Coins List:</p>
            {coins}
          </Card>
          <br></br>
          <Image style={{width: '30vw', borderRadius: '10px', padding: 0, margin: 0,}} src={qrCodeImageUrl} alt='QR Code'/>
          <Form>
            <Form.Check
              type={'checkbox'}
              id={`isPixCodeOnlyNumbers`}
              label={`PIX: ${userOrder.personalInfo.pixCode} é CPF ou TELEFONE ?`}
              onChange={handleValueChange}
              style={{cursor: 'pointer'}}
            />
            {/* <Form.Check
              type={'checkbox'}
              id={`paidByItauCheckbox`}
              label={`Valor descontado do Itaú`}
              onChange={handleValueChange}
              style={{cursor: 'pointer'}}
            /> */}
            <br></br>
            <Form.Check
              type={'checkbox'}
              id={`pixSentCheckbox`}
              label={`PIX of ${
                formatPrice(parseFloat(userOrder.totalToReceive), userOrder.fiatSymbol)
              } sent to client`}
              onChange={handleValueChange}
              style={{cursor: 'pointer'}}
            />
            <br></br>
            {notice && <Alert variant='primary'>{notice}</Alert>}
            <Button onClick={copyAllInfo} disabled={false}>
              COPY ALL INFO
            </Button>
            <br></br>
            <br></br>
            {error && <Alert variant='danger'>{error}</Alert>}
            {orderMessage && <Alert variant='success'>{orderMessage}</Alert>}
            {waitMessage && <Alert variant='primary'>{waitMessage}</Alert>}
            <Button variant='success' disabled={disabledButton || finished} className='w-100' onClick={confirmSell}>
              Confirm
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Link to='/users-sell-orders'>Back to Orders List</Link>
        <br></br>
        <br></br>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}