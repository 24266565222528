import React from 'react'
import { ListGroup } from 'react-bootstrap'


export default function Coin(props) {

  const statusStyle = {
    color: props.status === 'ERROR' ? 'red' :
    (props.status === 'FULFILLED' ? 'green' :
    (props.status === 'PARTIAL' ? 'orange' : 'default'))
  }

  return (
    <>
    <ListGroup variant='flush'>
      <ListGroup.Item style={statusStyle}>{props.symbol} | {props.percentage*100}%</ListGroup.Item>
    </ListGroup>
    </>
  )
}