import { fetchCloudFunction } from "./helpers"

export const getPairSymbolPrice = async (currentUser, pairSymbol, timeUnixSec) => {
  try{
    const queryData = {
      pairSymbol: pairSymbol,
      timeUnixSec: timeUnixSec,
    }
    const response = await fetchCloudFunction(currentUser, 'admin', false, 'getPairSymbolPrice', queryData)
    return response.price
  }
  catch(error) {
    console.log('error getPairSymbolPrice')
    console.error(error)
    throw new Error(error)
  }
}