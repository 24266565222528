import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link } from 'react-router-dom';
import { clog } from '../auxiliary/helpers';
import { appV2Db } from '../firebase';


const FIAT_SYMBOL = 'BRL'
const STABLE_COIN_SYMBOL = 'USDT'

function SetUserBasket() {

  const [users, setUsers] = React.useState([])
  const [baskets, setBaskets] = React.useState([])
  const [quantity, setQuantity] = useState(0)
  const [basketValue, setBasketValue] = React.useState(0)
  const [basketLink, setBasketLink] = React.useState('')

  const [selectedUser, setSelectedUser] = React.useState('')
  const [selectedBasket, setSelectedBasket] = React.useState({name: '', id: '', assets: []})


  const handleUserSelection = (eventKey) => {
    setSelectedUser(eventKey)
    clog(selectedUser)
  }

  const handleBasketSelection = (eventKey) => {
    const basket = baskets.find(basket => basket.name === eventKey)
    setSelectedBasket(basket)
    clog(selectedBasket)
    
  }

  const handleBasketValueChange = (event) => {
    setBasketValue(event.target.value);
  };

  const handleLinkCreation = async () => {
    const value = basketValue
    const advisorBasketId = selectedBasket.id
    const assets = selectedBasket.assets
    const link = `https://app.cryptohero.com.br/heroinvest?screen=basket-pre-trade&id=${advisorBasketId}&value=${value}`
    setBasketLink(link)

    const userId = users.find(user => user.email === selectedUser).id
    const assetsFormated = assets.map(asset => {
      return {symbol: asset.symbol, percentage: asset.percentage, assetType: asset.assetType}
    })
    
    const docRef = doc(appV2Db, `users/${userId}/editableInformation/editableInformation`);
    try {
      await updateDoc(docRef, {
        selectedCoins: assetsFormated,
        totalOrderValue: parseFloat(value),
      }) 
    } catch (error) {
      clog(error)
      alert(`Erro ao atualizar as moedas selecionadas do usuário ${selectedUser}: ${error}. Por favor, tente criar o link novamente.`)
    }

  }

  const loadUsersFromFirestore = async () => {
    const querySnapshot = await getDocs(collection(appV2Db, "users"));
    const usersData = [];
    querySnapshot.forEach((doc) => {
      /* usersData.push(doc.data()); */
      usersData.push({email: doc.data().email, id: doc.id})
    });
    
    if(usersData.length > 0) {
      const orderedUsersData = usersData.sort((a, b) => {
        if(a.email > b.email) return 1
        if(b.email > a.email) return -1
        return 0
      })
      clog(orderedUsersData)
      setUsers(orderedUsersData);
    }

  };

  const loadBasketsFromFirestore = async () => {
    const querySnapshot = await getDocs(collection(appV2Db, "advisorsBaskets"));
    const basketsData = [];
    querySnapshot.forEach((doc) => {
      /* basketsData.push(doc.data()); */
      basketsData.push({id: doc.id, name: doc.data().name, assets: doc.data().assets})
    });

    if(basketsData.length > 0) {
      const orderedBasketsData = basketsData.sort((a, b) => {
        if(a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if(b.name.toLowerCase() > a.name.toLowerCase()) return -1
        return 0
      })
      clog(orderedBasketsData)
      setBaskets(orderedBasketsData);
    }

  };

  // Chame a função para carregar os dados das moedas ao montar o componente
  React.useEffect(() => {
    loadUsersFromFirestore();
    loadBasketsFromFirestore();
  }, []);

  

  return (
    <>
      
      <h1 className="h3 mb-4 text-gray-800">Conectar cesta a usuário</h1>
      <DropdownButton className="mt-2" onSelect={handleUserSelection} id="dropdown-basic-button" title="Clique para escolher o usuário">
        {users.map((user, index) => (
          <Dropdown.Item eventKey={user.email} key={index} href={`#/user-${index}`}>{user.email}</Dropdown.Item>
        ))}
    </DropdownButton>

    <DropdownButton className="mt-2" onSelect={handleBasketSelection} id="dropdown-basic-button" title="Clique para escolher a cesta">
        {baskets.map((basket, index) => (
          <Dropdown.Item eventKey={basket.name} key={index} href={`#/basket-${index}`}>{basket.name}</Dropdown.Item>
        ))}
    </DropdownButton>
    
    {selectedUser && (<div>
      <p className="text-gray-800 mt-3">Usuário escolhido: {selectedUser}</p>
    </div>)}
    {selectedBasket && (<div>
      <p className="text-gray-800 mt-3">Cesta escolhida: {selectedBasket.name}</p>
    </div>)}

    <div>
        <label htmlFor="basketValue" className="mr-2 mt-4">Valor da cesta:</label>
        <input
          type="number"
          id="basketValue"
          value={basketValue}
          onChange={handleBasketValueChange}
        />
      </div>

    <Button variant='primary' className='w-100 mt-3' onClick={handleLinkCreation}>
      Gerar link para a cesta
    </Button>
    {basketLink && (
      <div>
        <p className="text-gray-800 mt-3">Link: {basketLink}</p>
        <Button variant='primary' className='w-100 mt-3' onClick={() => navigator.clipboard.writeText(basketLink)}>Copiar o link</Button>
      </div>
    )}
  
    <div className="w-100 text-center mt-2">
        <Link to="/dashboardappv2">Voltar</Link>
      </div>

    
    </>
  )
}

export default SetUserBasket
