import { addDoc, collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useState } from 'react';
import { Alert, Button, Image } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link } from 'react-router-dom';
import { clog, formatString, round } from '../auxiliary/helpers';
import mockData from '../data/mockDataCreateBasket.json';
import { appV2Db, appV2Storage } from '../firebase';


const FIAT_SYMBOL = 'BRL'
const STABLE_COIN_SYMBOL = 'USDT'

function BasketCreationTokens() {

  /* . */


  const [coins, setCoins] = React.useState(mockData.coins)
  const [quantity, setQuantity] = useState(0)
  const [newBasketName, setNewBasketName] = React.useState('')
  const [newBasketImage, setNewBasketImage] = React.useState(null)

  const [basketSucessfullyCreated, setBasketSucessfullyCreated] = React.useState(false)
  const [basketErrorCreation, setBasketErrorCreation] = React.useState(false)

  const [coinSucessfullyCreated, setCoinSucessfullyCreated] = React.useState(false)
  const [coinErrorCreation, setCoinErrorCreation] = React.useState(false)

  const [newCoinName, setNewCoinName] = React.useState('')
  const [newCoinSymbol, setNewCoinSymbol] = React.useState('')
  const [newCoinImage, setNewCoinImage] = React.useState(null)
  const [newCoinMainColor, setNewCoinMainColor] = React.useState('')
  const [newTokenUnitValue, setNewTokenUnitValue] = React.useState(1)
  const [newTokenDueDate, setNewTokenDueDate] = React.useState()
  const [newTokenSellMinimumDate, setNewTokenSellMinimumDate] = React.useState()
  const [newTokenMinimumInitialInvestment, setNewTokenMinimumInitialInvestment] = React.useState()
  const [fiatPrice, setFiatPrice] = React.useState(1)
  const [newTokenMinimumInvestment, setNewTokenMinimumInvestment] = React.useState()
  

  const [newCoin, setNewCoin] = React.useState({
    symbol: '',
    name: '',
    mainColor: '',
    dueDate: 0,
    type: "token",
    imageUrl: '',
    currentUnitValue: 0,
    sellMinimumDate: 0,
    darkerColor: '',
  })

  const [lastSelectedCoin, setLastSelectedCoin] = React.useState({
    name: '',
    symbol: '',
    coinImageUrl: '',
  });

  const [newBasket, setNewBasket] = React.useState({
    basketName: '',
    basketImgUrl: '',
    coins: [{
    percentage: 0,
    name: '',
    symbol: '',
    coinImageUrl: '',
}]})

  clog(coins)

  function darkenColor(hexColor, percent) {
    // Ensure the percent is within the valid range [0, 100]
    percent = Math.min(100, Math.max(0, percent));

    // Parse the hexadecimal color
    let r = parseInt(hexColor.slice(1, 3), 16);
    let g = parseInt(hexColor.slice(3, 5), 16);
    let b = parseInt(hexColor.slice(5, 7), 16);

    // Calculate the darkness amount
    let darkenAmount = percent / 100;

    // Darken the color components
    r = Math.round(r * (1 - darkenAmount));
    g = Math.round(g * (1 - darkenAmount));
    b = Math.round(b * (1 - darkenAmount));

    // Convert the result back to hexadecimal
    const resultHex = `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;

    return resultHex;
}

async function checkImageAvailability(url) {
  // Format the URL
  const formattedUrl = new URL(url);

  try {
    // Fetch the image
    const response = await fetch(formattedUrl);

    // Check if the status code indicates success
    if (response.ok) {
      console.log("Image is available!");
    } else {
      console.log(`Image not available. Status Code: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching the image:", error);
  }
}

// Example usage: Darken the color #3498db by 20%
const darkenedColor = darkenColor("#3498db", 20);
console.log(darkenedColor); // Output: #20638f


  const handleSelection = async (eventKey) => {
    const selectedCoin = coins.find(coin => coin.name === eventKey)
    clog(selectedCoin)
    /* setLastSelectedCoin(selectedCoin) */
    setLastSelectedCoin((prev) => ({name: selectedCoin.name, symbol: selectedCoin.symbol, coinImageUrl: getCoinImageUrl(selectedCoin.symbol)}))
    clog(lastSelectedCoin)
  }

  const addNewCoin = () => {
    

    if(newBasket.coins.length !== 0 && newBasket.coins[0].name === '') {

      setNewBasket((prevBasket) => ({
        ...prevBasket,  // Keep the existing basketName and basketImgUrl
        coins: [
          {
            assetType: 'token',
            percentage: round(quantity/100, 2),
            name: lastSelectedCoin.name,
            symbol: (lastSelectedCoin.symbol).toUpperCase(),
          },
        ],
      }));
      return
    }
    else {

      setNewBasket((prevBasket) => ({
        ...prevBasket,  // Keep the existing basketName and basketImgUrl
        coins: [
          ...prevBasket.coins,  // Keep the existing coins
          {
            assetType: 'token',
            percentage: round(quantity/100, 2),
            name: lastSelectedCoin.name,
            symbol: lastSelectedCoin.symbol,
          },
        ],
      }));
      
    }
    clog("CESTA")
    clog(newBasket)
  }

  const createNewBasket = async () => {
    setBasketSucessfullyCreated(false)
    setBasketErrorCreation(false)

    let totalSum = 0
    newBasket.coins.forEach(coin => {
      totalSum += coin.percentage
    })
    if(round(totalSum, 2) !== 1) {
      alert(`Erro: A soma das porcentagens deve ser igual a 100, atualmente é: ${totalSum}. Altere e tente novamente.`)
      return
    }

    const storageRef = ref(appV2Storage);
    /* const basketImageRef = ref(appV2Storage, `baskets_images/${newBasketImage.name}`); */
    /* const imageRef = storageRef.child(`basket_images/${newBasketName}.jpg`); */

    /* uploadBytes(basketImageRef, newBasketImage).then((snapshot) => {
      console.log('Imagem da cesta enviada com sucesso!');

      
      getDownloadURL(basketImageRef).then((downloadUrl) => {
        console.log("Download Url: ", downloadUrl)
        setNewBasket((prev) => ({basketImgUrl: downloadUrl, basketName: newBasketName, coins: prev.coins}))
        
        const newBasketToUpload = {name: newBasketName, assets: newBasket.coins}
        clog("CESTA CRIADA:")
        clog(newBasketToUpload)
        
        addDoc(collection(appV2Db, "advisorsBaskets"), newBasketToUpload).then((docRef) => {
          console.log('Document written with ID: ', docRef.id);
          setBasketSucessfullyCreated(true);
        }).catch((error) => {
          setBasketErrorCreation(true);
          console.error('Error creating Firestore document:', error);
        })
        

      }).catch((error) => {
        setBasketErrorCreation(true)
        clog(basketErrorCreation)
        console.error('Erro ao baixar imagem da cesta', error)
      })

    }).catch((error) => {
      setBasketErrorCreation(true)
      clog(basketErrorCreation)
      console.error('Erro ao subir imagem da cesta', error)
    })
 */

    
    //outra maneira de fazer isso seria puxar tds as cestas e ai iterar por elas comparando newBasketName.toLowerCase() === doc.data().name.toLowerCase() e se for igual, dar erro, se não, criar a cesta
    //talvez eu mude dps para isso /\, pensei dps q fiz
    const collectionRef = collection(appV2Db, "advisorsBaskets")
    const q1 = query(collectionRef, where("name", "==", newBasketName));
    const q2 = query(collectionRef, where("name", "==", newBasketName.toUpperCase()));
    const capitalizedName = newBasketName.charAt(0).toUpperCase() + newBasketName.slice(1)
    const q3 = query(collectionRef, where("name", "==", capitalizedName));
    const q4 = query(collectionRef, where("name", "==", newBasketName.toLowerCase()));
    const q5 = query(collectionRef, where("name", "==", formatString(newBasketName)));
    const querySnapshot1 = await getDocs(q1);
    const querySnapshot2 = await getDocs(q2);
    const querySnapshot3 = await getDocs(q3);
    const querySnapshot4 = await getDocs(q4);
    const querySnapshot5 = await getDocs(q5);
    if(!querySnapshot1.empty || !querySnapshot2.empty || !querySnapshot3.empty || !querySnapshot4.empty || !querySnapshot5.empty) {
      alert("Uma cesta com esse nome já existe no banco de dados, tente outro nome.")
      return;
    }
  

    setNewBasket((prev) => ({/* basketImgUrl: downloadUrl,  */basketName: formatString(newBasketName), coins: prev.coins}))
        
    const newBasketToUpload = {name: formatString(newBasketName), assets: newBasket.coins}
    clog("CESTA CRIADA:")
    clog(newBasketToUpload)
        
    addDoc(collection(appV2Db, "advisorsBaskets"), newBasketToUpload).then((docRef) => {
      console.log('Document written with ID: ', docRef.id);
      setBasketSucessfullyCreated(true);
    }).catch((error) => {
      setBasketErrorCreation(true);
      console.error('Error creating Firestore document:', error);
    })

    //clean the inputs, comment the block of code below if you dont want this.
    setNewBasketName('')
    /* setNewBasketImage(null) */
    /* const imageInput = document.getElementById('basketImage');
    if (imageInput) {
      imageInput.value = '';
    } */
    setNewBasket({basketName: '', /* basketImgUrl: '',  */coins: [{percentage: 0, name: '', symbol: '', coinImageUrl: ''}]})
    setLastSelectedCoin({name: '', symbol: '', coinImageUrl: ''})
  }

  const removeCoin = (coinToRemove) => {
    if(newBasket.coins.lenght === 0){
      clog("CESTA VAZIA")
      return
    }
    clog("feito ")
    const newFilteredBasket = {/* basketImgUrl: newBasket.basketImgUrl,  */basketName: newBasket.basketName, coins: newBasket.coins.filter(coin => coin.symbol !== coinToRemove.symbol)}
    /* const newFilteredBasket = newBasket.coins.filter(coin => coin.symbol !== coinToRemove.symbol) */
    clog(newFilteredBasket)
    setNewBasket(newFilteredBasket)
  }

  const handleNewCoinNameChange = (event) => {
    setNewCoinName(event.target.value);
  };

  const handleNewCoinSymbolChange = (event) => {
    setNewCoinSymbol(event.target.value);
  };

  const handleNewTokenUnitValueChange = (event) => {
    setNewTokenUnitValue(event.target.value);
  };

  const handleNewTokenMainColorChange= (event) => {
    setNewCoinMainColor(event.target.value);
  };

  const handleNewTokenDueDateChange= (event) => {
    setNewTokenDueDate(event.target.value);
  };

  const handleNewTokenSellMinimumDateChange= (event) => {
    setNewTokenSellMinimumDate(event.target.value);
  };

  const handleNewTokenMinimumInitialInvestmentChange= (event) => {
    setNewTokenMinimumInitialInvestment(event.target.value);
  };

  const handleNewTokenMinimumInvestmentChange= (event) => {
    setNewTokenMinimumInvestment(event.target.value);
  };

  const handleNewCoinImageChange = (event) => {
    setNewCoinImage(event.target.files[0]);
  };

  const handleSubmitNewToken = async (event) => {
    event.preventDefault();

    setCoinSucessfullyCreated(false)
    setCoinErrorCreation(false)

    const storageRef = ref(appV2Storage);
    const coinImageRef = ref(appV2Storage, `assets_images/${newCoinImage.name}`);

    const symbolToSearch = newCoinSymbol.toUpperCase();

    const tokensRef = collection(appV2Db, "tokens");
    const q = query(tokensRef, where("symbol", "==", symbolToSearch))
    const querySnapshot = await getDocs(q);
    if(!querySnapshot.empty) {
      alert("Esse token já existe no banco de dados: ", querySnapshot.docs[0].id)
      setCoinErrorCreation(true)
      return;
    } else {
      if(newCoinImage.name !== `${newCoinSymbol.toLowerCase()}-logo.png`) {
        alert(`Erro: A imagem do token deve ter o nome no formato: "symbol-logo", por ex: "at1-logo"`)
        console.error(`Erro: A imagem do token deve ter o nome no formato: "symbol-logo", por ex: "at1-logo"`)
        setCoinErrorCreation(true)
        return
      }
        uploadBytes(coinImageRef, newCoinImage).then((snapshot) => {
          console.log('Imagem do token enviado com sucesso!');
      
          getDownloadURL(coinImageRef).then( async (downloadUrl) => {
            console.log("Token Image Download Url: ", downloadUrl)

            const capitalizedName = newCoinName.charAt(0).toUpperCase() + newCoinName.slice(1)
            const newCoinToAdd = {name: capitalizedName, symbol: newCoinSymbol.toUpperCase(), mainColor: newCoinMainColor, dueDate: parseInt(newTokenDueDate), type: "token", imageUrl: downloadUrl, currentUnitValue: parseFloat(newTokenUnitValue), sellMinimumDate: parseInt(newTokenSellMinimumDate), darkerColor: darkenColor(newCoinMainColor, 20), minimumInvestment: parseFloat(newTokenMinimumInvestment), minimumInitialInvestment: parseFloat(newTokenMinimumInitialInvestment), fiatPrice: parseFloat(fiatPrice)}
            setNewCoin(newCoinToAdd)
            //create document in firestore
              
              
            setDoc(doc(appV2Db, `tokens/${newCoinSymbol.toUpperCase()}`), newCoinToAdd).then(() => {
              setCoinSucessfullyCreated(true)
              clog(`Document token written`)
            }).catch((error) => {
              setCoinErrorCreation(true)
              console.error("Error creating coin document: ", error)
            })
      
      /* 
            clog("Moeda criada com sucesso:")
            clog(newCoin) */
          }).catch((error) => {
            setCoinErrorCreation(true)
            console.error('Erro ao baixar imagem do token', error)
          })
      
        }).catch((error) => {
          setCoinErrorCreation(true)
          console.error('Erro ao subir imagem do token', error)
        })
      }
     


    console.log("Coin Name:", newCoinName);
    console.log("Coin Symbol:", newCoinSymbol);

    setNewCoinName("");
    setNewCoinSymbol("");
    setNewCoinImage(null);
  }

  const handleNewBasketNameChange = (event) => {
    setNewBasketName(event.target.value);
  };

  const handleNewBasketImageChange = (event) => {
    setNewBasketImage(event.target.files[0]);
    clog(newBasketImage)
  };

  const loadTokensFromFirestore = async () => {
    const querySnapshot = await getDocs(collection(appV2Db, "tokens"));
    const tokensData = [];
    querySnapshot.forEach((doc) => {
      /* tokensData.push(doc.data()); */
      if(doc.data().status !== 'EXCLUDED' && doc.data().status !== 'EXPIRED'){
        tokensData.push({name: doc.data().name, symbol: doc.data().symbol, coinImageUrl: ''})
      }
    });
    clog(tokensData)
    setCoins(tokensData);
  };

  // Chame a função para carregar os dados das moedas ao montar o componente
  React.useEffect(() => {
    loadTokensFromFirestore();
  }, []);

  /* const getCoinImageUrl = (symbol) => {
    getDownloadURL(ref(appV2Storage, `assets_images/${symbol.toLowerCase()}-logo.png`)).then((downloadUrl) => {
      console.log("Coin Image Download Url: ", downloadUrl)
      return downloadUrl
    }).catch((error) => {
      console.error(`Erro ao baixar imagem da moeda ${symbol}`, error)
    })
  } */

  const getCoinImageUrl = (symbol) => {
    const url = `https://firebasestorage.googleapis.com/v0/b/ch-app-v2-dev.appspot.com/o/assets_images%2F${symbol.toLowerCase()}-logo.png?alt=media&token=195f4836-b73f-4e11-bfc2-8922f9850af7&_gl=1*13r85sx*_ga*MTIzMjg0MDYxNC4xNjk3NzE5NzUx*_ga_CW55HF8NVT*MTY5NzcyMjE1MS4yLjEuMTY5NzcyMzQ2Ni4zNS4wLjA.`
    return url;
  }
  
  

  return (
    <>
      
      <h1 className="h3 mb-4 text-gray-800">Criar cesta</h1>
      <p style={{color: 'red'}}>ATENÇÃO! A imagem da cesta deve ter o nome no formato: "nomedacesta-logo", por ex: "investimentoseguro-logo"</p>
      <div>
        <label htmlFor="basketName" className="mr-2">Nome da cesta:</label>
        <input
          type="text"
          id="basketName"
          /* placeholder='Bitcoin' */
          value={newBasketName}
          onChange={handleNewBasketNameChange}
          /* required */
        />
      </div>
      {/* <div>
          <label htmlFor="basketImage" className="mr-2 mb-2">Imagem da cesta:</label>
          <input
            type="file"
            id="basketImage"
            onChange={handleNewBasketImageChange}
            accept="image/*"
          />
        </div> */}
      
      <DropdownButton className="mt-2" onSelect={handleSelection} id="dropdown-basic-button" title="Clique para escolher o token">
        {coins.map((coin, index) => (
          <Dropdown.Item eventKey={coin.name} key={index} href={`#/coin-${index}`}>{coin.name}</Dropdown.Item>
        ))}
    </DropdownButton>

    {lastSelectedCoin.name && (
    <div style={{marginTop: '5%', display: 'flex', flexDirection: 'row', gap: 20}}>
      <Image style={{height: 40, width: 40}} src={lastSelectedCoin.coinImageUrl} roundedCircle />
      <p className="text-gray-800">{lastSelectedCoin.symbol}</p>
      <input
            type="number"
            placeholder="Quantidade"
            value={quantity}
            min={0}
            max={100}
            onChange={(e) => { 
              let value = parseFloat(e.target.value);
              if (value < 0) {
                value = 0;
              } 
              else if (value > 100) {
                value = 100;
              }
              setQuantity(value)}
            }
          />
      <Button variant='primary' className='w-100' onClick={() => addNewCoin()}>
          Adicionar token
      </Button>
    </div>
    )}

    {newBasket.coins.length !== 0 && newBasket.coins[0].name !== '' && (
      <div>
        <h1 className="h4 mt-4 text-gray-600">Cesta atual:</h1>
        {newBasket.coins.map((coin, index) => (
          <div style={{marginTop: '5%', display: 'flex', flexDirection: 'row', gap: 20, justifyContent: 'center', alignItems: 'center'}} key={index}>
            <Image style={{height: 40, width: 40}} src={getCoinImageUrl(coin.symbol)} roundedCircle />
            <p className="text-gray-800">{coin.symbol}</p>
            <p className="text-gray-800">{coin.percentage * 100}%</p>
            <Button variant='danger' className='w-100' onClick={() => removeCoin(coin)}>Remover da cesta</Button>
          </div>
        ))}
      </div>
    )}
    {basketSucessfullyCreated && (
      <Alert variant='success' className='mt-1'>Cesta criada com sucesso!</Alert>
    )}
    {basketErrorCreation && (
      <Alert variant='danger' className='mt-1'>Erro ao criar cesta! Cheque o console.</Alert>
    )}
    <Button variant='primary' className='w-100 mt-3' onClick={createNewBasket}>
      Criar cesta
    </Button>
    

    <h1 className="h3 mb-4 mt-4 text-gray-800">Criar novo token</h1>
    <form onSubmit={handleSubmitNewToken}>
      <p style={{color: 'red'}}>ATENÇÃO! A imagem do token deve ter o nome no formato: "symbol-logo", por ex: "at1-logo"</p>
    <div>
      <label htmlFor="name" className="mr-2">Nome do token:</label>
      <input
        type="text"
        id="name"
        placeholder='-'
        value={newCoinName}
        onChange={handleNewCoinNameChange}
        required
      />
       <div>
          <label htmlFor="symbol" className="mr-2">Simbolo do token:</label>
          <input
            type="text"
            id="symbol"
            placeholder='-'
            value={newCoinSymbol}
            onChange={handleNewCoinSymbolChange}
            required
          />
        </div>
        <div>
          <label htmlFor="mainColor" className="mr-2">Cor da barra (em hexadecimal)</label>
          <input
            type="text"
            id="mainColor"
            placeholder='#ffffff'
            value={newCoinMainColor}
            onChange={handleNewTokenMainColorChange}
            required
          />
        </div>
        <div>
          <label htmlFor="currentunitvalue" className="mr-2">Valor unitário do token</label>
          <input
            type="number"
            id="currentunitvalue"
            placeholder='1'
            value={newTokenUnitValue}
            onChange={handleNewTokenUnitValueChange}
            required
          />
        </div>
        <div>
          <label onClick={() => window.open("https://www.epochconverter.com/")} style={{color: 'blue'}} htmlFor="duedate" className="mr-2">Data de vencimento do token em segundos (clique aqui):</label>
          <input
            type="number"
            id="duedate"
            placeholder='1699968978'
            value={newTokenDueDate}
            onChange={handleNewTokenDueDateChange}
            required
          />
        </div>
        <div>
          <label htmlFor="sellminimumdate" className="mr-2">Data mínima para venda do token (em segundos):</label>
          <input
            type="number"
            id="sellminimumdate"
            placeholder='1789979948'
            value={newTokenSellMinimumDate}
            onChange={handleNewTokenSellMinimumDateChange}
            required
          />
        </div>
        <div>
          <label htmlFor="minimumInitialInvestment" className="mr-2">Valor mínimo inicial de investimento no token:</label>
          <input
            type="number"
            id="minimumInitialInvestment"
            placeholder='1000'
            value={newTokenMinimumInitialInvestment}
            onChange={handleNewTokenMinimumInitialInvestmentChange}
            required
          />
        </div>
        <div>
          <label htmlFor="minimumInvestment" className="mr-2">Valor mínimo aporte posterior no token:</label>
          <input
            type="number"
            id="minimumInvestment"
            placeholder='500'
            value={newTokenMinimumInvestment}
            onChange={handleNewTokenMinimumInvestmentChange}
            required
          />
        </div>
        <div>
          <label htmlFor="minimumInvestment" className="mr-2">Valor fiat (IGNORAR):</label>
          <input
            disabled
            type="number"
            id="fiatPrice"
            placeholder='1'
            value={1}
            onChange={() => {}}
            required
          />
        </div>
        <div>
          <label htmlFor="coinImage" className="mr-2">Imagem da moeda:</label>
          <input
            type="file"
            id="coinImage"
            onChange={handleNewCoinImageChange}
            accept="image/*"
            required
          />
        </div>
        {coinSucessfullyCreated && (
          <Alert variant='success' className='mt-1'>Moeda criada com sucesso!</Alert>
        )}
        {coinErrorCreation && (
          <Alert variant='danger' className='mt-1'>Erro ao criar moeda! Cheque o console.</Alert>
        )}
        <Button type='submit' variant='primary' className='w-100 mt-4'>Criar token</Button>
    </div>
    </form>
    <div className="w-100 text-center mt-2">
        <Link to="/dashboardappv2">Voltar</Link>
      </div>

    {/* {newCoin.name !== '' && <div>
      <p>Nome da Ultima moeda: {newCoin.newCoinName}</p>
      <p>Symbol da ultima moeda: {newCoin.newCoinSymbol}</p>
      <p>Url da ultima moeda: {newCoin.newCoinImageUrl}</p>
      <Image style={{height: 40, width: 40}} src={newCoin.newCoinImageUrl} roundedCircle />
    </div>} */}
    
    </>
  )
}

export default BasketCreationTokens
