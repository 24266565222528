import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where, addDoc, Timestamp } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from "react"
import { Alert, Button, Card } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { httpRequest, clog, darkenHexColor, formatCpf, formatPhone, getPrice } from "../auxiliary/helpers"
import { useAuth } from "../contexts/AuthContext"

import allCoinsData from '../data/allCoins.json'

import { appDb, appV2Db } from '../firebase'

import { getPairSymbolPrice } from '../auxiliary/broker'
import ordersData from '../data/allOrders.json'
import { jstr } from '@mauchero/helpers'

export default function Dashboard() {

  const [error, setError] = useState("")
  const [dataToWrite, setDataToWrite] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [permitted, setPermitted] = useState(false)
  const [onlyLocalhost, setOnlyLocalhost] = useState(false)

  const { currentAdminUser, logout } = useAuth()
  const history = useHistory()

  const { currentAppUser } = useAuth()

  useEffect(() => {
    const PERMMITED_UIDS = ['tsM6UtAGmAcx0YHI1a2rh0BpkOB2', '5QdnIVHQ9YMQZYsHUsiLrXigCOV2']
    const uid = currentAdminUser.uid
    const url = new URL(window.location.href)
    const hostname = url.hostname
    if(PERMMITED_UIDS.includes(uid)) {
      setPermitted(true)
    }
    if(hostname === 'localhost') {
      setOnlyLocalhost(true)
    }
  }, [])

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  const sendEmailsTelegramTemp = async () => {
    return
    // const templateId = 'd-b150f2af0ded47fdb7678fdda944ac83'
    // const dynamicTemplateData = {}
    // emailsList.forEach(async email => {
    //   const sent = await sendEmail(currentAppUser, email, templateId, dynamicTemplateData)
    //   clog(email + ' -> ' + sent)
    // })
  }

  const restoreOrders = () => {
    clog(`TAM: ${ordersData.length}`)
    ordersData.forEach(async order => {
      clog(order.orderId)
      // clog(JSON.stringify(order, null, 2))
      await setDoc(doc(appDb, `/orders/${order.orderId}`), order)
    })
  }

  const makeReport = async () => {
    const ordersRef = collection(appDb, 'orders')
    // const dataRef = 1669215600 // Wednesday, November 23, 2022 11:59:59 PM GMT-03:00
    const q1 = query(ordersRef,
      where('status', '==', 'FULFILLED'),
      where('userId', '!=', 'YETftQD0Y5goflAqDPAixTY3O2h2'))    // Retirando MRC
      // where('orderSide', '==', 'BUY'))

    const querySnapshot = await getDocs(q1)
    clog(`TAMANHO col = ${querySnapshot.size}`)
    let userOrdersList = []
    if(!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        const order = doc.data()
        order.orderId = doc.id
        userOrdersList.push(order)
      })
      clog(`userOrdersList TAM: ${userOrdersList.length}`)
      clog(JSON.stringify(userOrdersList, null, 2))
    }
    clog(`userOrdersList TAM: ${userOrdersList.length}`)
    clog(JSON.stringify(userOrdersList, null, 2))

    let csvBuyData = [
      ["Data criação (segs)", "Data fulfilled (segs)", "Data criação", "Data fulfilled", "Nome", "Email", "CPF", "Phone", "Valor", "Cesta", "Hero", "Tipo", "Veio de", "id"],
    ]

    let cell = 2

    for(let i in userOrdersList) {
      const order = userOrdersList[i]
      const userRef = doc(appDb, `users/${order.userId}`)
      const userSnap = await getDoc(userRef)
      const phone = formatPhone(userSnap.data().personalInfo.phone)
      const cameFrom = userSnap.data().cameFrom
      // const dateCreation = toDateTime(order.timeCreation.seconds)
      const dateCreation = order.timeCreation.seconds
      const dateFulfilled = order.timeUpdate.seconds
      const dateFormula1 = `=(A${cell}/86400)+DATE(1970,1,1)`
      const dateFormula2 = `=(B${cell}/86400)+DATE(1970,1,1)`
      const name = order.personalInfo.name
      const email = order.personalInfo.email
      const cpf = formatCpf(userSnap.data().personalInfo.cpf)
      const value = order.orderSide === 'BUY' ?
        order.intendedInvestedValue :
        order.totalToReceive
      const basket = order.basketRefId
      const hero = order.heroId
      const side = order.orderSide
      const id = order.orderId
      const newLine = [dateCreation, dateFulfilled, dateFormula1, dateFormula2, name, email, cpf, phone, value, basket, hero, side, cameFrom, id]
      cell++
      csvBuyData.push(newLine)
    }
    setDataToWrite(csvBuyData)
    setSuccessMessage('Report ready to download')
  }

  const checkUserBaskted  = async () => {

    // const uid = 'YETftQD0Y5goflAqDPAixTY3O2h2'      // MRC
    // const uid = 'mdgT7tTVoJag1Zqt4Eh4kkN3gWX2'      // STE
    const uid = '4lDQM9T116R8aWF9ETnjeaD8Cvt1'      // 
    // const basketId = 'stefano_carteiraConservadora'
    const basketId = 'stefano_maiorPotencialDeGanhos'
    // const basketId = 'roberto_shardingBlockfolio'
    // const basketId = 'roberto_blockfolioMassAdoption'
    // const basketId = 'mauricio_carteiraConservadora'

    const docRef = doc(appDb, `/users/${uid}/userBaskets/${basketId}`)
    // const docRef = doc(appDb, `/users/TH6NNEPMWCOTgEJZBaUboHwqYO03/userBaskets/stefano_carteiraConservadora`)

    const docData = (await getDoc(docRef)).data()
    clog('docData')
    clog(JSON.stringify(docData, null, 2))
    const Binance = require('node-binance-api')
    const binance = new Binance().options({})

    let total = 0       // Apenas para checar se valor está coerente com o investido
    let totalValue = 0
    const brlPrice = await getPrice('USDTBRL', 'bidPrice', binance)
    for(let symbol in docData.coins) {
      clog(`------------------------------ ${symbol} ------------------------------`)
      const buyData = docData.coins[symbol]
      // clog(buyData)
      total += buyData.buyPrice * buyData.amount
      
      const currentPrice = await getPrice(`${symbol}USDT`, 'bidPrice', binance)
      const currentCoinTotalValue = currentPrice * buyData.amount
      // clog(`currentCoinTotalValue AT BUY = ${buyData.buyPrice * buyData.amount}`)
      // clog(`currentCoinTotalValue NOW = ${currentCoinTotalValue}`)
      totalValue += currentCoinTotalValue
      
      clog(`${symbol} PRICE = ${currentPrice}`)
      clog(`${symbol} AMOUNT = ${buyData.amount}`)
      clog(`buyCoinValue BRL = ${docData.investedValue * buyData.percentage}`)
      clog(`currentCoinTotalValue BRL = ${currentCoinTotalValue * brlPrice}`)
      clog(`-----------------------------------------------------------------------`)
    }
    
    const totalBrl = brlPrice * total
    clog(`total investido in USD = ${total}`)
    clog(`total investido in BRL APROXIMADAMENTE = ${totalBrl} BRL`)
    clog(`total investido in BRL REAL = ${docData.investedValue} BRL`)
    clog(`totalValue in USD = ${totalValue}`)
    clog(`totalValue in BRL = ${totalValue * brlPrice}`)
    
    const gain = (totalValue*brlPrice - docData.investedValue)
    const relativeGain = gain / docData.investedValue
    clog(`------------------------------ TOTALS ------------------------------`)
    clog(`totalValue*brlPrice = ${totalValue*brlPrice}`)
    clog(`totalBasketInvestedValue BRL = ${docData.investedValue}`)
    clog(`absBasketGain BRL = ${gain}`)
    clog(`relBasketGain BRL = ${relativeGain}`)
    clog(`--------------------------------------------------------------------`)
  }

  const confirmTestPurchases = async () => {
    const gasparID = 'YETftQD0Y5goflAqDPAixTY3O2h2'
    const colNale = 'orders'
    const ordersRef = collection(appDb, colNale)
    const q1 = query(ordersRef, where('userId', '==', gasparID), where('status', '==', 'OPEN'))
    const querySnapshot = await getDocs(q1)
    clog(`TAMANHO col = ${querySnapshot.size}`)
    let userOrdersList = []
    let userOrdersIdsList = []
    if(!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        const order = doc.data()
        order.orderId = doc.id
        userOrdersList.push(order)
        userOrdersIdsList.push(order.orderId)
      })
    }
    clog(`userOrdersList`)
    clog(JSON.stringify(userOrdersIdsList, null, 2))
    for(let i in userOrdersIdsList) {
      const orderId = userOrdersIdsList[i]
      const docRef = doc(appDb, `orders/${orderId}`)

      clog(`orderId = ${orderId}`)

      await updateDoc(docRef, {
        pixStatus: 'CONCLUIDA'
      })
    }
  }

  const deleteOwnOrders = async () => {

    const colNale = 'productsOrders'
    const gasparID = 'YETftQD0Y5goflAqDPAixTY3O2h2'

    const ordersRef = collection(appDb, colNale)
    const q1 = query(ordersRef, where('userId', '==', gasparID))
    const querySnapshot = await getDocs(q1)
    clog(`TAMANHO col = ${querySnapshot.size}`)
    let userOrdersList = []
    if(!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        const order = doc.data()
        order.orderId = doc.id
        userOrdersList.push(order)
      })
    }
    clog(`userOrdersList TAM: ${userOrdersList.length}`)
    clog(JSON.stringify(userOrdersList, null, 2))
    userOrdersList.forEach(async order => {
      const orderId = order.orderId
      clog(`orderId = ${orderId}`)
      await deleteDoc(doc(appDb, `/${colNale}/${orderId}`))
    })
  }

  const doSomething = async () => {
    //

    // const url = 'https://southamerica-east1-blackbelt-674a4.cloudfunctions.net/updateInvestorInfo'
    // const bodyData = {
    //   managerId: 'mqpDn8Md9oEKXtL9DGwZ',
    //   investorId: 'ZQSzmmhpNtujAvxrmgqB',
    //   investorAlias: 'Família Milani Corte Sales',
    //   // investorDetails: 'hahahahahahusuhasdhusadhu asdklakosdkoasdas dokaskods'
    // }

    // const url = 'https://southamerica-east1-blackbelt-674a4.cloudfunctions.net/testCall'
    // const response = await httpRequest(url)

    // const url = 'https://southamerica-east1-blackbelt-674a4.cloudfunctions.net/deleteInvestor'
    // const bodyData = {
    //   managerId: 'mqpDn8Md9oEKXtL9DGwZ',
    //   investorId: 'h6nDWuit3NwrSSbfjkLC',
    // }

    // const url = 'https://southamerica-east1-blackbelt-674a4.cloudfunctions.net/createInvestor'
    // const bodyData = {
    //   managerId: 'mqpDn8Md9oEKXtL9DGwZ',
    //   investorAlias: 'Família Corte Correia',
    //   investorDetails: 'bla bla bla bla bla bla'
    // }

    // const url = 'https://southamerica-east1-blackbelt-674a4.cloudfunctions.net/createManager'
    // const bodyData = {
    //   managerInfo: {
    //     name: 'Joe Doe',
    //     company: 'Acme Multi Family Office',
    //     companyId: 'V3jASW6v2I2ylkw2a0N9',
    //     contact: {
    //       email: 'joe@acme.com',
    //       phone: '5511999999999'
    //     }
    //   }
    // }

    // const url = 'https://southamerica-east1-blackbelt-674a4.cloudfunctions.net/createCompany'
    // const bodyData = {
    //   companyInfo: {
    //     name: 'Acme Multi Family Office',
    //     address: 'Rua XYZ 1234'
    //   }
    // }

    const url = 'https://southamerica-east1-blackbelt-674a4.cloudfunctions.net/testCall'
    const response = await httpRequest(url)

    // const response = await httpRequest(url, 'post', {}, {}, bodyData)
    clog('response:')
    clog(JSON.stringify(response, null, 2))
    clog(response.data)
    //
  }

  //ATENTION: this function get all the coins from the data json "allCoins" and add it to the firebase collection cryptocurrencies
  const updateAllCoinsToFirebase = async() => {
    for await (const coin of allCoinsData) {
      try {
        await setDoc(doc(appV2Db, `cryptocurrencies/${coin.symbol}`), {...coin, darkerColor: darkenHexColor(coin.mainColor, 0.5)})
        clog(`Document written with ID: ${coin.symbol}`)
        /* clog({...coin, darkerColor: darkenHexColor(coin.mainColor, 0.5)}) */
      }
      catch(error) {
        clog(`Error creating Firestore document for ${coin.symbol}:`)
        clog(error)
      }
    }
  }


  const symbolPrices = useRef({})
  const calculateUsersBalance = async () => {

    const getPairPrice = async (pairSymbol, timePriceReference) => {
      if(pairSymbol + timePriceReference in symbolPrices.current) {
        return symbolPrices.current[pairSymbol + timePriceReference]
      }
      try {
        const pairPrice = await getPairSymbolPrice(currentAdminUser, pairSymbol, timePriceReference)
        const prevSymbolPrices = symbolPrices.current
        symbolPrices.current = {
          ...prevSymbolPrices,
          [pairSymbol + timePriceReference]: pairPrice
        }
        return pairPrice
      }
      catch(error) {
        try {
          const pairPrice = await getPairSymbolPrice(currentAdminUser, pairSymbol, timePriceReference)
          const prevSymbolPrices = symbolPrices.current
          symbolPrices.current = {
            ...prevSymbolPrices,
            [pairSymbol + timePriceReference]: pairPrice
          }
          return pairPrice
        }
        catch(error) {
          clog('ERROR 2:')
          console.error(error)
        }
      }
    }

    const QUOTE_SYMBOL = 'USDT'

    const currentTimeDate = Math.round(Date.now() / 1000) - 1
    const dolarToBrl = await getPairPrice('USDTBRL', currentTimeDate)
    clog(`dolarToBrl = ${dolarToBrl}`)

    const usersRef = collection(appDb, 'users')
    const usersQuerySnap = await getDocs(usersRef)
    let usersIds = []
    usersQuerySnap.forEach(doc => {
      usersIds.push(doc.id)
    })
    clog('INICIOO')
    let totalInUsd = 0
    let totalBNBQty = 0
    for(const userId of usersIds) {
      const userBasketsRef = collection(appDb, `/users/${userId}/userBaskets`)
      const q1 = query(userBasketsRef, where('status', '==', 'FULFILLED'))
      const usersBasketsQuerySnap = await getDocs(q1)
      if(usersBasketsQuerySnap.size === 0) {
        continue        
      }
      let coinsList = []
      clog(`TOTAAL = ${usersBasketsQuerySnap.size}`)
      usersBasketsQuerySnap.forEach(doc => {
        const userBasket = doc.data()
        const coins = userBasket.coins
        coinsList.push(coins)
      })
      for(const coins of coinsList) {
        for(const symbol in coins) {
          const amount = coins[symbol].amount
          clog(`amount of ${symbol} = ${amount}`)
          const pairSymbol = symbol + QUOTE_SYMBOL
          const currentTimeDate = Math.round(Date.now() / 1000) - 1
          const price = await getPairPrice(pairSymbol, currentTimeDate)
          clog(`price of ${pairSymbol} = ${price}`)
          const partialValue = price * amount
          totalInUsd += partialValue
          if(symbol === 'BNB') {
            totalBNBQty += amount
          }
        }
      }
    }
    clog(`totalInUsd = ${totalInUsd}`)
    clog(`totalBNBQty = ${totalBNBQty}`)

  }

  const createSellOrder = async (userId, totalToReceive) => {

    // ******************* INPUTS ******************* //
    // IMPORTANTE!!!
    // users/nDJfNywyXTMeWuYHSlH9uBR22NF2/userBaskets
    // *************** APAGAR A CESTA DO USER!!!!!!!!!!!!!
    // IMPORTANTE!!!
    const usdtBrl = 5.013
    // ******************* INPUTS ******************* //

    const userBasketsRef = collection(appDb, `/users/${userId}/userBaskets`)
    const q1 = query(userBasketsRef, where('status', '==', 'FULFILLED'))
    const usersBasketsQuerySnap = await getDocs(q1)
    const allUserBaskets = []
    usersBasketsQuerySnap.forEach(doc => {
      const userBasket = doc.data()
      allUserBaskets.push(userBasket)
    })

    const userRef = doc(appDb, `users/${userId}`)
    const userSnap = await getDoc(userRef)
    const userData = userSnap.data()

    const personalInfo = userData.personalInfo
    if(!('pixCode' in personalInfo)) {
      personalInfo.pixCode = 'SEM_INFO_DE_PIX'
    }

    clog(JSON.stringify(personalInfo, null, 2))


    for(const basket of allUserBaskets) {
      const colRef = collection(appDb, 'orders')
      const basketRefId = basket.basketRefId
      const coins = Object.values(basket.coins).map(coin => ({
        percentage: coin.percentage,
        symbol: coin.symbol
      }));
      const heroId = basket.heroId
      const investedValue = basket.investedValue
      const sellData = {
        basketRefId: basketRefId,
        coins: coins,
        fee: 0,
        fiatSymbol: 'BRL',
        heroId: heroId,
        investedValue: investedValue,
        orderSide: 'SELL',
        personalInfo: personalInfo,
        status: 'WAITING',
        timeCreation: Timestamp.now(),
        timeUpdate: Timestamp.now(),
        totalToReceive: totalToReceive,
        usdtBrl: usdtBrl,
        userId: userId,
      }
      const returna = await addDoc(colRef, sellData)
      clog('NOVO DOC ID:')
      clog(returna.id)
    }
  }

  const createAllSellOrders = async () => {
    const usersData = [
      {
        "userId": "pzB0KzNeHaYF14IyA4zXiYCTJVs2",
        "cpf": "576.555.088-60",
        "name": "Moisés Vitor dias Moreira",
        "total": 2.9
      },
      {
        "userId": "jvk4ow05m4YEkDbBEYmEnB2sIrA2",
        "cpf": "300.261.388-67",
        "name": "Enos Ferreira Lopes Junior",
        "total": 2.11
      },
      {
        "userId": "97elHMhXcIXPZnzTblgT3WZVMBD3",
        "cpf": "142.604.196-92",
        "name": "Iara Pereira Alves",
        "total": 0.74
      },
      {
        "userId": "XPKXkKRIUbVemiutzHgaDsaazoe2",
        "cpf": "082.701.112-10",
        "name": "Ronnyerick da Silva Cardoso",
        "total": 1.37
      },
      {
        "userId": "CxTBD6J8AZSNCNaY4DS2dcmwrOr1",
        "cpf": "494.532.688-60",
        "name": "Gabriel Guedes Paulo de Souza",
        "total": 1.39
      },
      {
        "userId": "B2XApGYKErdsJcDZMKzp1QdfIr13",
        "cpf": "352.269.688-33",
        "name": "Adriano Trindade Nunes de Oliveira",
        "total": 1.86
      },
      {
        "userId": "9K8NKisKNJgi7bWSWl4FC9xLP2p2",
        "cpf": "123.465.639-60",
        "name": "Henzo Rodrigues baugartener",
        "total": 11.94
      },
      {
        "userId": "hrv2sNWzIeXAENsMbfgBuvEvhY42",
        "cpf": "493.854.705-82",
        "name": "Seara Maria de Souza Matos",
        "total": 118.84
      },
      {
        "userId": "ImdxIKpvieMrRLcZWdmLRuUJL2c2",
        "cpf": "028.632.473-36",
        "name": "Danilo Oliveira da Silva",
        "total": 13.73
      },
      {
        "userId": "9FWsUP9ogZTzrd0wRg9lTW5W5MG2",
        "cpf": "024.992.721-71",
        "name": "Luiz Carlos Souza da Silva",
        "total": 13.78
      },
      {
        "userId": "28D0AK8TjigaeRVX38ZKNtEgyI62",
        "cpf": "392.304.508-55",
        "name": "Israel",
        "total": 13.88
      },
      {
        "userId": "KUiuZo0iqsZR3bvamUB2wBzabPj2",
        "cpf": "985.251.662-00",
        "name": "Stephany de Oliveira Cavalcante",
        "total": 13.95
      },
      {
        "userId": "yRCSYtP4lNYVUNhP2IH6gl8nbLA3",
        "cpf": "051.072.590-22",
        "name": "Vítor Leonardo Kerschner Franck",
        "total": 13.97
      },
      {
        "userId": "ph50eubaIQZK8Y1JOJXw3zh7AvF2",
        "cpf": "852.739.535-53",
        "name": "Jobson muniz dos Santos",
        "total": 14.93
      },
      {
        "userId": "GNK4bAZQLTZNl072TgqR24I9iSA2",
        "cpf": "269.069.027-68",
        "name": "Antonio Jose Dourado Tourinho",
        "total": 164.34
      },
      {
        "userId": "BcDnQs4nzEOb6VzYFqInwgRZeOB3",
        "cpf": "184.419.712-34",
        "name": "Rosani Queiroga Fraga",
        "total": 178.04
      },
      {
        "userId": "FojpveU4flQIP3elgVr1gTmGt0G3",
        "cpf": "095.184.964-63",
        "name": "Tawanny victoria pereira de pinho",
        "total": 18.68
      },
      {
        "userId": "nDJfNywyXTMeWuYHSlH9uBR22NF2",
        "cpf": "074.758.115-06",
        "name": "Lucas Alves Das Neves Santana",
        "total": 2.27
      },
      {
        "userId": "3KLQNGF8KGVJOICp5pF3L9MxQwp1",
        "cpf": "476.375.828-41",
        "name": "GABRIEL DE FIGUEIREDO DALESSANDRO",
        "total": 2.88
      },
      {
        "userId": "TzvalrskOHWA5MgoeItHgd3v9ru1",
        "cpf": "367.785.568-38",
        "name": "André Fagundes Corrêa",
        "total": 4.23
      },
      {
        "userId": "35Fbq0ur0Hbq50ScLgKgtsLwhRj2",
        "cpf": "113.337.604-50",
        "name": "Lucas Oliveira da Silva",
        "total": 5.58
      },
      {
        "userId": "SzNkzMfiAFPtcxcg2AyaJ89cOho2",
        "cpf": "038.474.140-11",
        "name": "William Sidnei Bernstein",
        "total": 57.35
      },
      {
        "userId": "y9a1Tq5Q4hTxkFT7AIIzVgWRLCy1",
        "cpf": "979.654.729-53",
        "name": "Sergio Benny Pereira da Veiga",
        "total": 582.67
      },
      {
        "userId": "Oq9RTprcA0ZeTlPEUusBv7o3aTw2",
        "cpf": "060.924.353-57",
        "name": "Wellisson Gonçalves de Andrade",
        "total": 7.78
      },
      {
        "userId": "crV2PVEAmBUjDBprJRvZ75qmGkx2",
        "cpf": "111.238.499-51",
        "name": "Bruno Eduardo Miguel Couto",
        "total": 8.26
      },
      {
        "userId": "yb5v7JSVQrMUDlwShLxCo1lSKux2",
        "cpf": "575.745.703-10",
        "name": "Luciana Maria Cassundé de Oliveira",
        "total": 98.61
      }
    ]

    for(const data of usersData) {
      const userId = data.userId
      const totalToReceive = data.total
      clog(`userId = ${userId}`)
      clog(`totalToReceive = ${totalToReceive}`)
      await createSellOrder(userId, totalToReceive)
    }
  }
  // FULFILLED
  // WAITING
  const changeOrderStatus = async (orderId) => {
    const docRef = doc(appDb, `orders/${orderId}`)
    await updateDoc(docRef, {
      status: 'WAITING'
    })
  }
  const changeOrdersStatus = async () => {
    const orderIds = [
      "H0ZsCppVYlLEZ8wjQEJq",
      "xANFb3HBhZ9FhYQiHgkK",
      "Ri9O0vjTHZ3dxNLUnwll",
      "mUCKA6wcXAB1cayv1A7p",
      "aoX2kHW59uNH2tHuZWEE",
      "fhs4tU9oIG6kaLOXgTca",
      "KkHkNuB2fR6aL7sqWMte",
      "7IpEsP6cDNBKBZYiOsSx",
      "UpJWngQBPCMYcPF0mSGm",
      "u5fgSUqQyyphw6zNBrsY",
      "rysfPww4H7HLqmBB3i2D",
      "cQCBe6WKWdHD41lSlGDP",
      "jAQvHCrwIcrAntOnSmtJ",
      "ULvxHr6f34BCyoLZiMWu",
      "QscPne56KGMjxuYEkEX9",
      "HFvaCMkq7zCzn6Ka0Diw",
      "R5dKy58RYPem4drnkhUC",
      "ARZPWneM33Y7WKxRV2ma",
      "T6IycAVlLm8xiPmMaom4",
      "zd43UhWkgz4CPtLJKglh",
      "KPtCfyTM5XXS9h5xALdg",
      "5VdsVnnaVPASM9xeY2wz",
      "gcnsFE2bhbB4S8X31ksF",
      "NSukZSo872KhrxWya8fW",
      "d0KeC23XXkzIFFRPZeAd",
      "KBjqOxUoaDPMlaioAdrM",
    ]
    for(const orderId of orderIds) {
      await changeOrderStatus(orderId)
    }
    clog('DONE!')
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Choose an option</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <strong>You're logged in as:</strong> {currentAdminUser.email}
          {/* {permitted && <Link to="/users-buy-orders" className="btn btn-primary w-100 mt-3">
            Users Buy Orders
          </Link>} */}
          <Link to="/users-buy-orders" className="btn btn-primary w-100 mt-3">
            Users Payed Orders
          </Link>
          <Link to="/users-sell-orders" className="btn btn-danger w-100 mt-3">
            Users Sell Orders
          </Link>
          {permitted && <Link to="/manage-heroes" className="btn btn-outline-primary w-100 mt-3">
            Manage Heroes
          </Link>}
          <Link to="/update-profile" className="btn btn-outline-primary w-100 mt-3">
            Update Profile
          </Link>
          <Link to="/manage-assets-aux" className="btn btn-outline-primary w-100 mt-3">
            Manage Assets Aux.
          </Link>
          {permitted && <Link to="/manage-assets" className="btn btn-outline-primary w-100 mt-3">
            Manage Assets
          </Link>}
          <Link to="/legal-reports" className="btn btn-outline-primary w-100 mt-3">
            Legal Reports
          </Link>
          <Link to="/analytics" className="btn btn-outline-primary w-100 mt-3">
            Analytics
          </Link>
          <Link to="/send-bulk-emails" className="btn btn-outline-primary w-100 mt-3">
            Send Emails to All
          </Link>
          {permitted && <Link to="/verify-accounts" className="btn btn-outline-primary w-100 mt-3">
            Verify Accounts
          </Link>}
          <Link to="/basket-reports" className="btn btn-outline-primary w-100 mt-3">
            Basket Reports
          </Link>
          <Link to="/customer-reports" className="btn btn-outline-primary w-100 mt-3">
            Customer Reports
          </Link>
          
        </Card.Body>
        {/* <Button onClick={sendEmailsTelegramTemp}>
          ENVIAR EMAILS
        </Button>*/}
        {(permitted && onlyLocalhost) && <>
          {/* <br></br>
          <Button onClick={checkUserBaskted} disabled={true}>
            CHECK USER BASKET
          </Button> */}
          <br></br>
          <Button onClick={changeOrdersStatus} disabled={false}>
            CHANGE ORDERS STATUS
          </Button>
          <br></br>
          <Button onClick={createAllSellOrders} disabled={false}>
            CREATE SELL ORDER
          </Button>
          <br></br>
          <Button onClick={doSomething} disabled={false}>
            DO SOMETHING
          </Button>
          <br></br>
          <Button onClick={confirmTestPurchases} disabled={true}>
            CONFIRM MRC TEST PURCHASES
          </Button>
          <br></br>
          <Button onClick={calculateUsersBalance} disabled={true}>
            CALCULATE USERS BALANCES
          </Button>
          <br></br>
          <Button onClick={deleteOwnOrders} disabled={true}>
            DELETE OWN ORDERS
          </Button>
          <br></br>
          <Button onClick={restoreOrders} disabled={true}>
            RESTORE ORDERS
          </Button>
          <br></br>
        </>}
        {successMessage && <Alert variant='success'>{successMessage}</Alert>}
        {/* <Button onClick={makeReport} disabled={false}>
          MAKE BUY/SELL REPORT
        </Button>
        <CSVLink
          data={dataToWrite}
          separator={','}
          filename={`buys_sells.csv`}>
          Download report
        </CSVLink> */}
        
      </Card>
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </>
  )
}