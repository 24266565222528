import React, { useState, useEffect } from 'react'
import { Form, Button, Card, Alert, ListGroup } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
// import { useDatabase } from '../contexts/DatabaseContext'
import { appDb } from '../firebase'
import { doc, getDoc, updateDoc } from '../firebase'
import { Timestamp } from 'firebase/firestore'
import { Link, useLocation } from 'react-router-dom'

// import buyResponseMock from '../mocks/buyResponse2.json'
import Coin from './Coin'
import { clog, recordError, secsToDateTime, formatPrice, formatCpf, sendEmail, getPrice, copyToClipboard } from '../auxiliary/helpers'

export default function Exchange() {

  const location = useLocation()
  const { buyingPower, bnbBalance, userOrder, buyingUsdPower } = location.state

  const QUOTE_ASSET_SYMBOL = 'USDT'
  const MIN_SDT_NOTIONAL = 11

  const { currentAdminUser, currentAppUser } = useAuth()

  const [orderMessage, setOrderMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [error, setError] = useState('')
  const [buyingPowerAlert, setBuyingPowerAlert] = useState('')
  const [notice, setNotice] = useState('')
  const [disabledButton, setDisabledButton] = useState(true)
  const [disabledCancelButton, setDisabledCancelButton] = useState(true)
  const [loading, setLoading] = useState(true)
  const [finalizedBuy, setFinalizedBuy] = useState(false)
  const [usdtBrl, setUsdtBrl] = useState(0)
  const [permitted, setPermitted] = useState(true)

  useEffect(() => {
    const PERMMITED_UID = 'tsM6UtAGmAcx0YHI1a2rh0BpkOB2'
    const uid = currentAdminUser.uid
    const url = new URL(window.location.href)
    const hostname = url.hostname
    if(uid === PERMMITED_UID && hostname === 'localhost') setPermitted(true)
    else setError('User not allowed!')
  }, [])

  useEffect(() => {
    const checkBuyingPower = async () => {
      const coinsNumber = userOrder.coins.length
      // const minUsdToBuy = MIN_SDT_NOTIONAL * coinsNumber
      clog(buyingUsdPower)
      const Binance = require('node-binance-api')
      const binance = new Binance().options({})
      let minUsdToBuy = 0
      for(let i in userOrder.coins) {
        const percentage = userOrder.coins[i].percentage
        const pairSymbol = QUOTE_ASSET_SYMBOL + 'BRL'
        const askPrice = await getPrice(pairSymbol, 'askPrice', binance)
        setUsdtBrl(askPrice)
        const totalUsd = userOrder.intendedInvestedValue/askPrice * percentage
        clog(`totalUsd = ${totalUsd}`)
        if(totalUsd < MIN_SDT_NOTIONAL) {
          minUsdToBuy += totalUsd + 11
        }
        else minUsdToBuy += totalUsd
      }
      clog(`buyingUsdPower = ${buyingUsdPower}`)
      clog(`minUsdToBuy = ${minUsdToBuy}`)

      if(buyingUsdPower < minUsdToBuy) setBuyingPowerAlert(`Caution: need more money to buy basket safely. Minimum of ${minUsdToBuy.toFixed(2)} USD to buy safely`)
      setLoading(false)
    }
    checkBuyingPower()
  }, [])

  const cancelOrder = async () => {
    const orderId = userOrder.orderId
    clog(`***** orderId = ${orderId}`)
    const orderRef = doc(appDb, 'orders', orderId)
    const userOrderDoc = await getDoc(orderRef)

    clog('userOrderDoc')
    clog(JSON.stringify(userOrderDoc.data(), null, 2))

    await updateDoc(orderRef, {
      status: 'CANCELLED',
      timeUpdate: Timestamp.now()
    })

    const userBasketRef = doc(appDb, `/users/${userOrder.userId}/userBaskets/${userOrder.heroId}_${userOrder.basketRefId}`)
    const userBasket = await getDoc(userBasketRef)
    // const prevInvestedValue = 'investedValue' in userBasket.data() ? userBasket.data().investedValue : 0
    const prevIntendedInvestedValue = userBasket.data().intendedInvestedValue

    clog(`prevIntendedInvestedValue = ${prevIntendedInvestedValue}`)
    clog(`userOrder.intendedInvestedValue = ${userOrder.intendedInvestedValue}`)

    const resp = await updateDoc(userBasketRef, {
      intendedInvestedValue: prevIntendedInvestedValue - userOrder.intendedInvestedValue > 0 ? prevIntendedInvestedValue - userOrder.intendedInvestedValue : 0,
      timeUpdate: Timestamp.now()
    })
    setOrderMessage('Success! -- Order cancelled with success')
    clog('resp')
    clog(resp)
    clog(JSON.stringify(resp, null, 2))
  }

  const handleCancelConfirmation = (event) => {
    const target = event.target
    if(target.type === 'checkbox') {
      setDisabledCancelButton(!target.checked)
      return
    }
  }

  const confirmBuy = async (event) => {
    event.preventDefault()
    setWaitMessage('Wait while the order is processed')
    setLoading(true)

    const response = await adminBuyBasket()
    console.log('BUY response')
    console.log(JSON.stringify(response, null, 2))

    const allFulfilled = checkAllFulfilled(response)

    if(allFulfilled) {
      const isRecorded = await recordUpdatedOrder(response, userOrder.orderId)
      const templateId = 'd-3eadf76b52054ed8a59e2c37536875ad'
      const dynamicTemplateData = {
        name: userOrder.personalInfo.name,
        hero: userOrder.heroId,
      }
      const userEmail = userOrder.personalInfo.email
      const emailSent = await sendEmail(currentAppUser, userEmail, templateId, dynamicTemplateData)
      if(isRecorded && emailSent) {
        setWaitMessage('')
        setOrderMessage('Success! -- Click "Back to Orders List" link to return to list')
        setFinalizedBuy(true)
      }
      else {
        setWaitMessage('')
        if(!isRecorded) setError('Erro ao gravar os dados de compra do cliente. Cheque o banco de dados')
        if(!emailSent) setError('Erro ao enviar o e-mail de aviso para o cliente')
      }
    }
    else {
      setWaitMessage('')
      setError('Erro: não foi possível executar todas as compras')
    }
  }

  const checkAllFulfilled = (response) => {
    for(let i in response.response) {
      if(response.response[i].status !== 'FILLED') {
        const message = 'Not every order was fulfilled. Check the orders manually.'
        setWaitMessage('')
        setError(message)
        recordError(appDb, {message: message, data: response})
        return false
      }
    }
    // REMOVED remainderResponse
    // for(let i in response.remainderResponse) {
    //   if(response.response[i].status !== 'FILLED') {
    //     const message = 'Not every reminder order was fulfilled. Check the orders manually.'
    //     setError(message)
    //     recordError(appDb, {message: message, data: response})
    //     return false
    //   }
    // }
    return true
  }

  const adminBuyBasket = async () => {

    clog('INICITOU EXEC...')
    
    const authTokenId = await currentAdminUser.getIdToken()
    const options = {
      headers: {
        'Authorization': 'Bearer ' + authTokenId,
      }
    }

    /***************************************************/
    const EMULATOR = false
    /***************************************************/

    const baseUrl = EMULATOR ?
    'http://localhost:5001/ch-admin-v1/southamerica-east1/' :
    'https://southamerica-east1-ch-admin-v1.cloudfunctions.net/'

    const cloudFunction = 'adminBuyBasket'

    const orderSide = userOrder.orderSide
    const basketDataRaw = userOrder.coins.map(item => {
      return {
        rawSymbol: item.symbol,
        relativeAmount: item.percentage
      }
    })

    const investedFiatAmount = userOrder.intendedInvestedValue
    const fiatSymbol = userOrder.fiatSymbol

    const url = `${baseUrl}${cloudFunction}?uid=${currentAdminUser.uid}&basketDataRaw=${JSON.stringify(basketDataRaw)}&orderSide=${orderSide}&investedFiatAmount=${investedFiatAmount}&fiatSymbol=${fiatSymbol}`
    try {
      clog('INICIOU FETCH...')
      const data = await fetch(url, options)
      return data.json()
    }
    catch(error) {
      console.log(error)
    }
  }

  const recordUpdatedOrder = async (response, docId) => {
    try {
      clog('recording updated ORDER')
      const fulfilledCoins = []
      for(let i in response.response) {
        let fulfilledAmount = parseFloat(response.response[i].executedQty)
        for(let j in response.baseAmountsClient) {
          if(response.response[i].symbol === response.baseAmountsClient[j].symbol) {
            fulfilledAmount = parseFloat(response.baseAmountsClient[j].baseAmount)
          }
        }
        // for(let j in response.remainderResponse) {
        //   if(response.remainderResponse[j] &&
        //   Object.keys(response.remainderResponse[j]).length !== 0 &&
        //   response.response[i].symbol === response.remainderResponse[j].symbol) {
        //     fulfilledAmount = parseFloat(response.response[i].executedQty) - parseFloat(response.remainderResponse[j].executedQty)
        //   }
        // }
        let buyPrice = 0
        const executedQty = parseFloat(response.response[i].executedQty)
        response.response[i].fills.forEach(fill => {
          const price = parseFloat(fill.price)
          const qty = parseFloat(fill.qty)
          buyPrice += price * qty / executedQty
        })
        fulfilledCoins.push({
          fulfilledAmount: fulfilledAmount,
          status: 'FULFILLED',
          symbol: response.response[i].symbol,
          buyPrice: buyPrice
        })
      }
      clog('fulfilledCoins')
      clog(JSON.stringify(fulfilledCoins, null, 2))

      const orderRef = doc(appDb, 'orders', docId)
      await updateDoc(orderRef, {
        status: 'FULFILLED',
        fulfilledCoins: fulfilledCoins,
        usdtBrl: usdtBrl,
        timeUpdate: Timestamp.now()
      })

      const userBasketRef = doc(appDb, `/users/${userOrder.userId}/userBaskets/${userOrder.heroId}_${userOrder.basketRefId}`)
      const userBasket = await getDoc(userBasketRef)

      const prevInvestedValue = 'investedValue' in userBasket.data() ? userBasket.data().investedValue : 0
      const prevIntendedInvestedValue = userBasket.data().intendedInvestedValue

      clog(`prevInvestedValue = ${prevInvestedValue}`)
      clog(prevInvestedValue)

      await updateDoc(userBasketRef, {
        status: 'FULFILLED',
        usdtBrl: usdtBrl,
        investedValue: prevInvestedValue + userOrder.intendedInvestedValue,
        intendedInvestedValue: prevIntendedInvestedValue - userOrder.intendedInvestedValue > 0 ? prevIntendedInvestedValue - userOrder.intendedInvestedValue : 0,
        timeUpdate: Timestamp.now()
      })
      const coins = userBasket.data().coins
      for(let i in fulfilledCoins) {
        for(let j in coins) {
          const symbol = fulfilledCoins[i].symbol.substring(0, fulfilledCoins[i].symbol.length - QUOTE_ASSET_SYMBOL.length)
          if(symbol === coins[j].symbol) {
            const prevBuyPrice = parseFloat(coins[j].buyPrice)
            const prevAmount =  parseFloat(coins[j].amount)
            const newBuyPrice = parseFloat(fulfilledCoins[i].buyPrice)
            const newAmount =  parseFloat(fulfilledCoins[i].fulfilledAmount)
            const amount = prevAmount + newAmount
            const buyPrice = (prevBuyPrice*prevAmount + newBuyPrice*newAmount)/amount
            if(amount === 0) setError('Error: revAmount + newAmount === 0')

            clog('-----------------------------------------------------------------------------------')
            clog(`symbol = ${symbol}`)
            clog(`prevBuyPrice = ${prevBuyPrice}`)
            clog(`prevAmount = ${prevAmount}`)
            clog(`newBuyPrice = ${newBuyPrice}`)
            clog(`newAmount = ${newAmount}`)
            clog(`*** amount = ${amount}`)
            clog(`*** buyPrice = ${buyPrice}`)
            clog('-----------------------------------------------------------------------------------')



            await updateDoc(userBasketRef, {
              [`coins.${symbol}.amount`]: amount,
              [`coins.${symbol}.buyPrice`]: buyPrice
            })
          }
        }
      }
      return true
    }
    catch(error) {
      // If there is an error or a partial fulfillment
      setError(error)
      return false
    }
  }

  const handleValueChange = (event) => {
    const target = event.target
    if(target.type === 'checkbox') {
      setDisabledButton(!target.checked)
      return
    }
  }

  const coins = userOrder.coins.map(item => {
    return(<Coin
        key={item.symbol}
        symbol={item.symbol}
        percentage={item.percentage}
        />
    )
  })

  const copyInfo = (info, value) => {
    copyToClipboard(value)
    setNotice(`${info} copied`)
  }

  const globalStatusStyle = {
    color: userOrder.status === 'ERROR' ? 'red' :
    (userOrder.status === 'FULFILLED' ? 'green' :
    (userOrder.status === 'PARTIAL' ? 'orange' : 'default'))
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className='text-center mb-4'>Exchange</h2>
          <br></br>
          <br></br>

          <Card style={{ width: '40rem' }}>
            <p>Account Info:</p>
            <ListGroup variant='flush'>
            {buyingPowerAlert && <Alert variant='danger'>{buyingPowerAlert}</Alert>}
            <ListGroup.Item>Buying Power: {buyingPower} ({buyingUsdPower.toFixed()} USD)</ListGroup.Item>
            <ListGroup.Item>BNB balance: {bnbBalance}</ListGroup.Item>
            </ListGroup>
            <p>User Order Info:</p>
            <ListGroup variant='flush'>
              <ListGroup.Item>Time: {secsToDateTime(parseFloat(userOrder.timeCreation.seconds))}</ListGroup.Item>
              <ListGroup.Item style={globalStatusStyle}>Status: {userOrder.status}</ListGroup.Item>
              <Card onClick={() => copyInfo('Order ID', userOrder.orderId)} style={{cursor: 'pointer'}}>
                {notice && <Alert variant='primary'>{notice}</Alert>}
                <ListGroup.Item>Order: {userOrder.orderId}</ListGroup.Item>
              </Card>
              {/* <Card onClick={() => copyInfo('User ID', userOrder.userId)} style={{cursor: 'pointer'}}>
                {notice && <Alert variant='primary'>{notice}</Alert>}
                <ListGroup.Item>User ID: {userOrder.userId}</ListGroup.Item>
              </Card> */}
              <ListGroup.Item>User name: {userOrder.personalInfo.name}</ListGroup.Item>
              <ListGroup.Item>User Email: {userOrder.personalInfo.email}</ListGroup.Item>
              <ListGroup.Item>User CPF: {formatCpf(userOrder.personalInfo.cpf)}</ListGroup.Item>
              <Card onClick={() => copyInfo('Investment Value', userOrder.intendedInvestedValue)} style={{cursor: 'pointer'}}>
                {notice && <Alert variant='primary'>{notice}</Alert>}
                <ListGroup.Item>Investment Value: {userOrder.intendedInvestedValue} {userOrder.fiatSymbol}</ListGroup.Item>
              </Card>
              <ListGroup.Item>Basket: {userOrder.basketRefId}</ListGroup.Item>
              <ListGroup.Item>Hero: {userOrder.heroId}</ListGroup.Item>
              <Card onClick={() => copyInfo('User ID', userOrder.userId)} style={{cursor: 'pointer'}}>
                {notice && <Alert variant='primary'>{notice}</Alert>}
                <ListGroup.Item>User ID: {userOrder.userId}</ListGroup.Item>
              </Card>
            </ListGroup>
            {/* <p>Coins List:</p>
            {coins} */}
          </Card>
          <br></br>
          {/* <Form>
            <Form.Check
              type={'checkbox'}
              id={`default-${'checkbox'}`}
              label={`PIX of ${
                formatPrice(parseFloat(userOrder.intendedInvestedValue) + parseFloat(userOrder.fee), userOrder.fiatSymbol)
              } confirmed`}
              onChange={handleValueChange}
              style={{cursor: 'pointer'}}
            />
            <br></br>
            {error && <Alert variant='danger'>{error}</Alert>}
            {orderMessage && <Alert variant='success'>{orderMessage}</Alert>}
            {waitMessage && <Alert variant='primary'>{waitMessage}</Alert>}
            {buyingPowerAlert && <Alert variant='danger'>{buyingPowerAlert}</Alert>}
            <Button variant='success' disabled={disabledButton || loading || finalizedBuy || !permitted} className='w-100' onClick={confirmBuy}>
              Buy
            </Button>
            <br></br>
            <br></br>
            <Form.Check
              type={'checkbox'}
              id={`default-${'checkbox'}`}
              label={`Certeza que deseja cancelar?`}
              onChange={handleCancelConfirmation}
              style={{cursor: 'pointer'}}
            />
            <br></br>
            <Button variant='danger' disabled={disabledCancelButton || loading || !permitted} className='w-100' onClick={cancelOrder}>
              Cancel
            </Button>
          </Form> */}
        </Card.Body>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Link to='/users-buy-orders'>Back to Orders List</Link>
        <br></br>
        <br></br>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}