import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { appV2Db, collection, doc, getDocs, query, updateDoc } from '../firebase'

import { formatPercentage } from '@mauchero/helpers'
import { clog } from '../auxiliary/helpers'

export default function UpdateTokensValue() {

  const MAX_PERCENTAGE_VARIATION = 0.01
  
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(true)
  const [usersOrders, setUsersOrders] = useState([])
  const [successMessage, setSuccessMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [error, setError] = useState('')
  const [tokensToSell, setTokensToSell] = useState([])
  const [tokensToEdit, setTokensToEdit] = useState([])
  const [inputValues, setInputValues] = useState([])
  const [messageIndex, setMessageIndex] = useState(null)
  const [variationMessage, setVariationMessage] = useState('')

  const fetchTokens = async () => {
    
    let ordersTotal = 0
      setTokensToEdit([])
      setUsersOrders([])
      setTokensToSell([])
      setSuccessMessage('')
      setError('')
      setWaitMessage('Aguarde...')
      clog(`BUSCANDO ORDENS...`)

      const tokensRef = collection(appV2Db, 'tokens')
      const tokensQuery = query(tokensRef)
      const tokensSnapshot = await getDocs(tokensQuery)
      if(!tokensSnapshot.empty) {
        tokensSnapshot.forEach((doc) => {
          const token = doc.data();
          token.tokenId = doc.id;
          if(token.status !== 'EXCLUDED' && token.status !== 'EXPIRED'){
            setTokensToEdit(item => [...item, token])
          }
        })
      }
    
      setReady(prev => !prev)
      setWaitMessage('')
  }

  useEffect(() => {
    if(usersOrders.length > 0) {
      setLoading(false)
    }
  }, [ready])

  const updateTokenValue = async (tokenId, newValue, index) => {
    setMessageIndex(index)
    setError('')
    setSuccessMessage('')
    setWaitMessage('Aguarde...')
    const floatNewValue = parseFloat(newValue);
    const tokenRef = doc(appV2Db, `tokens/${tokenId}`)
    const oldPrice = tokensToEdit[index].currentUnitValue;

    try{
      await updateDoc(tokenRef, {
        currentUnitValue: floatNewValue
      })
      const editedTokens = tokensToEdit.map((token) => {
        if(token.tokenId === tokenId){
          token.currentUnitValue = floatNewValue;
        }
        return token;
      })
      setTokensToEdit(editedTokens)
      setWaitMessage('')
      setSuccessMessage('Token atualizado com sucesso!')
    } catch(error) {
      console.error('>> error')
      console.error(JSON.stringify(error))
      setWaitMessage('')
      setError('Erro: tente novamente. Caso o erro persista, entre em contato.')
    }

    const currentInputList = [...inputValues];
    currentInputList[index] = '';
    setInputValues(currentInputList);

  }

  const handleInputChange = (e, index) => {
    e.preventDefault()
    setMessageIndex(index)
    setError('')
    setVariationMessage('')
    const oldPrice = tokensToEdit[index].currentUnitValue;
    const value = e.target.value;
    const variation = (value - oldPrice) / oldPrice;
    clog(`value = ${value}`)
    clog(`oldPrice = ${oldPrice}`)
    clog(`variation = ${variation}`)
    if(value) {
      setVariationMessage(`Variação relativa: ${formatPercentage(variation)}`)
    }
    if(value && Math.abs(variation) > MAX_PERCENTAGE_VARIATION) {
      setError(`Variação maior que ${formatPercentage(MAX_PERCENTAGE_VARIATION)} CUIDADO! | variação: ${formatPercentage(variation)}`)
    }

    const list = [...inputValues];
    list[index] = value;
    setInputValues(list);
  }

  const handleDeleteToken = async (tokenId) => {
    const result = window.confirm('O token será excluido, deseja confirmar?');

    if (result) {
      const tokenRef = doc(appV2Db, `tokens/${tokenId}`)
      try {
        await updateDoc(tokenRef, {
          status: 'EXCLUDED'
        })
        const newTokensToEdit = tokensToEdit.filter((token) => {
          return token.tokenId !== tokenId;
        })
        setTokensToEdit(newTokensToEdit)
      } catch (error) {
        console.error('>> error')
        console.error(JSON.stringify(error))
        setWaitMessage('')
        setError('Erro: tente novamente. Caso o erro persista, entre em contato.')
      }
      alert('Token excluido!');
    } else {
      // User clicked "Cancel" or closed the dialog
      alert('Exclusão cancelada.');
    }
  };



  
  const tokens = tokensToEdit.map((token, index) => {
    if(token.status === 'EXCLUDED' || token.status === 'EXPIRED') {
      return null;
    }
    return (
    <div>
      <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '6%', alignItems: 'center'}}>
        <p style={{fontWeight: 'bold'}}>{token.tokenId}</p>
        <p>R$ {token.currentUnitValue}</p>
        <input type="number" value={inputValues[index]} placeholder="Novo valor" onChange={(e) => {handleInputChange(e, index)} }></input>
        <Button disabled={!inputValues[index]} onClick={() => {updateTokenValue(token.tokenId, inputValues[index], index)}}>Editar valor</Button>
        <Button variant='danger' onClick ={() => handleDeleteToken(token.tokenId)}>Excluir Token</Button>
      </div>
      {messageIndex === index &&
        <>
        {variationMessage && <Alert variant='warning'>{variationMessage}</Alert>}
        {successMessage && <Alert variant='success'>{successMessage}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        </>
      }
      <div style={{height: 1, backgroundColor: '#969696', width: '100%', marginTop: '3%', marginBottom: '3%'}}></div>
    </div>
    )
  })

  return (
    <>
      <Card>
      <br></br>
      <br></br>
      <br></br>
        {waitMessage && <Alert variant='warning'>{waitMessage}</Alert>}
        <br></br>
        <br></br>
        <Button variant='primary' className='w-100' onClick={fetchTokens}>
          Buscar Tokens
        </Button>
        <Card.Body>
          <h2 className='text-center mb-4'>Alterar Valor Unitário de Tokens</h2>
          <br></br>
          <br></br>
          <Card style={{ width: '40rem' }}>
            <p>Tokens:</p>
            <ListGroup variant='flush'>
            {tokens}
            </ListGroup>
          </Card>
        
          <br></br>
        </Card.Body>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Link to='/dashboardappv2'>Voltar</Link>
      </div>
    </>
  )
}