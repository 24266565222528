import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { secsToDateTime, formatPrice, formatCpf } from '../auxiliary/helpers'

export default function OrderBuy(props) {

  const amountToPay = props.order.productId ?
    formatPrice(props.order.productValue) :
    formatPrice(parseFloat(props.order.intendedInvestedValue) + parseFloat(props.order.fee), props.order.fiatSymbol)

  const productId = props.order.productId ? props.order.productId : props.order.basketRefId

  const orderStyle = {
    cursor: 'pointer',
    color: 'blue'
  }

  const pathname = props.order.orderSide === 'BUY' ? '/exchange' :
    (props.order.orderSide === 'SELL' ? 'exchange-sell' : 'buy-product')

  return (
    <ListGroup variant='flush'>
      <ListGroup.Item style={orderStyle}>
        <Link to={{
          pathname: pathname,
          state: {
            buyingPower: props.buyingPower,
            buyingUsdPower: props.buyingUsdPower,
            bnbBalance: props.bnbBalance,
            userOrder: props.order
          }
          }}>
          Time: {secsToDateTime(parseFloat(props.order.timeCreation.seconds))}
          <br></br>
          OrderId: {props.orderId}
          <br></br>
          Type: {props.order.type}
          <br></br>
          Name: {props.order.personalInfo.name}
          <br></br>
          Email: {props.order.personalInfo.email}
          <br></br>
          CPF: {formatCpf(props.order.personalInfo.cpf)}
          <br></br>
          Product: {productId}
          <br></br>
          Hero: {props.order.heroId}
          <br></br>
          Amount to Receive: {amountToPay}
        </Link>
        </ListGroup.Item>
    </ListGroup>
  )
}