import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { secsToDateTime, formatPrice, formatPhone } from '../auxiliary/helpers'

export default function OrderSell(props) {

  const amountToPay = formatPrice(parseFloat(props.order.totalToReceive), props.order.fiatSymbol)
  const feePayed = formatPrice(parseFloat(props.order.fee), props.order.fiatSymbol)
  const totalAmount = formatPrice(parseFloat(props.order.totalToReceive) + parseFloat(props.order.fee), props.order.fiatSymbol)

  const orderStyle = {
    cursor: 'pointer',
    color: 'blue'
  }

  const pathname = props.order.orderSide === 'BUY' ? '/exchange' : 'exchange-sell'

  return (
    <ListGroup variant='flush'>
      <ListGroup.Item style={orderStyle}>
        <Link to={{
          pathname: pathname,
          state: {
            buyingPower: props.buyingPower,
            bnbBalance: props.bnbBalance,
            userOrder: props.order
          }
          }}>
          Time: {secsToDateTime(parseFloat(props.order.timeCreation.seconds))}
          <br></br>
          Name: {props.order.personalInfo.name}
          <br></br>
          Email: {props.order.personalInfo.email}
          <br></br>
          Phone: {'phone' in props.order.personalInfo ? formatPhone(props.order.personalInfo.phone) : ''}
          <br></br>
          PIX: {props.order.personalInfo.pixCode}
          <br></br>
          Basket: {props.order.basketRefId}
          <br></br>
          Hero: {props.order.heroId}
          <br></br>
          Fee payed: {feePayed}
          <br></br>
          Amount to Pay: {amountToPay}
          <br></br>
          Total Amount (fee + amount): {totalAmount}
        </Link>
        </ListGroup.Item>
    </ListGroup>
  )
}