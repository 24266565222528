import React, { useEffect, useState } from "react"
import { Alert, Button, Card } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"

import params from '../constants/params.json'
import { useAuth } from "../contexts/AuthContext"
import allCoinsData from '../data/allCoins.json'
import allTokens from '../data/allTokens.json'
import { appV2Db, doc, getDoc, setDoc } from "../firebase"

import { callUrlCloudFunction, clog, confirmOrderPayed } from "../auxiliary/helpers"

export default function DashboardAppV2() {

  const [error, setError] = useState("")
  const [dataToWrite, setDataToWrite] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [permitted, setPermitted] = useState(false)
  const [onlyLocalhost, setOnlyLocalhost] = useState(false)

  const { currentAppV2User, logout, currentAdminUser } = useAuth()
  const history = useHistory()

  useEffect(() => {
    const PERMMITED_UIDS = ['tsM6UtAGmAcx0YHI1a2rh0BpkOB2', '1zIQEAkqY2hurJCR4KplSLEqrmp1']
    const url = new URL(window.location.href)
    const hostname = url.hostname
    if(PERMMITED_UIDS.includes(currentAdminUser.uid)) {
      setPermitted(true)
    }
    if(hostname === 'localhost') {
      setOnlyLocalhost(true)
    }
  }, [])

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  const updateFrequentQuestions = async () => {
    const questions = require(`../data/frequent-questions.json`)

    clog(JSON.stringify(questions, null, 2))
    
    await setDoc(doc(appV2Db, `/appData/frequentAskedQuestions`), {questions: questions})

  }


  //ATENTION: this function get all the coins from the data json "allCoins" and add it to the firebase collection cryptocurrencies
  const updateAllCoinsToFirebase = async() => {
    // const collectionRef = collection(appV2Db, 'cryptocurrencies')
  //   const coins = await getDocs(collectionRef)
  //   coins.forEach(async coin => {
  //     const data = coin.data();
  //     const symbol = data.symbol;
  //     const updatedCoin = {...data}
  //     await updateDoc(doc(appV2Db, `cryptocurrencies/${symbol}`), updatedCoin)
  //     clog(`${symbol} updated`)
  //   })

  clog(allCoinsData)
    for (const coin of allCoinsData) {
      try {
        await setDoc(doc(appV2Db, `cryptocurrencies/${coin.symbol}`), {...coin, type: 'cryptocurrency'})
        clog(`Document written with ID: ${coin.symbol}`)
      }
      catch(error) {
        clog(`Error creating Firestore document for ${coin.symbol}:`)
        clog(error)
      }
    }
  }

  const updateAllTokensToFirebase = async() => {
  clog(allTokens)
    for (const token of allTokens) {
      try {
        await setDoc(doc(appV2Db, `tokens/${token.symbol}`), {...token, type: 'token'})
        clog(`Document written with ID: ${token.symbol}`)
      }
      catch(error) {
        clog(`Error creating Firestore document for ${token.symbol}:`)
        clog(error)
      }
    }
  }

  const doSomething = async () => {
    // const orderId = 'pPD9mFwNEZcHJdRbiZEs'
    const orderId = 'A3Vqx4MPfi2LckLkNW7K'
    const docRef = doc(appV2Db, `/ordersBuy/${orderId}`)
    const docSnap = await getDoc(docRef)
    const orderData = docSnap.data()
    
    const basketInfo = orderData.basketInfo
    const fiatPrice = orderData.finalBuyBasketResponse.fiatPrice
    const buyBasketResponsesList = orderData.finalBuyBasketResponse.buyBasketResponsesList
    const basketResponse = buyBasketResponsesList.map(e => 
      ({
        averagePrice: e.averagePrice,
        userExecutedBaseQty: e.userExecutedBaseQty,
        symbol: e.symbol,
      }))
    clog(JSON.stringify(basketInfo, null, 2))
    clog(JSON.stringify(basketResponse, null, 2))
    clog(`fiatPrice = ${fiatPrice}`)
  }

  const getTotalValueFulfilled = (orderBuy) => {
    try {
      const isTokensBasket = orderBuy.basketInfo.some(
        element => element.assetType === 'token',
      );
      if (isTokensBasket) {
        return orderBuy.investedValue;
      }
      if (orderBuy?.finalBuyBasketResponse?.buyBasketResponsesList) {
        let totalFulfilled = 0;
        const fiatFulfillmentValue = orderBuy.finalBuyBasketResponse?.fiatPrice;
        for (const fulfilledCoin of orderBuy?.finalBuyBasketResponse
          ?.buyBasketResponsesList) {
          const userExecutedBaseQty = fulfilledCoin.userExecutedBaseQty;
          const averagePrice = fulfilledCoin.averagePrice;
          const partialValue = userExecutedBaseQty * averagePrice;
          totalFulfilled += partialValue;
        }
        return totalFulfilled * fiatFulfillmentValue;
      }
      return orderBuy.investedValue;
    } catch (error) {
      console.log('>>> error getTotalValueFulfilled <<<');
      console.log(error);
      return orderBuy.investedValue;
    }
  };

  const intToFloat = (intValue) => {
    const decimalPart = intValue % 100; // Extract the last two digits
    const integerPart = (intValue - decimalPart) / 100; // Extract the integer part
  
    const floatValue = parseFloat(
      integerPart + '.' + decimalPart.toString().padStart(2, '0'),
    );
  
    return floatValue;
  };


  const confirmOrderTest = async () => {
    const orderId = 'RrrdCptLRFqr3ODEKYrR'
    const eventData = await getBrlaEventData(orderId, currentAppV2User)
    clog('>>> event')
    clog(eventData)
    const cameFromUserBalance = false

    const amount = intToFloat(eventData.amount)
    const depositId = eventData.id
    const taxId = eventData.taxId
    const discountedFee = intToFloat(eventData.discountedFee)

    clog(`amount = ${amount}`)
    clog(`depositId = ${depositId}`)
    clog(`taxId = ${taxId}`)
    clog(`discountedFee = ${discountedFee}`)
    
    const resp = await confirmOrderPayed(
      currentAppV2User,
      orderId,
      amount,
      depositId,
      taxId,
      discountedFee,
      cameFromUserBalance
    )
    clog('>> resp')
    clog(JSON.stringify(resp))
  }

  const getBrlaEventData = async (orderId, currentAppV2User) => {
    const baseUrl = params.isProduction ?
    'https://southamerica-east1-heroinvest-5fabd.cloudfunctions.net' :
    'https://southamerica-east1-ch-app-v2-dev.cloudfunctions.net'
    const cloudFunction = 'getBrlaMintEventsData'

    let page = 1
    while(true) {
      clog(`page = ${page}`)
      const queryData = {
        requestUserId: currentAppV2User.uid,
        page: page
      }
      const response = await callUrlCloudFunction(
        baseUrl,
        cloudFunction,
        queryData,
      )
      const events = response.response.events
      const event = events.find(event => event.data?.referenceLabel === orderId)
      clog(`events.length = ${events.length}`)
      if(event) {
        return event.data
      }
      if(!response || events.length === 0) {
        return []
      }
      page++
    }
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Choose an option</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          You're logged in as: <strong>{currentAppV2User?.email}</strong>
          <br></br>
          You are connected to firebase: <strong>{params.isProduction ? 'PRODUCTION' : 'DEVELOPMENT'}</strong>
          <Link to="/basket-creation" className="btn btn-outline-primary w-100 mt-3">
            Create Basket
          </Link>
          <Link to="/basket-creation-tokens" className="btn btn-outline-primary w-100 mt-3">
            Create Basket (Tokens)
          </Link>
          <Link to="/add-new-user" className="btn btn-outline-primary w-100 mt-3">
            Add New User To Advisor
          </Link>
          <Link to="/set-user-basket" className="btn btn-outline-primary w-100 mt-3">
            Set User Basket
          </Link>
          <Link to="/users-tokens-buy-orders" className="btn btn-outline-primary w-100 mt-3">
            Users Tokens Buy Orders
          </Link>
          <Link to="/users-tokens-sell-orders" className="btn btn-outline-primary w-100 mt-3">
            Users Tokens Sell Orders
          </Link>
          <Link to="/update-tokens-value" className="btn btn-outline-primary w-100 mt-3">
            Update Tokens Value
          </Link>
          <Link to="/manage-app-data" className="btn btn-outline-primary w-100 mt-3">
            Manage App Data
          </Link>
          <Link to="/customer-reports-appv2" className="btn btn-outline-primary w-100 mt-3">
            Customer Reports App V2
          </Link>
          <Link to="/analytics-appv2" className="btn btn-outline-primary w-100 mt-3">
            Analytics App V2
          </Link>

          {(permitted && onlyLocalhost) && <>
            <Button className='mt-4 w-100'  onClick={updateAllCoinsToFirebase} disabled={true}> {/* BE CAREFUL WHEN USING THIS, CHECK ALL THE INFO BEFORE. */}
            Update all coins to firebase
          </Button>
          <Button className='mt-4 w-100'  onClick={updateAllCoinsToFirebase} disabled={true}> {/* BE CAREFUL WHEN USING THIS, CHECK ALL THE INFO BEFORE. */}
            Update all coins to firebase
          </Button>
          <Button className='mt-4 w-100'  onClick={updateAllTokensToFirebase} disabled={true}> {/* BE CAREFUL WHEN USING THIS, CHECK ALL THE INFO BEFORE. */}
            Update all tokens to firebase
          </Button>
          <Button className='mt-4 w-100'  onClick={updateFrequentQuestions} disabled={true}> {/* BE CAREFUL WHEN USING THIS, CHECK ALL THE INFO BEFORE. */}
            Update Frequent Questions
          </Button>
          <Button className='mt-4 w-100'  onClick={doSomething} disabled={true}> {/* BE CAREFUL WHEN USING THIS, CHECK ALL THE INFO BEFORE. */}
            Do Something
          </Button>
          <Button className='mt-4 w-100'  onClick={confirmOrderTest} disabled={true}> {/* BE CAREFUL WHEN USING THIS, CHECK ALL THE INFO BEFORE. */}
          confirmOrderTest TEST
          </Button>
          
          </>}
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/">Voltar</Link>
      </div>
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </>
  )
}