import React, { useEffect, useState, useRef } from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore'
import { clog, float2INFormat, toDateTime, toTimestamp } from '../auxiliary/helpers'
import { appDb } from '../firebase'
import { Link } from 'react-router-dom'

const CRLF = '\r\n'
const QUOTE_ASSET_SYMBOL = 'USDT'


const OPERACAO_VALOR_DECIMALS = 2
const OPERACAO_TAXAS_VALOR_DECIMALS = 2
const CRIPTOATIVO_QUANTIDADE_DECIMALS = 10
const COMPRADOR_TIPO_NI = '1'
const COMPRADOR_PAIS = 'BR'


const usersRemoved = [
  'sKTnnJRneWWnXZLrCjZvt9zOYZQ2',   // Retirando MRC1
  'YETftQD0Y5goflAqDPAixTY3O2h2',   // Retirando MRC2
  'fv0y4cafUfg73YRT5P7Ye6tpuyj1',   // Retirando MRC3
  'ArNikjTrRuNtLXyqkunlu7MmJBZ2',   // Retirando FND1
  'g5duVw1idLVSCUdF6fDXnjGcPZ13',   // Retirando FND2
  'Hc73reLZRgNDqd7h7DJlrjVGG4i1',   // Retirando STE1
  'mdgT7tTVoJag1Zqt4Eh4kkN3gWX2',   // Retirando STE2
]

export default function LegalReports() {

  const inititalDateRef = useRef('')
  const finalDateRef = useRef('')
  
  const [fileInfo, setFileInfo] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [error, setError] = useState('')
  const [filename, setFilename] = useState('')

  const downloadTxtFile = () => {
    const element = document.createElement('a')
    const file = new Blob([fileInfo], {type: 'text/plain;charset=utf-8'})
    element.href = URL.createObjectURL(file)
    element.download = `${filename}.txt`
    document.body.appendChild(element)
    element.click()
  }

  const fetchIN1888ReportData = async () => {

    try {
      setFilename('in1888')
      setError('')
      setSuccessMessage('')
      setWaitMessage('Wait for it...')
      if(!inititalDateRef.current.value || !finalDateRef.current.value) {
        setError('Insira as datas')
        return
      }
      const inititalDate = toTimestamp(inititalDateRef.current.value)
      const finalDate = toTimestamp(finalDateRef.current.value) + 24 * 60 * 60

      clog(`inititalDate = ${inititalDate}`)
      clog(`finalDate = ${finalDate}`)

      const openFileRecordType = '0000'
      const exchangeCnpj = '48591593000134'
      const exchangeName = 'CRYPTO HERO SERVICOS DIGITAIS LTDA'
      const ExchangeURL = 'https://www.cryptohero.com.br/'

      let in188Info = `${openFileRecordType}|${exchangeCnpj}|${exchangeName}|${ExchangeURL}${CRLF}`


      const ordersRef = collection(appDb, 'orders')

      const q1 = query(ordersRef,
        where('orderSide', '==', 'BUY'),
        where('status', '==', 'FULFILLED')
      )
      const buyOrdersQuerySnap = await getDocs(q1)
      let userOrdersList = []
      if(!buyOrdersQuerySnap.empty) {
        buyOrdersQuerySnap.forEach(doc => {
          const order = doc.data()
          order.orderId = doc.id
          if(inititalDate <= order.timeUpdate.seconds && order.timeUpdate.seconds < finalDate && !usersRemoved.includes(order.userId)) {
            userOrdersList.push(order)
          }
        })
        // clog(`userOrdersList TAM: ${userOrdersList.length}`)
        // clog(JSON.stringify(userOrdersList, null, 2))
      }

      const buySellRecordType = '0110'
      for(let i in userOrdersList) {
        const order = userOrdersList[i]
        const orderId = order.orderId

        const operacaoData = formatDate(order.timeUpdate.seconds, orderId)
        
        const operacaoCodigo = 'Ⅰ'  // pode ser que queiram que seja 'I'
        
        const usdtBrl = order.usdtBrl
        const fee = order.fee
        
        for(let i in order.coins) {
          const operacaoID = orderId + `-${i}`
          const coinInfo = order.coins[i]
          const percentage = coinInfo.percentage
          let buyPrice = 0
          let fulfilledAmount = 0

          for(let j in order.fulfilledCoins) {
            const fulfilledCoinInfo = order.fulfilledCoins[j]
            if(coinInfo.symbol + QUOTE_ASSET_SYMBOL === fulfilledCoinInfo.symbol) {
              buyPrice = fulfilledCoinInfo.buyPrice
              fulfilledAmount = fulfilledCoinInfo.fulfilledAmount
            }
          }
          const operacaoValor = float2INFormat(buyPrice * fulfilledAmount * usdtBrl, OPERACAO_VALOR_DECIMALS)
          const operacaoTaxasValor = float2INFormat(fee * percentage, OPERACAO_TAXAS_VALOR_DECIMALS)
          const criptoativoSimbolo = coinInfo.symbol
          const criptoativoQuantidade = float2INFormat(fulfilledAmount, CRIPTOATIVO_QUANTIDADE_DECIMALS)

          const compradorTipoNI = COMPRADOR_TIPO_NI
          const compradorPais = COMPRADOR_PAIS
          const compradorCPFCNPJ = order.personalInfo.cpf
          const compradorNI = ''
          const compradorNome = order.personalInfo.name
          const compradorEndereco = 'XXXXXXXXXXXXXXXXXXXXXXXXXXXX'

          const vendedorTipoNI = 'xx'
          const vendedorPais = 'xx'
          const vendedorCPFCNPJ = 'xx'
          const vendedorNI = 'xx'
          const vendedorNome = 'xx'
          const vendedorEndereco = 'xx'

          in188Info += `${buySellRecordType}|`
          in188Info += `${operacaoData}|`
          in188Info += `${operacaoID}|`
          in188Info += `${operacaoCodigo}|`
          in188Info += `${operacaoValor}|`
          in188Info += `${operacaoTaxasValor}|`
          in188Info += `${criptoativoSimbolo}|`
          in188Info += `${criptoativoQuantidade}|`
          in188Info += `${compradorTipoNI}|`
          in188Info += `${compradorPais}|`
          in188Info += `${compradorCPFCNPJ}|`
          in188Info += `${compradorNI}|`
          in188Info += `${compradorNome}|`
          in188Info += `${compradorEndereco}|`
          in188Info += `${vendedorTipoNI}|`
          in188Info += `${vendedorPais}|`
          in188Info += `${vendedorCPFCNPJ}|`
          in188Info += `${vendedorNI}|`
          in188Info += `${vendedorNome}|`
          in188Info += `${vendedorEndereco}`
          in188Info += `${CRLF}`
        }

      }

      clog(in188Info)


      setFileInfo(in188Info)
      setWaitMessage('')
      setSuccessMessage('Sell info ready to download')
    }
    catch(error) {
      clog(error)
      setError(`error - It was not possible to fetch data | error: ${error}`)
    }
  }

  const formatDate = (secs, orderId) => {
    const dateTime = toDateTime(secs) // 6/19/2023, 2:31:01 PM
    const date = dateTime.split(',')[0]
    const mm = date.split('/')[0].length > 1 ? date.split('/')[0] : '0' + date.split('/')[0]
    const dd = date.split('/')[1].length > 1 ? date.split('/')[1] : '0' + date.split('/')[1]
    const aaaa = date.split('/')[2]
    checkDate(dd, mm, aaaa, orderId)
    return `${dd}${mm}${aaaa}`
  }

  const checkDate = (dd, mm, aaaa, orderId) => {
    if(parseFloat(dd) < 1 || parseFloat(dd) > 31 ||
       parseFloat(mm) < 1 || parseFloat(mm) > 12 ||
       parseFloat(aaaa) < 2022) {
        setError(`PROBLEM WITH ORDER DATE: ORDER ID: ${orderId}`)
    }
  }


  return (
    <>
      <Form>
        <Form.Group id='inititalDate' style={{justifyContent: 'center'}}>
          <Form.Label>Data inicial (a partir de 00:00:00)</Form.Label>
          <Form.Control
            type='date'
            ref={inititalDateRef}
            required
            autoFocus
            style={{width: '30%'}}
          />
        </Form.Group>
        <Form.Group id='finalDate'>
          <Form.Label>Data final (até 23:59:59)</Form.Label>
          <Form.Control
            type='date'
            ref={finalDateRef}
            required
            style={{width: '30%'}}
          />
        </Form.Group>
        {waitMessage && <Alert variant='warning'>{waitMessage}</Alert>}
        {successMessage && <Alert variant='success'>{successMessage}</Alert>}
        {error && <Alert variant='danger'>{error}</Alert>}
        <Button variant='primary' className='w-100'onClick={fetchIN1888ReportData}>
          Gerar RELATÓRIO IN 1.888/2019
        </Button>
      <br></br>
      <br></br>
      <Button onClick={downloadTxtFile}>Download</Button>
      </Form>
      <div className='w-100 text-center mt-2'>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}