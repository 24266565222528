import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore"
import React, { useRef, useState } from "react"
import { Alert, Button, Form } from "react-bootstrap"
import { CSVLink } from "react-csv"
import { Link } from 'react-router-dom'
import { clog, convertTimestampToBrazil, formatCpf, formatPhone, reorderList, toDateTimeWithoutTime, toTimestamp } from "../auxiliary/helpers"
import { appV2Db } from "../firebase"
import { getPairSymbolPrice } from "../auxiliary/broker"

import { useAuth } from "../contexts/AuthContext"



export default function AnalyticsV2() {

  const [dataToWrite, setDataToWrite] = useState('')
  const inititalDateRef = useRef('')
  const finalDateRef = useRef('')

  const [successMessage, setSuccessMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [error, setError] = useState('')
  const [filename, setFilename] = useState('')

  const { currentAdminUser } = useAuth()


  const pathToTitle = {
    'store': 'Loja',
    'basket-coins': 'Composição Carteira',
    'signup': 'Cadastro',
    'login': 'Login',
    'logout': 'Logout',
    'forgot-password': 'Redefinir Senha',
    'ebook': 'Ebook',
    'purchase-data': 'Dados para Compra',
    'finish-basket-buy': 'Resumo da Compra',
    'success': 'Solicitação Feita da Compra',
    'user-orders': 'Minhas Ordens',
    'user-baskets': 'Minhas Cestas',
    'sell-basket': 'Vender Cesta',
    'contact-us': 'Fale Conosco',
    'desktop': 'Desktop',
    'all-heroes': 'Escolha seu Hero',
    'home': 'Minhas Cestas',
  }

  const filteredIds = ['pG8d0Ykim0WKbefRD3PMG7qyuI33', 'tNCWR5WLZxOvPN8ZF3LRcPc8pZE2', 'r7wQ62iBZUd7jJ4pZR40Drh1PtF3', 'oP6MAOlMeSW2tJtojJQ750vRtoR2', 'PLri7WNPhmMZeMSVRh4QzJXsomM2', 't0wrt3l7GsSesrYUuVQQvf2JtlA3', 'vBOzUo9ZoEXqwTE8MqGQSZwx9bj2', 'rIpFbEs4OiXD9RvHsdYbQLhaUTf1', 'IRV2Wj9j9ybe887dYmBmlSJlHml2']

  const symbolPrices = {
    current: {
    }
  }
  const getPairPrice = async (pairSymbol, timePriceReference) => {
    if(pairSymbol in symbolPrices.current) {
      return symbolPrices.current[pairSymbol]
    }
    const pairPrice = await getPairSymbolPrice(currentAdminUser, pairSymbol, timePriceReference)
    const prevSymbolPrices = symbolPrices.current
    symbolPrices.current = {
      ...prevSymbolPrices,
      [pairSymbol]: pairPrice
    }
    return pairPrice
  }


  const fetchEmailsList = async () => {
    setFilename('emailsList')
    const usersRef = collection(appV2Db, 'users')
    const usersQuerySnap = await getDocs(query(usersRef))
    let csvData = []
    usersQuerySnap.forEach(doc => {
      const userData = doc.data()
      const newLine = [userData.personalInfo.email, userData.personalInfo.name]
      csvData.push(newLine)
    })
    setDataToWrite(csvData)
    setSuccessMessage('EMAIL data ready to download')
  }

  const fetchAccountReportDataV2 = async () => {
    try {
      setFilename('vendas')
      setError('')
      setSuccessMessage('')
      setWaitMessage('Wait for it...')
      if(!inititalDateRef.current.value || !finalDateRef.current.value) {
        setError('Insira as datas')
        return
      }
      const inititalDate = toTimestamp(inititalDateRef.current.value)
      const finalDate = toTimestamp(finalDateRef.current.value) + 24 * 60 * 60

      clog(`inititalDate = ${inititalDate}`)
      clog(`finalDate = ${finalDate}`)

      const sellOrdersRef = collection(appV2Db, 'ordersSell')
      const buyOrdersRef = collection(appV2Db, 'ordersBuy')

      const q1 = query(sellOrdersRef,
        where('orderSide', '==', 'SELL'),
        where('status', '==', 'FULFILLED'),
        where('userId', '!=', 'YETftQD0Y5goflAqDPAixTY3O2h2'),    // Retirando MRC
        where('pixStatus', '==', 'PAYED'))

      const sellOrdersQuerySnap = await getDocs(q1)
      
      let csvData = [
        ['Tempo (seg)', 'Nome', 'CPF', 'Data de Venda', 'Valor de Venda (enviado para o cliente)', 'ID Venda', 'Dados de Compra (data | valor | ID)'],
      ]
      
      let sellOrderDataList = []
      sellOrdersQuerySnap.forEach(doc => {
        const sellOrderData = doc.data()
        if(inititalDate <= sellOrderData.timeStatusFulfilled && sellOrderData.timeStatusFulfilled < finalDate) {
          sellOrderDataList.push({...sellOrderData, orderId: doc.id})
        }
      })

      for(let i in sellOrderDataList) {
        const sellOrderData = sellOrderDataList[i]

        const timeUpdate = sellOrderData.timeStatusFulfilled
        const name = sellOrderData.userInfo.name
        let cpf = sellOrderData.userInfo.cpf
        if(!cpf) {
          const userDocRef = doc(appV2Db, `users/${sellOrderData.userId}/nonEditableInformation/nonEditableInformation`)
          const userDocSnap = await getDoc(userDocRef)
          cpf = userDocSnap.data().registrationInfo.cpf
        }
        cpf = cpf ? formatCpf(cpf) : ''
        const sellFulfilledDate = toDateTimeWithoutTime(sellOrderData.timeStatusFulfilled)
        const totalToReceive = sellOrderData.totalToReceive
        const fee = sellOrderData.fee
        const sellId = sellOrderData.orderId

        const q2 = query(buyOrdersRef,
          where('orderSide', '==', 'BUY'),
          where('status', '==', 'FULFILLED'),
          where('userId', '==', sellOrderData.userId))
  
        let buyOrderDataList = []
        const buyOrdersQuerySnap = await getDocs(q2)
        buyOrdersQuerySnap.forEach(doc => {
          const buyOrderData = doc.data()
          buyOrderDataList.push({...buyOrderData, orderId: doc.id})
        })
        const newBuyOrderDataList = reorderList(buyOrderDataList, 'timeStatusFulfilled')

        let totalInvested = 0
        let foundAllOrders = false
        let correspondingBuyOrdersDataList = []
        // Iniciando pela ordem mais recente
        for(let i = newBuyOrderDataList.length - 1; i >= 0; i--) {
          const buyOrderData = newBuyOrderDataList[i]
          // Se o usuário comprar novamente após ter feito a venda -> desconsiderar essa ordem de compra
          if(buyOrderData.timeStatusFulfilled >= sellOrderData.timeStatusFulfilled) {
            continue
          }
          else {
            totalInvested += buyOrderData.investedValue
            correspondingBuyOrdersDataList.push(toDateTimeWithoutTime(buyOrderData.timeStatusFulfilled))
            correspondingBuyOrdersDataList.push(buyOrderData.investedValue)
            correspondingBuyOrdersDataList.push(buyOrderData.orderId)
            if(totalInvested >= sellOrderData.totalToReceive) { // >= porque pode ter uma ordem de compra com valor maior que o da venda
              foundAllOrders = true
              break
            }
          }
        }
        if(!foundAllOrders) {
          setError('ERRO: ordens de compra não batendo. Reportar ao DEV que fez merda!')
          clog('sellId')
          clog(sellId)
          clog('newBuyOrderDataList')
          clog(JSON.stringify(newBuyOrderDataList, null, 2))
          return
        }

        const newLine = [timeUpdate, name, cpf, sellFulfilledDate, totalToReceive, sellId, ...correspondingBuyOrdersDataList]
        csvData.push(newLine)
      }
      setDataToWrite(csvData)
      setWaitMessage('')
      setSuccessMessage('Sell info ready to download')
    }
    catch(error) {
      clog(error)
      setError(`error - It was not possible to fetch data | error: ${error}`)
    }
  }

  const makeTradesReport = async () => {
    
    setFilename('negociacoes')
    setError('')
    setSuccessMessage('')
    setWaitMessage('Wait for it...')

    if(!inititalDateRef.current.value || !finalDateRef.current.value) {
      setError('Insira as datas')
      setWaitMessage('')
      return
    }

    const ordersBuyRef = collection(appV2Db, 'ordersBuy')
    const ordersSellRef = collection(appV2Db, 'ordersSell')
    // const dataRef = 1669215600 // Wednesday, November 23, 2022 11:59:59 PM GMT-03:00

    try {
      const q1 = query(ordersBuyRef, where('status', '==', 'FULFILLED'))
      const q2 = query(ordersSellRef, where('status', '==', 'FULFILLED'))
      
      const querySnapshotBuy = await getDocs(q1)
      const querySnapshotSell = await getDocs(q2)
      clog(`TAMANHO col = ${querySnapshotBuy.size + querySnapshotSell.size}`)
      let userOrdersList = []
      
      const inititalDate = inititalDateRef.current.value ? toTimestamp(inititalDateRef.current.value) : 0
      const finalDate = finalDateRef.current.value ? toTimestamp(finalDateRef.current.value) + 24 * 60 * 60 : 33241680360
      
      if(!querySnapshotBuy.empty) {
        querySnapshotBuy.forEach(doc => {
          const order = doc.data()
          order.orderId = doc.id
          const isFilteredId = filteredIds.includes(order.userId)
          if(inititalDate <= order.timeStatusFulfilled && order.timeStatusFulfilled < finalDate && !isFilteredId) {
            userOrdersList.push(order)
          }
        })
      }
      
      if(!querySnapshotSell.empty) {
        querySnapshotSell.forEach(doc => {
          const order = doc.data()
          order.orderId = doc.id
          const isFilteredId = filteredIds.includes(order.userId)
          if(inititalDate <= order.timeStatusFulfilled && order.timeStatusFulfilled < finalDate && !isFilteredId) {
            userOrdersList.push(order)
          }
        })
      }
      
      clog(`userOrdersList TAM: ${userOrdersList.length}`)
      clog(JSON.stringify(userOrdersList, null, 2))
      
      let csvBuyData = [
        ["Data criação (segs)", "Data fulfilled (segs)", "Data criação", "Data fulfilled", "Nome", "Email", "CPF", "Phone", "Valor", "Taxa CH", "Tipo", "id"],
      ]

      let cell = 2
      
      for(let i in userOrdersList) {
        const order = userOrdersList[i]
        const userRef = doc(appV2Db, `users/${order.userId}/nonEditableInformation/nonEditableInformation`) //usar noneditable pq lá q tem as infos do user
        const userSnap = await getDoc(userRef)
        if(userSnap.data()) {
          const phone = formatPhone(userSnap.data().registrationInfo.phone)
          const dateCreationSeconds = order.timeCreation
          const dateFulfilledSeconds = order.timeStatusFulfilled
          const dateCreation = convertTimestampToBrazil(dateCreationSeconds)
          const dateFulfilled = convertTimestampToBrazil(dateFulfilledSeconds)
          const name = order.userInfo.name
          const email = order.userInfo.email
          const cpf = userSnap.data().registrationInfo.cpf ? formatCpf(userSnap.data().registrationInfo.cpf) : 'Não informado'
          const value = order.orderSide === 'BUY' ? order.investedValue : order.totalToReceive
          const fee = order.fee
          const side = order.orderSide
          const id = order.orderId
          const newLine = [dateCreationSeconds, dateFulfilledSeconds, dateCreation, dateFulfilled, name, email, cpf, phone, value, fee, side, id]
          cell++
          csvBuyData.push(newLine)
        } else {
            const phone = formatPhone(order.userInfo.phone)
            const dateCreationSeconds = order.timeCreation
            const dateFulfilledSeconds = order.timeStatusFulfilled
            const dateCreation = convertTimestampToBrazil(dateCreationSeconds)
            const dateFulfilled = convertTimestampToBrazil(dateFulfilledSeconds)
            const name = order.userInfo.name
            const email = order.userInfo.email
            const cpf = order.userInfo.cpf ? formatCpf(order.userInfo.cpf) : 'Não informado'
            const value = order.orderSide === 'BUY' ? order.investedValue : order.totalToReceive
            const fee = order.fee
            const side = order.orderSide
            const id = order.orderId
            const newLine = [dateCreationSeconds, dateFulfilledSeconds, dateCreation, dateFulfilled, name, email, cpf, phone, value, fee, side, id]
            cell++
            csvBuyData.push(newLine)
        }
      }
      setDataToWrite(csvBuyData)
      setWaitMessage('')
      setSuccessMessage('Report ready to download')
    } catch (error) {
      console.error(error)
      setError(`error - It was not possible to fetch data | error: ${error}`)
    }
  }

  const fetchAccountReportData = async () => {
    try {
      setFilename('vendas')
      setError('')
      setSuccessMessage('')
      setWaitMessage('Wait for it...')
      if(!inititalDateRef.current.value || !finalDateRef.current.value) {
        setError('Insira as datas')
        return
      }
      const inititalDate = toTimestamp(inititalDateRef.current.value)
      const finalDate = toTimestamp(finalDateRef.current.value) + 24 * 60 * 60

      clog(`inititalDate = ${inititalDate}`)
      clog(`finalDate = ${finalDate}`)

      /* ------------------------------------- */
      let csvData = [
        ['Tempo (seg)', 'Nome', 'CPF', 'Data de Venda', 'Valor de Venda (enviado para o cliente)', 'ID Venda', 'Valor Compra', 'Ativo'],
      ]

      
      const usersRef = collection(appV2Db, 'users')
      const ordersSellRef = collection(appV2Db, 'ordersSell')
      const ordersBuyRef = collection(appV2Db, 'ordersBuy')
      
      let usersIds = []
      const usersList = await getDocs(usersRef)
      usersList.forEach(doc => {
        usersIds.push(doc.id)
      })

      for(let i in usersIds) {
        const userId = usersIds[i]
        const q1 = query(ordersSellRef,
          where('status', '==', 'FULFILLED'),
          where('pixStatus', '==', 'PAYED'),
          where('userId', '==', userId))
        
        const sellOrdersQuerySnap = await getDocs(q1)
        if(sellOrdersQuerySnap.empty) {
          continue
        }
        let sellOrderDataList = []
        sellOrdersQuerySnap.forEach(doc => {
          const sellOrderData = doc.data()
          if(inititalDate <= sellOrderData.timeStatusFulfilled && sellOrderData.timeStatusFulfilled < finalDate) {
            sellOrderDataList.push({...sellOrderData, orderId: doc.id})
          }
        })

        for(let i in sellOrderDataList) {
          const sellOrderData = sellOrderDataList[i]

          const timeUpdate = sellOrderData.timeStatusFulfilled
          const name = sellOrderData.userInfo.name
          let cpf = sellOrderData.userInfo.cpf
          if(!cpf) {
            const userDocRef = doc(appV2Db, `users/${sellOrderData.userId}/nonEditableInformation/nonEditableInformation`)
            const userDocSnap = await getDoc(userDocRef)
            cpf = userDocSnap.data().registrationInfo.cpf
          }
          cpf = cpf ? formatCpf(cpf) : ''
          const sellFulfilledDate = toDateTimeWithoutTime(sellOrderData.timeStatusFulfilled)
          const totalToReceive = sellOrderData.totalToReceive
          const fee = sellOrderData.fee
          const sellId = sellOrderData.orderId

          const q2 = query(ordersBuyRef,
            where('status', '==', 'FULFILLED'),
            where('pixStatus', '==', 'PAYED'),
            where('userId', '==', userId))

          let orderDataList = []
          const buyOrdersQuerySnap = await getDocs(q2)
          buyOrdersQuerySnap.forEach(doc => {
            const buyOrderData = doc.data()
            orderDataList.push({...buyOrderData, orderId: doc.id})
          })
          sellOrdersQuerySnap.forEach(doc => {
            const sellOrderData = doc.data()
            orderDataList.push({...sellOrderData, orderId: doc.id})
          })
          const newOrderDataList = reorderList(orderDataList, 'timeStatusFulfilled')
          const type = sellOrderData.basketInfo[0].assetType
          if(type === 'cryptocurrency') {
            let assetsSell = {}
            const basketSellResponse = sellOrderData.finalSellBasketResponse.sellBasketResponsesList
            const fiatPrice = sellOrderData.finalSellBasketResponse.fiatPrice
            for(let i in basketSellResponse) {
              const asset = basketSellResponse[i]
              assetsSell[sellOrderData.basketInfo[i].symbol] = {value: asset.averagePrice * parseFloat(asset.executedQty) * fiatPrice, qty: parseFloat(asset.executedQty)}
            }

            let grossTotal = 0
            Object.keys(assetsSell).forEach(asset => {
              grossTotal += assetsSell[asset].value
            })

            Object.keys(assetsSell).forEach(asset => {
              const assetBuyInfo = {qty: 0, totalInvestedValue: 0, averagePrice: 0}
              const percentageCoin = assetsSell[asset].value / grossTotal
              const sellValue = percentageCoin * totalToReceive
              for(let i = 0; i < newOrderDataList.length; i++) {
                const orderData = newOrderDataList[i]
                const orderSide = orderData.orderSide
                if(orderSide === 'BUY') {
                  if(orderData.timeStatusFulfilled >= sellOrderData.timeStatusFulfilled || orderData.basketInfo[0].assetType !== 'cryptocurrency') {
                    continue
                  }
                  else {
                    const basketInfo = orderData.basketInfo
                    const finalBuyBasketResponse = orderData.finalBuyBasketResponse
                    const fiatPriceBuy = finalBuyBasketResponse.fiatPrice
                    const buyBasketResponseList = finalBuyBasketResponse.buyBasketResponsesList
                    for(let j in buyBasketResponseList) {
                      const buyAssetSymbol = basketInfo[j].symbol
                      const buyAsset = buyBasketResponseList[j]
                      if(buyAssetSymbol === asset) {
                        const buyAssetQty = parseFloat(buyAsset.executedQty)
                        const buyAssetValue = buyAsset.averagePrice * buyAssetQty * fiatPriceBuy
                        assetBuyInfo.qty += buyAssetQty
                        assetBuyInfo.totalInvestedValue += buyAssetValue
                        assetBuyInfo.averagePrice = assetBuyInfo.totalInvestedValue / assetBuyInfo.qty
                      }
                    }
                  }
                } else if(orderSide === 'SELL') {
                  if(orderData.timeStatusFulfilled < sellOrderData.timeStatusFulfilled && orderData.basketInfo[0].assetType === 'cryptocurrency') {
                    const oldBasketSellResponse = orderData.finalSellBasketResponse
                    const oldSellFiatPrice = oldBasketSellResponse.fiatPrice
                    const oldSellBasketResponseList = oldBasketSellResponse.sellBasketResponsesList
                    for(let k in oldSellBasketResponseList) {
                      const oldSellAsset = oldSellBasketResponseList[k]
                      if(orderData.basketInfo[k].symbol === asset) {
                        assetBuyInfo.qty -= parseFloat(oldSellAsset.executedQty)
                        assetBuyInfo.totalInvestedValue = assetBuyInfo.qty * assetBuyInfo.averagePrice
                      }
                    }
                  }
                }
              }
              const newLine = [timeUpdate, name, cpf, sellFulfilledDate, sellValue, sellId, assetBuyInfo.averagePrice * assetsSell[asset].qty, asset]
              csvData.push(newLine)
            })
          }
        }
      }

      /* ------------------------------------- */
      setDataToWrite(csvData)
      setWaitMessage('')
      setSuccessMessage('Sell info ready to download')
    }
    catch(error) {
      clog(error)
      setError(`error - It was not possible to fetch data | error: ${error}`)
    }
  }

  const fetchUsersWithBalance = async () => {
    setError('')
    setSuccessMessage('')
    setFilename('usersWithBalance')
    setWaitMessage('Wait for it... Loading data')
    try {
      const usersRef = collection(appV2Db, 'users')
      const usersQuerySnap = await getDocs(usersRef)
      let csvData = [['NAME', 'EMAIL', 'PHONE', 'TOTAL VALUE IN R$']]
      let users = []
      usersQuerySnap.docs.forEach(doc => {
        const userInfo = doc.data()
        userInfo.userId = doc.id
        users.push(userInfo)
      })
      for(const userIndex in users) {
        const user = users[userIndex]
        const userId = user.userId
        const userNonEditableInfoRef = doc(appV2Db, `users/${userId}/nonEditableInformation/nonEditableInformation`)
        const userNonEditableInfoSnap = await getDoc(userNonEditableInfoRef)
        const userNonEditableInfo = userNonEditableInfoSnap.data()
        const userRegistrationInfo = userNonEditableInfo.registrationInfo
        const name = userRegistrationInfo.name ?? ''
        const email = userRegistrationInfo.email ?? ''
        const phone = userRegistrationInfo.phone ?? ''
        const userAssetsRef = doc(appV2Db, `users/${userId}/nonEditableInformation/userAssets`)
        const userAssetsSnap = await getDoc(userAssetsRef)
        const assetsData = userAssetsSnap?.data()
        if(!userAssetsSnap|| !assetsData) {
          continue
        }
        let totalInFiat = 0
        const currentDate = new Date()
        const finalDate = Math.floor(currentDate/1000)
        const priceStableInFiat = await getPairPrice('USDTBRL', finalDate - 1000); // subtract 1000 seconds, if we try to use the current time the api returns error
        for (const asset of Object.keys(assetsData)) {
          const symbol = asset
          const assetData = assetsData[asset]
          const pairSymbol = symbol + 'USDT';
          const qty = assetData.qty
          const pairPrice = await getPairPrice(pairSymbol, finalDate - 1000) // subtract 1000 seconds, if we try to use the current time the api returns error
          const totalInFiatSymbol = qty * pairPrice * priceStableInFiat
          totalInFiat += totalInFiatSymbol
        }
        if(totalInFiat !== 0) {
          const newLine = [name, email, phone, totalInFiat]
          csvData.push(newLine)
        }
      }
      clog('TERMINOU')
      clog(JSON.stringify(csvData, null, 2))
  
      setDataToWrite(csvData)
      setSuccessMessage('Users with balance ready to download')
    } catch(error) {
      console.error(error)
      setError(`error - It was not possible to fetch users with balance`)
    }
   
  }
  
  return (
    <>
      <Form>
        <Form.Group id='inititalDate' style={{justifyContent: 'center'}}>
          <Form.Label>Data inicial (a partir de 00:00:00)</Form.Label>
          <Form.Control
            type='date'
            ref={inititalDateRef}
            required
            autoFocus
            style={{width: '30%'}}
          />
        </Form.Group>
        <Form.Group id='finalDate'>
          <Form.Label>Data final (até 23:59:59)</Form.Label>
          <Form.Control
            type='date'
            ref={finalDateRef}
            required
            style={{width: '30%'}}
          />
        </Form.Group>
        {waitMessage && <Alert variant='warning'>{waitMessage}</Alert>}
        {successMessage && <Alert variant='success'>{successMessage}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}
        <Button variant='primary' className='w-100'onClick={fetchAccountReportData}>
          Gerar RELATÓRIO DE VENDAS
        </Button>
        <br></br>
        <br></br>
        <Button variant='primary' className='w-100'onClick={makeTradesReport}>
          Buscar DADOS DE NEGOCIAÇÃO
        </Button>
        <br></br>
        <br></br>
        <Button variant='primary' className='w-100'onClick={fetchUsersWithBalance}>
          Gerar RELATÓRIO DE USUÁRIOS COM SALDO
        </Button>
        <br></br>
        <br></br>
        <CSVLink
          data={dataToWrite}
          separator={','}
          filename={`${filename}_${inititalDateRef.current.value}|00:00___${finalDateRef.current.value}|23:59.csv`}
          className='btn btn-primary'>
          Download
        </CSVLink>
      </Form>
      <div className='w-100 text-center mt-2'>
        <Link to='/dashboardappv2'>Voltar</Link>
      </div>
    </>
  )
}