import { arrayUnion, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import mockData from '../data/mockDataCreateBasket.json';
import { appV2Db } from '../firebase';

const FIAT_SYMBOL = 'BRL'
const STABLE_COIN_SYMBOL = 'USDT'


function AddNewUser() {
  

  const { currentAdminUser } = useAuth()

  const [coins, setCoins] = React.useState(mockData.coins)
  const [quantity, setQuantity] = useState(0)
  const [newBasketName, setNewBasketName] = React.useState('')
  const [newBasketImage, setNewBasketImage] = React.useState(null)

  const [basketSucessfullyCreated, setBasketSucessfullyCreated] = React.useState(false)
  const [basketErrorCreation, setBasketErrorCreation] = React.useState(false)

  const [clientAddSucess, setClientAddSucess] = React.useState(false)
  const [clientAddError, setClientAddError] = React.useState(false)
  const [errorMsg, setErrorMsg] = React.useState('')

  const [newClientEmail, setNewClientEmail] = React.useState('')
  const [newCoinSymbol, setNewCoinSymbol] = React.useState('')
  const [newCoinImage, setNewCoinImage] = React.useState(null)
  const [newCoinMainColor, setNewCoinMainColor] = React.useState('')
  const [newTokenUnitValue, setNewTokenUnitValue] = React.useState()
  const [newTokenDueDate, setNewTokenDueDate] = React.useState()
  const [newTokenSellMinimumDate, setNewTokenSellMinimumDate] = React.useState()



  const handleNewClientEmailChange = (event) => {
    setNewClientEmail(event.target.value);
  };

  const handleSubmitNewToken = async (event) => {
    event.preventDefault();

    setClientAddSucess(false)
    setClientAddError(false)
    setErrorMsg('')

    let NUM;
    const adminId = currentAdminUser.uid;
    if(adminId === 'oaDhAu79SPYYqDxh6lOl2G6AEDn2') {
      NUM = 0;  //FERNANDO
    } else if(adminId === 'UtJ5B63v4uZolhsadU0ANSISemW2'){
      NUM = 1;  //STEFANO
    } else if (adminId === 'tsM6UtAGmAcx0YHI1a2rh0BpkOB2'){
      NUM = 2; //MAURICIO
    }
    else {
      alert('Você não é um assessor cadastrado, entre em contato.')
      console.error('Você não é um assessor cadastrado, entre em contato.')
      setClientAddError(true);
      setErrorMsg('Você não é um assessor cadastrado, entre em contato.')
      return;
    }
    
    const docRef = doc(appV2Db, "admin/advisorsInfo");
    const docSnap = await getDoc(docRef);
    const docData = docSnap.data();
    if(docSnap.exists() && docData.advisors[NUM]?.clientsEmails?.includes(newClientEmail)) {
      alert('Erro: não foi possível adicionar o cliente, esse email já está cadastrado para esse assessor, tente novamente!')
      console.error('Esse cliente já foi adicionado.')
      setClientAddError(true);
      return;
    }

    const updatedData = {
      ...docData,
      advisors: docData.advisors.map((advisor, index) => {
        if(index === NUM) {
          return {
            ...advisor,
            clientsEmails: advisor.clientsEmails.concat(newClientEmail)
          }
        }
        return advisor
      })
    }

    try {
      await updateDoc(docRef, updatedData)

    } catch (error) {
      console.error("Error updating document: ", error);
      setClientAddError(true);
      setNewClientEmail("");
      return;
    }

    try {
      const usersRef = collection(appV2Db, 'users');
      const q = query(usersRef, where("email", "==", newClientEmail));
      const querySnapshot = await getDocs(q);
      if(!querySnapshot.empty) {
        const docSnap = querySnapshot.docs[0];
        const docData = docSnap.data();
        const docId = docSnap.id;
        const docRef = doc(appV2Db, `users/${docId}/nonEditableInformation/nonEditableInformation`);
        if(NUM === 0){
          await updateDoc(docRef, {
            advisors: arrayUnion({name: 'Fernando Lopes', phone: '5511983153932'})
          })
        }
        else if(NUM === 1){
          await updateDoc(docRef, {
            advisors: arrayUnion({name: 'Stefano Giorgi', phone: '5511992366838'})
          })
        }
        else if(NUM === 2){
          await updateDoc(docRef, {
            advisors: arrayUnion({name: 'Maurício Sales', phone: '5512996596616'})
          })
        }
      }
    } catch (error) {
      console.error("Error updating document: ", error);
      setClientAddError(true);
      setNewClientEmail("");
      return;
    }

    /* const docSnap = await getDoc(docRef);
    const docData = docSnap.data();
    clog(docData.advisors[0])
    clog(adminId) */

    setNewClientEmail("");
    setErrorMsg('');
    setClientAddSucess(true);
  }
  

  React.useEffect(() => {
    
  }, []);


  return (
    <>
      
    <h1 className="h3 mb-4 mt-4 text-gray-800">Adicionar novo cliente</h1>
    <form onSubmit={handleSubmitNewToken}>
      <p style={{color: 'red'}}>ATENÇÃO! Caso você não esteja na lista de assessores ou logado na sua conta correta, a adição do novo cliente não irá funcionar.</p>
    <div>
      <label htmlFor="newclient" className="mr-2">Email do cliente:</label>
      <input
        type="email"
        id="newclient"
        placeholder='assessorado@gmail.com'
        value={newClientEmail}
        onChange={handleNewClientEmailChange}
        required
      />
       
        {clientAddSucess && (
          <Alert variant='success' className='mt-1'>Cliente adicionado com sucesso!</Alert>
        )}
        {clientAddError && (
          <Alert variant='danger' className='mt-1'>Erro ao adicionar cliente! {errorMsg} Para mais informações, cheque o console.</Alert>
        )}
        <Button type='submit' variant='primary' className='w-100 mt-4'>Adicionar usuário</Button>
    </div>
    </form>
    <div className="w-100 text-center mt-2">
        <Link to="/dashboardappv2">Voltar</Link>
      </div>
    
    </>
  )
}

export default AddNewUser
