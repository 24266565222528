import { collection, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

import { clog, fetchUrl, getPrice } from '../auxiliary/helpers'
import { appDb } from '../firebase'

export default function ManageAssets() {

  const MARGIN_SAFETY_USD = 0.1
  const QUOTE_ASSET_SYMBOL = 'USDT'
  const FIAT_SYMBOL = 'BRL'
  const EMAIL_MASTER = 'contato@cryptohero.com.br'
  const EMAIL_SUBACCOUNT = 'subaccount_virtual@bammwqw0noemail.com'
  const EMAIL_SUBACCOUNT_AUX = 'auxiliary_virtual@tlumkmitnoemail.com'
  const EMAIL_SUBACCOUNT_WITHDRAW = 'withdraw_virtual@7jku88mhnoemail.com'

  const { currentAdminUser } = useAuth()
  const [error, setError] = useState('')
  const [orderMessage, setOrderMessage] = useState('')
  const [waitMessage, setWaitMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [permitted, setPermitted] = useState(false)
  // const [allAccountDataReady, setAllAccountDataReady] = useState(false)
  const [accountData, setAccountData] = useState()
  const [prices, setPrices] = useState({})

  const [exchangeAccountBalances, setExchangeAccountBalances] = useState()
  const [auxiliaryAccountBalances, setAuxiliaryAccountBalances] = useState()
  // const [usersCoins, setUsersCoins] = useState()
  const [cryptoHeroCoins, setCryptoHeroCoins] = useState()

  const [totalsUsd, setTotalsUsd] = useState()
  const [totalsBrl, setTotalsBrl] = useState()

  useEffect(() => {
    const PERMMITED_UID = 'tsM6UtAGmAcx0YHI1a2rh0BpkOB2'
    const uid = currentAdminUser.uid
    const url = new URL(window.location.href)
    const hostname = url.hostname
    if(uid === PERMMITED_UID && hostname === 'localhost') setPermitted(true)
    else setError('User not allowed!')
  }, [])

  useEffect(() => {
    if(accountData && prices && exchangeAccountBalances && totalsUsd && totalsBrl) {
      if('exchangeAccountBalances' in totalsUsd &&
        'auxiliaryAccountBalances' in totalsUsd &&
        'usersCoins' in totalsUsd &&
        'cryptoHeroCoins' in totalsUsd &&
        'exchangeAccountBalances' in totalsBrl &&
        'auxiliaryAccountBalances' in totalsBrl &&
        'usersCoins' in totalsBrl &&
        'cryptoHeroCoins' in totalsBrl) {
        clog('----------------------------------------')
        clog('FINAL')
        clog('totalsUsd')
        clog(JSON.stringify(totalsUsd, null, 2))
        clog('totalsBrl')
        clog(JSON.stringify(totalsBrl, null, 2))
        setLoading(false)
      }
    }
  }, [accountData, prices, exchangeAccountBalances, totalsUsd, totalsBrl])

  useEffect(() => {
    const getAccountInfo = async () => {
      const Binance = require('node-binance-api')
      const binance = new Binance().options({})
      const authTokenId = await currentAdminUser.getIdToken()
      const options = {
          headers: {
              'Authorization': 'Bearer ' + authTokenId,
          }
      }
      /***************************************************/
      const EMULATOR = false
      /***************************************************/
      const cloudFunction = 'getExchangeAccountInfo'
      const queryData = [
        {
          name: 'fiatSymbol',
          value: FIAT_SYMBOL,
        },
      ]
      try {
        clog('getting accountAllData...')
        const accountAllData = await fetchUrl(currentAdminUser, 'admin', EMULATOR, cloudFunction, queryData)
        setAccountData(accountAllData)

        const subaccountBalances = {}
        
        const fiatPrice = accountAllData.fiat2QuotePrice

        let exchangeCoinsTotalsUsd = 0
        let bidPrices = {}
        for(const symbol in accountAllData.availableBalancesSub) {
          
          const amount = parseFloat(accountAllData.availableBalancesSub[symbol])
          let bidPrice = 0
          const pairSymbol = symbol + QUOTE_ASSET_SYMBOL
          bidPrice = await getAdjustedPrice(symbol, QUOTE_ASSET_SYMBOL, 'bidPrice', binance)
          bidPrices[pairSymbol] = bidPrice
          const inUSDT = bidPrice * amount
          const inFiat = inUSDT * fiatPrice
          exchangeCoinsTotalsUsd += inUSDT
          clog(`symbol = ${symbol} || bidPrice = ${bidPrice}`)
          subaccountBalances[symbol] = {
            amount: amount,
            price: bidPrice,
            inUSDT: inUSDT,
            inFiat: inFiat,
          }
        }
        let auxCoinsTotalsUsd = 0
        // clog(`accountAllData.availableBalancesAux`)
        // clog(JSON.stringify(accountAllData.availableBalancesAux, null, 2))
        const auxAccountBalances = {}
        for(const symbol in accountAllData.availableBalancesAux) {
          const amount = parseFloat(accountAllData.availableBalancesAux[symbol])
          const pairSymbol = symbol + QUOTE_ASSET_SYMBOL
          let bidPrice = bidPrices[pairSymbol]
          if(!bidPrice) {
            bidPrice = await getAdjustedPrice(symbol, QUOTE_ASSET_SYMBOL, 'bidPrice', binance)
            bidPrices[pairSymbol] = bidPrice
            clog(`** if(!bidPrice) symbol = ${symbol} || bidPrice = ${bidPrice}`)
          }
          // clog(`pairSymbol = ${pairSymbol} | bidPrice = ${bidPrice}`)
          const inUSDT = bidPrice * amount
          auxCoinsTotalsUsd += inUSDT
          auxAccountBalances[symbol] = {
            amount: amount,
            price: bidPrice,
            inUSDT: inUSDT,
            inFiat: inUSDT * fiatPrice,
          }
        }
        setAuxiliaryAccountBalances(auxAccountBalances)
        setPrices(bidPrices)
        let auxCoinsTotalsBrl = auxCoinsTotalsUsd * fiatPrice
        setExchangeAccountBalances(subaccountBalances)
        let exchangeCoinsTotalsBrl = exchangeCoinsTotalsUsd * fiatPrice
        clog(subaccountBalances)
        setTotalsUsd(prev => {
          return {
            ...prev,
            exchangeAccountBalances: exchangeCoinsTotalsUsd,
            auxiliaryAccountBalances: auxCoinsTotalsUsd
          }
        })
        setTotalsBrl(prev => {
          return {
            ...prev,
            exchangeAccountBalances: exchangeCoinsTotalsBrl,
            auxiliaryAccountBalances: auxCoinsTotalsBrl
          }
        })

      }
      catch(error) {
        console.log(error)
      }
    }
    getAccountInfo()
  }, [])

  useEffect(() => {
    const getAllBalances = async () => {
      const Binance = require('node-binance-api')
      const binance = new Binance().options({})
      clog('getAllBalances INITIATED')
      const fiatPrice = accountData.fiat2QuotePrice

      const usersRef = collection(appDb, 'users')
      const usersQuerySnapshot = await getDocs(usersRef)
      let usersIdsList = []
      usersQuerySnapshot.forEach(doc => {
        usersIdsList.push(doc.id)
      })
      let userBasketsCoins = []
      for(const index in usersIdsList) {
        const userId = usersIdsList[index]
        const userBasketsRef = collection(appDb, `/users/${userId}/userBaskets`)
        const q1 = query(userBasketsRef, where('status', '==', 'FULFILLED'))
        const userBasketsQuerySnapshot = await getDocs(q1)
        userBasketsQuerySnapshot.forEach(doc => {
          const userBasket = doc.data()
          const coins = userBasket.coins
          userBasketsCoins.push(coins)
        })
      }
      let usersCoinsAmounts = {}
      for(const index in userBasketsCoins) {
        const coins = userBasketsCoins[index]
        for(const symbol in coins) {
          const prevCoinAmount = symbol in usersCoinsAmounts ? usersCoinsAmounts[symbol] : 0
          usersCoinsAmounts[symbol] = prevCoinAmount + coins[symbol].amount
        }
      }
      // clog('usersCoinsAmounts')
      // clog(JSON.stringify(usersCoinsAmounts, null, 2))
      let usersCoinsTotalsUsd = 0
      // let usersCoins = {}
      for(const symbol in usersCoinsAmounts) {
        clog(`symbol in usersCoinsAmounts = ${symbol}`)
        const pairSymbol = symbol + QUOTE_ASSET_SYMBOL
        const bidPrice = prices[pairSymbol]
        const inUSDT = usersCoinsAmounts[symbol] * bidPrice
        usersCoinsTotalsUsd += inUSDT
        // usersCoins[symbol] = {
        //   amount: usersCoinsAmounts[symbol],
        //   price: bidPrice,
        //   inUSDT: inUSDT,
        //   inFiat: inFiat,
        // }
        // setUsersCoins(prev => {
        //   return {
        //     ...prev,
        //     [symbol] : {
        //       amount: usersCoinsAmounts[symbol],
        //       price: bidPrice,
        //       inUSDT: inUSDT,
        //       inFiat: inFiat,
        //     }
        //   }
        // })
      }
      let usersCoinsTotalsBrl = usersCoinsTotalsUsd * fiatPrice

      // clog('usersCoins')
      // clog(JSON.stringify(usersCoins, null, 2))

      let cryptoHeroCoinsTotalsUsd = 0
      for(const symbol in accountData.availableBalancesSub) {
        const totalAmount = accountData.availableBalancesSub[symbol]
        const userAmount = symbol in usersCoinsAmounts ? usersCoinsAmounts[symbol] : 0
        const pairSymbol = symbol + QUOTE_ASSET_SYMBOL
        let bidPrice = prices[pairSymbol]
        if(!bidPrice) {
          bidPrice = await getAdjustedPrice(symbol, QUOTE_ASSET_SYMBOL, 'bidPrice', binance)
        }
        const marginAmount = MARGIN_SAFETY_USD / bidPrice
        const cryptoHeroAmount = totalAmount - userAmount - marginAmount > 0 ? totalAmount - userAmount - marginAmount : 0
        const inUSDT = cryptoHeroAmount * bidPrice
        // clog(`pairSymbol = ${pairSymbol} | bidPrice = ${bidPrice} | userAmount = ${userAmount} | cryptoHeroAmount = ${cryptoHeroAmount} | totalAmount = ${totalAmount} | inUSDT = ${inUSDT}`)
        cryptoHeroCoinsTotalsUsd += inUSDT
        setCryptoHeroCoins(prev => {
          return {
            ...prev,
            [symbol]: {
              amount: cryptoHeroAmount,
              price: bidPrice,
              inUSDT: inUSDT,
              inFiat: inUSDT * fiatPrice,
            },
          }
        })
      }
      let cryptoHeroCoinsTotalsBrl = cryptoHeroCoinsTotalsUsd * fiatPrice

      setTotalsUsd(prev => {
        return {
          ...prev,
          usersCoins: usersCoinsTotalsUsd,
          cryptoHeroCoins: cryptoHeroCoinsTotalsUsd
        }
      })
      setTotalsBrl(prev => {
        return {
          ...prev,
          usersCoins: usersCoinsTotalsBrl,
          cryptoHeroCoins: cryptoHeroCoinsTotalsBrl
        }
      })
    }
    if(accountData && prices && exchangeAccountBalances) {
      getAllBalances()
    }
  }, [accountData, prices, exchangeAccountBalances])

  //REDONDOS
  const transferCHAssets = async () => {
    const backend = 'admin'
    /***************************************************/
    const EMULATOR = false
    /***************************************************/
    const cloudFunction = 'internalTransfer'
    const queryData = [
      {
        name: 'fromEmail',
        value: EMAIL_SUBACCOUNT,
      },
      {
        name: 'toEmail',
        value: EMAIL_SUBACCOUNT_AUX,
      },
      {
        name: 'assetsInfo',
        value: JSON.stringify(cryptoHeroCoins),
      },
    ]
    const response = await fetchUrl(currentAdminUser, backend, EMULATOR, cloudFunction, queryData)
    clog('response')
    clog(response)
  }

  const tradeCoins = async () => {

    /*-------------------------------------------------*/
    /*-------------------------------------------------*/
    // Insert TRADE inputs here
    const coin = 'BNB'
    const quoteAmount = 15  // to sell all, insert a big number
    // const orderSide = 'SELL'
    const orderSide = 'BUY'
    /*-------------------------------------------------*/
    /*-------------------------------------------------*/

    if((orderSide !== 'SELL' && orderSide !== 'BUY') ||
       !(coin in auxiliaryAccountBalances) || quoteAmount < 10) {
      setError('Incorrect inputs. Check it!')
      return
    }

    const backend = 'admin'
    /***************************************************/
    const EMULATOR = false
    /***************************************************/
    const cloudFunction = 'adminTrade'
    const queryData = [
      {
        name: 'orderSide',
        value: orderSide,
      },
      {
        name: 'quoteSymbol',
        value: QUOTE_ASSET_SYMBOL,
      },
      {
        name: 'baseSymbol',
        value: coin,
      },
      {
        name: 'quoteAmount',
        value: quoteAmount,
      },
    ]
    const response = await fetchUrl(currentAdminUser, backend, EMULATOR, cloudFunction, queryData)

    clog(JSON.stringify(response, null, 2))
  }

  const sellAllCoinsSync = async () => {
    const quoteAmount = 1000000000  // to sell all, insert a big number
    const orderSide = 'SELL'
    const backend = 'admin'
    /***************************************************/
    const EMULATOR = false
    /***************************************************/
    const cloudFunction = 'adminTrade'
    let responses = []
    transferSymbol('USDT', EMAIL_SUBACCOUNT, EMAIL_SUBACCOUNT_AUX)
    for(let symbol in auxiliaryAccountBalances) {
      const coin = symbol
      if(auxiliaryAccountBalances[symbol].inUSDT < 0.001 || coin === QUOTE_ASSET_SYMBOL || coin === FIAT_SYMBOL) {
        clog(`${symbol} com menos de 0.01 USD de amount`)
        continue
      }
      const queryData = [
        {
          name: 'orderSide',
          value: orderSide,
        },
        {
          name: 'quoteSymbol',
          value: QUOTE_ASSET_SYMBOL,
        },
        {
          name: 'baseSymbol',
          value: coin,
        },
        {
          name: 'quoteAmount',
          value: quoteAmount,
        },
      ]
      const response = await fetchUrl(currentAdminUser, backend, EMULATOR, cloudFunction, queryData)
      responses.push(response)
    }
    console.log(responses)
  }

  const sellAllCoinsAsync = async () => {
    const quoteAmount = 1000000000  // to sell all, insert a big number
    const orderSide = 'SELL'
    const backend = 'admin'
    /***************************************************/
    const EMULATOR = false
    /***************************************************/
    const cloudFunction = 'adminTrade'
    let responses = []
    transferSymbol('USDT', EMAIL_SUBACCOUNT, EMAIL_SUBACCOUNT_AUX)
    for(let symbol in auxiliaryAccountBalances) {
      const coin = symbol
      if(auxiliaryAccountBalances[symbol].inUSDT < 0.001 || coin === QUOTE_ASSET_SYMBOL || coin === FIAT_SYMBOL) {
        clog(`${symbol} com menos de 0.01 USD de amount`)
        continue
      }
      const queryData = [
        {
          name: 'orderSide',
          value: orderSide,
        },
        {
          name: 'quoteSymbol',
          value: QUOTE_ASSET_SYMBOL,
        },
        {
          name: 'baseSymbol',
          value: coin,
        },
        {
          name: 'quoteAmount',
          value: quoteAmount,
        },
      ]
      const response = fetchUrl(currentAdminUser, backend, EMULATOR, cloudFunction, queryData)
      responses.push(response)
    }
    Promise.all(responses).then((values) => {
      console.log(values)
    })
  }

  const transferSymbol = async (symbol, fromEmail, toEmail) => {
    const backend = 'admin'
    /***************************************************/
    const EMULATOR = false
    /***************************************************/
    const cloudFunction = 'internalTransfer'

    // if(!(symbol in auxiliaryAccountBalances)) {
    //   clog(`AUX account does not contain ${symbol}`)
    //   return
    // }

    let amount = 0
    if(fromEmail === EMAIL_SUBACCOUNT_AUX)
      amount = symbol in auxiliaryAccountBalances ? auxiliaryAccountBalances[symbol].amount : 0
    else if(fromEmail === EMAIL_SUBACCOUNT)
      amount = symbol in exchangeAccountBalances ? exchangeAccountBalances[symbol].amount : 0
    else {
      setError('Não tem essa possibilidade de transferencia!!!')
      return
    }
    
    clog('amount')
    clog(amount)

    const assetsInfo = {[symbol]: {'amount': amount, },}
    const queryData = [
      {
        name: 'fromEmail',
        value: fromEmail,
      },
      {
        name: 'toEmail',
        value: toEmail,
      },
      {
        name: 'assetsInfo',
        value: JSON.stringify(assetsInfo),
      },
    ]
    const response = await fetchUrl(currentAdminUser, backend, EMULATOR, cloudFunction, queryData)
    clog('response')
    clog(response)
  }

  const getAdjustedPrice = async (baseSymbol, quoteSymbol, priceType, binanceAccount) => {
    const pairSymbol = baseSymbol + quoteSymbol
    if(baseSymbol === quoteSymbol)
      return 1
    if(pairSymbol === FIAT_SYMBOL + QUOTE_ASSET_SYMBOL) {
      const pairSymbolAdjusted = QUOTE_ASSET_SYMBOL + FIAT_SYMBOL
      const price = await getPrice(pairSymbolAdjusted, priceType, binanceAccount)
      return 1/price
    }
    const price = await getPrice(pairSymbol, priceType, binanceAccount)
    return price
  }

  return (
    <>
      <Card>
        {error && <Alert variant='danger'>{error}</Alert>}
        {orderMessage && <Alert variant='success'>{orderMessage}</Alert>}
        {waitMessage && <Alert variant='primary'>{waitMessage}</Alert>}
        <Button disabled={loading || !permitted} className='w-100' onClick={transferCHAssets}>
          Transfer CH Assets
        </Button>
        <br></br>
        <Button disabled={true} className='w-100' onClick={tradeCoins}>
          Trade
        </Button>
        <br></br>
        <Button disabled={loading || !permitted} className='w-100' onClick={sellAllCoinsAsync}>
          Sell All Async
        </Button>
        <br></br>
        <Button disabled={loading || !permitted} className='w-100' onClick={sellAllCoinsSync}>
          Sell All Sync
        </Button>
        <br></br>
        <Button disabled={loading || !permitted} className='w-100' onClick={() => {transferSymbol('USDT', EMAIL_SUBACCOUNT_AUX, EMAIL_SUBACCOUNT)}}>
          Transfer USDT (aux to CH)
        </Button>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Link to='/'>Cancel</Link>
      </div>
    </>
  )
}