import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from "react"
import { Alert, Button, Card } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { axiosFetchCloudFunction, clog, cloj, formatCpf, formatPhone, getPrice } from "../auxiliary/helpers"
import { useAuth } from "../contexts/AuthContext"

import allCoinsData from '../data/allCoins.json'

import { appDb, appV2Db } from '../firebase'

import { getPairSymbolPrice } from '../auxiliary/broker'
import ordersData from '../data/allOrders.json'

export default function InitialPage() {

  const [error, setError] = useState("")
  const [dataToWrite, setDataToWrite] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [permitted, setPermitted] = useState(false)
  const [onlyLocalhost, setOnlyLocalhost] = useState(false)

  const { currentAdminUser, logout } = useAuth()
  const history = useHistory()

  const { currentAppUser } = useAuth()

  useEffect(() => {
    const PERMMITED_UIDS = ['tsM6UtAGmAcx0YHI1a2rh0BpkOB2', '5QdnIVHQ9YMQZYsHUsiLrXigCOV2']
    const uid = currentAdminUser.uid
    const url = new URL(window.location.href)
    const hostname = url.hostname
    if(PERMMITED_UIDS.includes(uid)) {
      setPermitted(true)
    }
    if(hostname === 'localhost') {
      setOnlyLocalhost(true)
    }
  }, [])

  async function handleLogout() {
    setError("")

    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  const sendEmailsTelegramTemp = async () => {
    return
    // const templateId = 'd-b150f2af0ded47fdb7678fdda944ac83'
    // const dynamicTemplateData = {}
    // emailsList.forEach(async email => {
    //   const sent = await sendEmail(currentAppUser, email, templateId, dynamicTemplateData)
    //   clog(email + ' -> ' + sent)
    // })
  }

  const restoreOrders = () => {
    clog(`TAM: ${ordersData.length}`)
    ordersData.forEach(async order => {
      clog(order.orderId)
      // clog(JSON.stringify(order, null, 2))
      await setDoc(doc(appDb, `/orders/${order.orderId}`), order)
    })
  }

  const makeReport = async () => {
    const ordersRef = collection(appDb, 'orders')
    // const dataRef = 1669215600 // Wednesday, November 23, 2022 11:59:59 PM GMT-03:00
    const q1 = query(ordersRef,
      where('status', '==', 'FULFILLED'),
      where('userId', '!=', 'YETftQD0Y5goflAqDPAixTY3O2h2'))    // Retirando MRC
      // where('orderSide', '==', 'BUY'))

    const querySnapshot = await getDocs(q1)
    clog(`TAMANHO col = ${querySnapshot.size}`)
    let userOrdersList = []
    if(!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        const order = doc.data()
        order.orderId = doc.id
        userOrdersList.push(order)
      })
      clog(`userOrdersList TAM: ${userOrdersList.length}`)
      clog(JSON.stringify(userOrdersList, null, 2))
    }
    clog(`userOrdersList TAM: ${userOrdersList.length}`)
    clog(JSON.stringify(userOrdersList, null, 2))

    let csvBuyData = [
      ["Data criação (segs)", "Data fulfilled (segs)", "Data criação", "Data fulfilled", "Nome", "Email", "CPF", "Phone", "Valor", "Cesta", "Hero", "Tipo", "Veio de", "id"],
    ]

    let cell = 2

    for(let i in userOrdersList) {
      const order = userOrdersList[i]
      const userRef = doc(appDb, `users/${order.userId}`)
      const userSnap = await getDoc(userRef)
      const phone = formatPhone(userSnap.data().personalInfo.phone)
      const cameFrom = userSnap.data().cameFrom
      // const dateCreation = toDateTime(order.timeCreation.seconds)
      const dateCreation = order.timeCreation.seconds
      const dateFulfilled = order.timeUpdate.seconds
      const dateFormula1 = `=(A${cell}/86400)+DATE(1970,1,1)`
      const dateFormula2 = `=(B${cell}/86400)+DATE(1970,1,1)`
      const name = order.personalInfo.name
      const email = order.personalInfo.email
      const cpf = formatCpf(userSnap.data().personalInfo.cpf)
      const value = order.orderSide === 'BUY' ?
        order.intendedInvestedValue :
        order.totalToReceive
      const basket = order.basketRefId
      const hero = order.heroId
      const side = order.orderSide
      const id = order.orderId
      const newLine = [dateCreation, dateFulfilled, dateFormula1, dateFormula2, name, email, cpf, phone, value, basket, hero, side, cameFrom, id]
      cell++
      csvBuyData.push(newLine)
    }
    setDataToWrite(csvBuyData)
    setSuccessMessage('Report ready to download')
  }

  const checkUserBaskted  = async () => {

    // const uid = 'YETftQD0Y5goflAqDPAixTY3O2h2'      // MRC
    // const uid = 'mdgT7tTVoJag1Zqt4Eh4kkN3gWX2'      // STE
    const uid = '4lDQM9T116R8aWF9ETnjeaD8Cvt1'      // 
    // const basketId = 'stefano_carteiraConservadora'
    const basketId = 'stefano_maiorPotencialDeGanhos'
    // const basketId = 'roberto_shardingBlockfolio'
    // const basketId = 'roberto_blockfolioMassAdoption'
    // const basketId = 'mauricio_carteiraConservadora'

    const docRef = doc(appDb, `/users/${uid}/userBaskets/${basketId}`)
    // const docRef = doc(appDb, `/users/TH6NNEPMWCOTgEJZBaUboHwqYO03/userBaskets/stefano_carteiraConservadora`)

    const docData = (await getDoc(docRef)).data()
    clog('docData')
    clog(JSON.stringify(docData, null, 2))
    const Binance = require('node-binance-api')
    const binance = new Binance().options({})

    let total = 0       // Apenas para checar se valor está coerente com o investido
    let totalValue = 0
    const brlPrice = await getPrice('USDTBRL', 'bidPrice', binance)
    for(let symbol in docData.coins) {
      clog(`------------------------------ ${symbol} ------------------------------`)
      const buyData = docData.coins[symbol]
      // clog(buyData)
      total += buyData.buyPrice * buyData.amount
      
      const currentPrice = await getPrice(`${symbol}USDT`, 'bidPrice', binance)
      const currentCoinTotalValue = currentPrice * buyData.amount
      // clog(`currentCoinTotalValue AT BUY = ${buyData.buyPrice * buyData.amount}`)
      // clog(`currentCoinTotalValue NOW = ${currentCoinTotalValue}`)
      totalValue += currentCoinTotalValue
      
      clog(`${symbol} PRICE = ${currentPrice}`)
      clog(`${symbol} AMOUNT = ${buyData.amount}`)
      clog(`buyCoinValue BRL = ${docData.investedValue * buyData.percentage}`)
      clog(`currentCoinTotalValue BRL = ${currentCoinTotalValue * brlPrice}`)
      clog(`-----------------------------------------------------------------------`)
    }
    
    const totalBrl = brlPrice * total
    clog(`total investido in USD = ${total}`)
    clog(`total investido in BRL APROXIMADAMENTE = ${totalBrl} BRL`)
    clog(`total investido in BRL REAL = ${docData.investedValue} BRL`)
    clog(`totalValue in USD = ${totalValue}`)
    clog(`totalValue in BRL = ${totalValue * brlPrice}`)
    
    const gain = (totalValue*brlPrice - docData.investedValue)
    const relativeGain = gain / docData.investedValue
    clog(`------------------------------ TOTALS ------------------------------`)
    clog(`totalValue*brlPrice = ${totalValue*brlPrice}`)
    clog(`totalBasketInvestedValue BRL = ${docData.investedValue}`)
    clog(`absBasketGain BRL = ${gain}`)
    clog(`relBasketGain BRL = ${relativeGain}`)
    clog(`--------------------------------------------------------------------`)
  }

  const confirmTestPurchases = async () => {
    const gasparID = 'YETftQD0Y5goflAqDPAixTY3O2h2'
    const colNale = 'orders'
    const ordersRef = collection(appDb, colNale)
    const q1 = query(ordersRef, where('userId', '==', gasparID), where('status', '==', 'OPEN'))
    const querySnapshot = await getDocs(q1)
    clog(`TAMANHO col = ${querySnapshot.size}`)
    let userOrdersList = []
    let userOrdersIdsList = []
    if(!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        const order = doc.data()
        order.orderId = doc.id
        userOrdersList.push(order)
        userOrdersIdsList.push(order.orderId)
      })
    }
    clog(`userOrdersList`)
    clog(JSON.stringify(userOrdersIdsList, null, 2))
    for(let i in userOrdersIdsList) {
      const orderId = userOrdersIdsList[i]
      const docRef = doc(appDb, `orders/${orderId}`)

      clog(`orderId = ${orderId}`)

      await updateDoc(docRef, {
        pixStatus: 'CONCLUIDA'
      })
    }
  }

  const deleteOwnOrders = async () => {

    const colNale = 'productsOrders'
    const gasparID = 'YETftQD0Y5goflAqDPAixTY3O2h2'

    const ordersRef = collection(appDb, colNale)
    const q1 = query(ordersRef, where('userId', '==', gasparID))
    const querySnapshot = await getDocs(q1)
    clog(`TAMANHO col = ${querySnapshot.size}`)
    let userOrdersList = []
    if(!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        const order = doc.data()
        order.orderId = doc.id
        userOrdersList.push(order)
      })
    }
    clog(`userOrdersList TAM: ${userOrdersList.length}`)
    clog(JSON.stringify(userOrdersList, null, 2))
    userOrdersList.forEach(async order => {
      const orderId = order.orderId
      clog(`orderId = ${orderId}`)
      await deleteDoc(doc(appDb, `/${colNale}/${orderId}`))
    })
  }

  const doSomething = async () => {
    const currentUser = currentAppUser
    clog(currentAppUser.uid)
    const backend = 'app'
    const emulator = false
    const cloudFunction = 'generatePixCode'
    // const cloudFunction = 'generatePixCode'
    const method = 'get'
    const queryData = {
      uid: currentAppUser.uid,
      amount: '51.40',
      orderId: 'XxxxxxxxxxxxxaaaaaaaaaZ',
    }
    // const resp = await fetchCloudFunction(currentUser, backend, emulator, cloudFunction, queryData)
    const resp = await axiosFetchCloudFunction(currentUser, backend, emulator, cloudFunction, method, queryData)
    const pixQrCode = resp.data.pixQrCode
    cloj(resp)
    cloj('pixQrCode')
    cloj(pixQrCode)

    
  }

  //ATENTION: this function get all the coins from the data json "allCoins" and add it to the firebase collection cryptocurrencies
  const updateAllCoinsToFirebase = async() => {
    for await (const coin of allCoinsData) {
      try {
        await setDoc(doc(appV2Db, `cryptocurrencies/${coin.symbol}`), coin)
        clog(`Document written with ID: ${coin.symbol}`)
      }
      catch(error) {
        clog(`Error creating Firestore document for ${coin.symbol}:`)
        clog(error)
      }
    }
  }


  const symbolPrices = useRef({})
  const calculateUsersBalance = async () => {

    const getPairPrice = async (pairSymbol, timePriceReference) => {
      if(pairSymbol + timePriceReference in symbolPrices.current) {
        return symbolPrices.current[pairSymbol + timePriceReference]
      }
      try {
        const pairPrice = await getPairSymbolPrice(currentAdminUser, pairSymbol, timePriceReference)
        const prevSymbolPrices = symbolPrices.current
        symbolPrices.current = {
          ...prevSymbolPrices,
          [pairSymbol + timePriceReference]: pairPrice
        }
        return pairPrice
      }
      catch(error) {
        try {
          const pairPrice = await getPairSymbolPrice(currentAdminUser, pairSymbol, timePriceReference)
          const prevSymbolPrices = symbolPrices.current
          symbolPrices.current = {
            ...prevSymbolPrices,
            [pairSymbol + timePriceReference]: pairPrice
          }
          return pairPrice
        }
        catch(error) {
          clog('ERROR 2:')
          console.error(error)
        }
      }
    }

    const QUOTE_SYMBOL = 'USDT'

    const currentTimeDate = Math.round(Date.now() / 1000) - 1
    const dolarToBrl = await getPairPrice('USDTBRL', currentTimeDate)
    clog(`dolarToBrl = ${dolarToBrl}`)

    const usersRef = collection(appDb, 'users')
    const usersQuerySnap = await getDocs(usersRef)
    let usersIds = []
    usersQuerySnap.forEach(doc => {
      usersIds.push(doc.id)
    })
    clog('INICIOO')
    let totalInUsd = 0
    let totalBNBQty = 0
    for(const userId of usersIds) {
      const userBasketsRef = collection(appDb, `/users/${userId}/userBaskets`)
      const q1 = query(userBasketsRef, where('status', '==', 'FULFILLED'))
      const usersBasketsQuerySnap = await getDocs(q1)
      if(usersBasketsQuerySnap.size === 0) {
        continue        
      }
      let coinsList = []
      clog(`TOTAAL = ${usersBasketsQuerySnap.size}`)
      usersBasketsQuerySnap.forEach(doc => {
        const userBasket = doc.data()
        const coins = userBasket.coins
        coinsList.push(coins)
      })
      for(const coins of coinsList) {
        for(const symbol in coins) {
          const amount = coins[symbol].amount
          clog(`amount of ${symbol} = ${amount}`)
          const pairSymbol = symbol + QUOTE_SYMBOL
          const currentTimeDate = Math.round(Date.now() / 1000) - 1
          const price = await getPairPrice(pairSymbol, currentTimeDate)
          clog(`price of ${pairSymbol} = ${price}`)
          const partialValue = price * amount
          totalInUsd += partialValue
          if(symbol === 'BNB') {
            totalBNBQty += amount
          }
        }
      }
    }
    clog(`totalInUsd = ${totalInUsd}`)
    clog(`totalBNBQty = ${totalBNBQty}`)

  }

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Choose an option</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <strong>You're logged in as:</strong> {currentAdminUser.email}
          {/* {permitted && <Link to="/users-buy-orders" className="btn btn-primary w-100 mt-3">
            Users Buy Orders
          </Link>} */}
          <Link to="/dashboard" className="btn btn-primary w-100 mt-3">
          Dashboard App V1
          </Link>
          <Link to="/dashboardappv2" className="btn btn-primary w-100 mt-3">
            Dashboard App V2
          </Link>
          
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </>
  )
}