import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { appV2Db, collection, doc, getDoc, getDocs, query, updateDoc } from '../firebase'

import { formatPercentage } from '@mauchero/helpers'
import { clog } from '../auxiliary/helpers'
import { arrayRemove, arrayUnion } from 'firebase/firestore'

export default function ManageAppData() {

  const MAX_PERCENTAGE_VARIATION = 0.01
  
  const [ready, setReady] = useState(false)
  const [waitMessage, setWaitMessage] = useState('')
  const [error, setError] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const [appFees, setAppFees] = useState({tokensFee: 0, cryptocurrenciesFee: 0})
  const [appDisclaimers, setAppDisclaimers] = useState({forCheckoutBuy: '', forCheckoutSell: '', forProfile: ''})
  const [appMinimumValues, setAppMinimumValues] = useState({basketBuyMinValue: '', cryptoMinValue: '', minAgeToUseApp: '', minBalanceToShow: ''})
  const [appFilteredUsers, setAppFilteredUsers] = useState([])
  const [newFilteredUser, setNewFilteredUser] = useState('')
  const [appFrequentQuestions, setAppFrequentQuestions] = useState([])
  const [newQuestion, setNewQuestion] = useState('')
  const [newAnswer, setNewAnswer] = useState('')

  const [appData, setAppData] = useState([])

  useEffect(() => {
    
    fetchAppData();

  }, [])

  const fetchAppData = async () => {
    try {

      let ordersTotal = 0
      setError('')
      setErrorMsg('')
      setWaitMessage('Aguarde...')
      clog(`BUSCANDO APP DATA...`)

      const appDataRef = collection(appV2Db, 'appData')
      const appDataSnapshot = await getDocs(appDataRef)
      if(!appDataSnapshot.empty) {
        appDataSnapshot.forEach((doc) => {
          const appData = doc.data();
          if(doc.id === 'fees') {
            setAppFees(appData)
          } else if(doc.id === 'disclaimers') {
            setAppDisclaimers(appData)
          } else if (doc.id === 'minimumValues') {
            setAppMinimumValues(appData)
          } else if(doc.id === 'filteredUsers') {
            setAppFilteredUsers(appData.usersIds)
          } else if(doc.id === 'frequentAskedQuestions') {
            const questions = appData.questions.map((item) => {
              return {...item, editingAnswer: false, editingQuestion: false}
            })
            setAppFrequentQuestions(questions)
          }
          appData.appDataId = doc.id;
          setAppData(item => [...item, appData])
        })
      }

      
      setReady(prev => !prev)
      setWaitMessage('')
    } catch(error) {
      setErrorMsg('Erro: tente novamente. Caso o erro persista, entre em contato.')
      setError(error)
      setWaitMessage('')
    }
  }

  

  // -------------------------------------------- Update Fees ------------------------------------------- //

  const handleFeesInputChange = (e, type) => {
    e.preventDefault()

    const value = e.target.value;
    if(type === 'token') {
      setAppFees(prev => ({...prev, tokensFee: value}))
    } else if(type === 'crypto') {
      setAppFees(prev => ({...prev, cryptocurrenciesFee: value}))
    }
    
  }

  const updateFeesValue = async (type, newValue) => {
    setError('')
    setWaitMessage('Aguarde...')
    const floatNewValue = parseFloat(newValue);

    
    const feesRef = doc(appV2Db, `appData/fees`)

    try{
      if(type === "crypto"){
        await updateDoc(feesRef, {
          cryptocurrenciesFee: floatNewValue
        })
        setAppFees(prev => ({...prev, cryptocurrenciesFee: floatNewValue}))
        alert('Valor da taxa de crypto atualizada com sucesso!')
      } else if(type === "token") {
        await updateDoc(feesRef, {
          tokensFee: floatNewValue
        })
        setAppFees(prev => ({...prev, tokensFee: floatNewValue}))
        alert('Valor da taxa de token atualizada com sucesso!')
      }

      setWaitMessage('')
    } catch(error) {
      console.error('>> error')
      console.error(JSON.stringify(error))
      setWaitMessage('')
      setError('Erro: tente novamente. Caso o erro persista, entre em contato.')
    }


  }

  // -------------------------------------------- Update Disclaimers ------------------------------------------- //

  const handleDisclaimersInputChange = (e, type) => {
    e.preventDefault()

    const value = e.target.value;
    if(type === 'checkoutbuy') {
      setAppDisclaimers(prev => ({...prev, forCheckoutBuy: value}))
    } else if(type === 'checkoutsell') {
      setAppDisclaimers(prev => ({...prev, forCheckoutSell: value}))
    } else if(type === 'profile') {
      setAppDisclaimers(prev => ({...prev, forProfile: value}))
    }
    
  }

  const updateDisclaimersValue = async (type, newValue) => {
    setError('')
    setWaitMessage('Aguarde...')

    
    const disclaimersRef = doc(appV2Db, `appData/disclaimers`)

    try{
      if(type === "checkoutbuy"){
        await updateDoc(disclaimersRef, {
          forCheckoutBuy: newValue
        })
        setAppDisclaimers(prev => ({...prev, forCheckoutBuy: newValue}))
        alert('Disclaimer checkout buy atualizado com sucesso!')
      } else if(type === "checkoutsell") {
        await updateDoc(disclaimersRef, {
          forCheckoutSell: newValue
        })
        setAppFees(prev => ({...prev, forCheckoutSell: newValue}))
        alert('Disclaimer checkout sell atualizado com sucesso!')
      } else if(type === "profile") {
        await updateDoc(disclaimersRef, {
          forProfile: newValue
        })
        setAppFees(prev => ({...prev, forProfile: newValue}))
        alert('Disclaimer profile atualizado com sucesso!')
      }

      setWaitMessage('')
    } catch(error) {
      console.error('>> error')
      console.error(JSON.stringify(error))
      setWaitMessage('')
      setError('Erro: tente novamente. Caso o erro persista, entre em contato.')
    }


  }


  // -------------------------------------------- Update MinimumValues ------------------------------------------- //

  const handleMinimumValuesInputChange = (e, type) => {
    e.preventDefault()

    const value = e.target.value;
    if(type === 'basketbuyminvalue') {
      setAppMinimumValues(prev => ({...prev, basketBuyMinValue: value}))
    } else if(type === 'cryptominvalue') {
      setAppMinimumValues(prev => ({...prev, cryptoMinValue: value}))
    } else if(type === 'minagetouseapp') {
      setAppMinimumValues(prev => ({...prev, minAgeToUseApp: value}))
    } else if(type === 'minbalancetoshow') {
      setAppMinimumValues(prev => ({...prev, minBalanceToShow: value}))
    }
    
  }

  const updateMinimumValues = async (type, newValue) => {
    setError('')
    setWaitMessage('Aguarde...')

    const floatNewValue = parseFloat(newValue);

    
    const minValuesRef = doc(appV2Db, `appData/minimumValues`)

    try{
      if(type === "basketbuyminvalue"){
        await updateDoc(minValuesRef, {
          basketBuyMinValue: floatNewValue
        })
        setAppDisclaimers(prev => ({...prev, forCheckoutBuy: floatNewValue}))
        alert('Valor minimo de compra para cesta atualizado com sucesso!')
      } else if(type === "cryptominvalue") {
        await updateDoc(minValuesRef, {
          cryptoMinValue: floatNewValue
        })
        setAppDisclaimers(prev => ({...prev, forCheckoutSell: floatNewValue}))
        alert('Valor minimo de crypto atualizado com sucesso!')
      } else if(type === "minagetouseapp") {
        await updateDoc(minValuesRef, {
          minAgeToUseApp: floatNewValue
        })
        setAppDisclaimers(prev => ({...prev, forProfile: floatNewValue}))
        alert('Valor de idade minima atualizado com sucesso!')
      } else if(type === "minbalancetoshow") {
        await updateDoc(minValuesRef, {
          minBalanceToShow: floatNewValue
        })
        setAppDisclaimers(prev => ({...prev, forProfile: floatNewValue}))
        alert('Valor minimo de saldo atualizado com sucesso!')
      }

      setWaitMessage('')
    } catch(error) {
      console.error('>> error')
      console.error(JSON.stringify(error))
      setWaitMessage('')
      setError('Erro: tente novamente. Caso o erro persista, entre em contato.')
    }


  }

  // -------------------------------------------- Filtered Users ------------------------------------------- //

  const handleAddFilteredUser = async () => {
    setError('')
    setWaitMessage('Aguarde...')

    const filteredUsersRef = doc(appV2Db, `appData/filteredUsers`)

    try{
      await updateDoc(filteredUsersRef, {
        usersIds: arrayUnion(newFilteredUser)
      })
      setAppFilteredUsers((prev) => [...prev, newFilteredUser])
      setWaitMessage('')
      setNewFilteredUser('')
    } catch(error) {
      console.error('>> error')
      console.error(JSON.stringify(error))
      setWaitMessage('')
      setError('Erro: tente novamente. Caso o erro persista, entre em contato.')
    }
  }

  const handleRemoveFilteredUser = async(user) => {
    setError('')
    setWaitMessage('Aguarde...')

    const filteredUsersRef = doc(appV2Db, `appData/filteredUsers`)

    try{
      await updateDoc(filteredUsersRef, {
        usersIds: arrayRemove(user)
      })
      const newUsers = appFilteredUsers.filter((item) => item !== user)
      setAppFilteredUsers(newUsers)
      setWaitMessage('')
      setNewFilteredUser('')
    } catch(error) {
      console.error('>> error')
      console.error(JSON.stringify(error))
      setWaitMessage('')
      setError('Erro: tente novamente. Caso o erro persista, entre em contato.')
    }
  }

  // -------------------------------------------- Frequent Questions ------------------------------------------- //

  const handleQuestionsChange = (value, index, type) => {
    
    if(type === 'answer'){
      const correctedQuestions = appFrequentQuestions.map((item, i) => {
        if(i === index) {
          return {...item, answer: value}
        } else {
          return item
        }
      })
      setAppFrequentQuestions(correctedQuestions)
    } else if(type === 'question'){
      const correctedQuestions = appFrequentQuestions.map((item, i) => {
        if(i === index) {
          return {...item, question: value}
        } else {
          return item
        }
      })
      setAppFrequentQuestions(correctedQuestions)
    }
    

  }

  const handleQuestionUpdate = async (questionIndex, newValue, type) => {
    const docRef = doc(appV2Db, "appData/frequentAskedQuestions");
    const docSnap = await getDoc(docRef);
    const docData = docSnap.data();

    if(type === 'answer'){
      const updatedData = {
        ...docData,
        questions: docData.questions.map((frequentQuestion, index) => {
          if(index === questionIndex) {
            return {
              ...frequentQuestion,
              answer: newValue
            }
          }
          return frequentQuestion
        })
      }

      try {
        await updateDoc(docRef, updatedData)
        setAppFrequentQuestions((prev) => [...prev, prev[questionIndex].editingAnswer = false])
        alert('Resposta atualizada com sucesso!')
  
      } catch (error) {
        alert('Erro: tente novamente. Caso o erro persista, entre em contato.')
        console.error("Error updating document: ", error);
        return;
      }
    }

    else if(type === 'question'){
      const updatedData = {
        ...docData,
        questions: docData.questions.map((frequentQuestion, index) => {
          if(index === questionIndex) {
            return {
              ...frequentQuestion,
              question: newValue
            }
          }
          return frequentQuestion
        })
      }

      try {
        await updateDoc(docRef, updatedData)
        setAppFrequentQuestions((prev) => [...prev, prev[questionIndex].editingQuestion = false])
        alert('Pergunta atualizada com sucesso!')
  
      } catch (error) {
        alert('Erro: tente novamente. Caso o erro persista, entre em contato.')
        console.error("Error updating document: ", error);
        return;
      }
    }
  }

  const handleAddFrequentQuestion = async () => {
    setError('')
    setWaitMessage('Aguarde...')

    const frequentQuestionsRef = doc(appV2Db, `appData/frequentAskedQuestions`)

    const newData = {question: newQuestion, answer: newAnswer, order: appFrequentQuestions.length + 1}

    try{
      await updateDoc(frequentQuestionsRef, {
        questions: arrayUnion(newData)
      })
      setAppFrequentQuestions((prev) => [...prev, newData])
      setWaitMessage('')
      setNewAnswer('')
      setNewQuestion('')
    } catch(error) {
      console.error('>> error')
      console.error(JSON.stringify(error))
      setWaitMessage('')
      setError('Erro: tente novamente. Caso o erro persista, entre em contato.')
    }
  }

  const handleRemoveFrequentQuestion = async(questionIndex) => {
    const result = window.confirm('Você tem certeza que deseja excluir?')
    if(result) {
      const frequentQuestionsRef = doc(appV2Db, `appData/frequentAskedQuestions`)
      const updatedQuestions = appFrequentQuestions.map((item, index) => {
        if(index !== questionIndex) {
          return {question: item.question, answer: item.answer, order: index + 1}
        }
      }).filter((item) => item !== undefined)

      try{
        await updateDoc(frequentQuestionsRef, {
          questions: updatedQuestions
        })
        setWaitMessage('')
        setAppFrequentQuestions(updatedQuestions)
        alert('Excluido')
      } catch(error) {
        console.error('>> error')
        console.error(JSON.stringify(error))
        setWaitMessage('')
        setError('Erro: tente novamente. Caso o erro persista, entre em contato.')
      }
      
    } else{
      alert('Não excluido')
    }
  }

  const handleShowQuestionAndAnswer = (questionIndex, type) => {
    if(type === 'answer'){
      const frequentQuestions = appFrequentQuestions.map((item, index) => {
        if(index === questionIndex) {
          return {...item, editingAnswer: item.editingAnswer ? false : true}
        } else {
          return item
        }
      })
      setAppFrequentQuestions(frequentQuestions)
    } else if(type === 'question') {
      const frequentQuestions = appFrequentQuestions.map((item, index) => {
        if(index === questionIndex) {
          return {...item, editingQuestion: item.editingQuestion ? false : true}
        } else {
          return item
        }
      })
      setAppFrequentQuestions(frequentQuestions)
    }
  }


  // --------------------------------------------  ------------------------------------------- //


  const fees = appData.map((item, index) => {
    
    if(item.appDataId === 'fees') {
      return (
        <div>
          {/* <p>{item.tokensFee}</p>
          <p>{item.cryptocurrenciesFee}</p> */}
          <p>Tokens Fee</p>
          <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '6%', alignItems: 'center'}}>
            <input type="number" value={appFees.tokensFee} placeholder={item.tokensFee} onChange={(e) => {handleFeesInputChange(e, "token")} }></input>
            <Button disabled={!appFees.tokensFee} onClick={() => {updateFeesValue("token", appFees.tokensFee)}}>Editar valor</Button>
          </div>
          <p>Crypto Fee</p>
          <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '6%', alignItems: 'center'}}>
            <input type="number" value={appFees.cryptocurrenciesFee} placeholder={item.cryptocurrenciesFee} onChange={(e) => {handleFeesInputChange(e, "crypto")} }></input>
            <Button disabled={!appFees.cryptocurrenciesFee} onClick={() => {updateFeesValue("crypto", appFees.cryptocurrenciesFee)}}>Editar valor</Button>
          </div>
          <div style={{height: 1, backgroundColor: '#969696', width: '100%', marginTop: '3%', marginBottom: '3%'}}></div>
        </div>
      )
    }
   
  })

  const disclaimers = appData.map((item, index) => {
    
    if(item.appDataId === 'disclaimers') {
      return (
        <div>
          <p>Checkout Buy</p>
          <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '6%', alignItems: 'center'}}>
            <textarea type="text" value={appDisclaimers.forCheckoutBuy} placeholder={item.forCheckoutBuy} onChange={(e) => {handleDisclaimersInputChange(e, "checkoutbuy")}}></textarea>
            <Button disabled={!appDisclaimers.forCheckoutBuy} onClick={() => {updateDisclaimersValue("checkoutbuy", appDisclaimers.forCheckoutBuy)}}>Editar valor</Button>
          </div>
          <p>Checkout Sell</p>
          <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '6%', alignItems: 'center'}}>
            <textarea type="text" value={appDisclaimers.forCheckoutSell} placeholder={item.forCheckoutSell} onChange={(e) => {handleDisclaimersInputChange(e, "checkoutsell")}}></textarea>
            <Button disabled={!appDisclaimers.forCheckoutSell} onClick={() => {updateDisclaimersValue("checkoutsell", appDisclaimers.forCheckoutSell)}}>Editar valor</Button>
          </div>
          <p>Profile</p>
          <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '6%', alignItems: 'center'}}>
            <textarea type="text" value={appDisclaimers.forProfile} placeholder={item.forProfile} onChange={(e) => {handleDisclaimersInputChange(e, "profile")}}></textarea>
            <Button disabled={!appDisclaimers.forProfile} onClick={() => {updateDisclaimersValue("profile", appDisclaimers.forProfile)}}>Editar valor</Button>
          </div>
          <div style={{height: 1, backgroundColor: '#969696', width: '100%', marginTop: '3%', marginBottom: '3%'}}></div>
        </div>
      )
    }
   
  })

  const minimumValues = appData.map((item, index) => {
    
    if(item.appDataId === 'minimumValues') {
      return (
        <div>
          <p>Basket Buy Minimum Value</p>
          <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '6%', alignItems: 'center'}}>
            <input type="number" value={appMinimumValues.basketBuyMinValue} placeholder={item.basketBuyMinValue} onChange={(e) => {handleMinimumValuesInputChange(e, "basketbuyminvalue")} }></input>
            <Button disabled={!appMinimumValues.basketBuyMinValue} onClick={() => {updateMinimumValues("basketbuyminvalue", appMinimumValues.basketBuyMinValue)}}>Editar valor</Button>
          </div>
          <p>Crypto Minimum Value</p>
          <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '6%', alignItems: 'center'}}>
            <input type="number" value={appMinimumValues.cryptoMinValue} placeholder={item.cryptoMinValue} onChange={(e) => {handleMinimumValuesInputChange(e, "cryptominvalue")} }></input>
            <Button disabled={!appMinimumValues.cryptoMinValue} onClick={() => {updateMinimumValues("cryptominvalue", appMinimumValues.cryptoMinValue)}}>Editar valor</Button>
          </div>
          <p>Minimum Age to Use App</p>
          <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '6%', alignItems: 'center'}}>
            <input type="number" value={appMinimumValues.minAgeToUseApp} placeholder={item.minAgeToUseApp} onChange={(e) => {handleMinimumValuesInputChange(e, "minagetouseapp")} }></input>
            <Button disabled={!appMinimumValues.minAgeToUseApp} onClick={() => {updateMinimumValues("minagetouseapp", appMinimumValues.minAgeToUseApp)}}>Editar valor</Button>
          </div>
          <p>Minimum Balance To Show</p>
          <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '6%', alignItems: 'center'}}>
            <input type="number" value={appMinimumValues.minBalanceToShow} placeholder={item.minBalanceToShow} onChange={(e) => {handleMinimumValuesInputChange(e, "minbalancetoshow")} }></input>
            <Button disabled={!appMinimumValues.minBalanceToShow} onClick={() => {updateMinimumValues("minbalancetoshow", appMinimumValues.minBalanceToShow)}}>Editar valor</Button>
          </div>
          <div style={{height: 1, backgroundColor: '#969696', width: '100%', marginTop: '3%', marginBottom: '3%'}}></div>
        </div>
      )
    }
   
  })

  return (
    <>
      <Card>
      <br></br>
      <br></br>
      <br></br>
        {waitMessage && <Alert variant='warning'>{waitMessage}</Alert>}
        {errorMsg && <Alert variant='warning'>{errorMsg}</Alert>}
        <br></br>
        <br></br>
        <Card.Body>
          <h2 className='text-center mb-4'>Gerenciar dados do App</h2>
          <br></br>
          <br></br>
          <Card style={{ width: '40rem', padding: '1rem' }}>
            <h3>Fees</h3>
            {fees}
            <h3>Disclaimers</h3>
            {disclaimers}
            <h3>Minimum Values</h3>
            {minimumValues}
            <h3>Filtered Users List</h3>
            {appFilteredUsers.length > 0 && appFilteredUsers.map((user, index) => {
              return (
                <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '4%', alignItems: 'center'}}>
                  <p>{user}</p>
                  <Button onClick={() => handleRemoveFilteredUser(user)}>Remover Usuário</Button>
                </div>
              )
            })}
            {appFilteredUsers.length === 0 && <p>No users</p>}
            <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginTop: '5%'}}>
              <input type="text" value={newFilteredUser} placeholder={'Id do novo usuário'} onChange={(e) => {setNewFilteredUser(e.target.value)}}></input>
              <Button disabled={!newFilteredUser} onClick={() => handleAddFilteredUser()}>Add Novo Usuário</Button>
            </div>
            <div style={{height: 1, backgroundColor: '#969696', width: '100%', marginTop: '3%', marginBottom: '3%'}}></div>
            <h3>Frequent Questions</h3>
            <p style={{color: 'blue'}}>Clique na pergunta ou resposta em que deseja editar</p>
            {appFrequentQuestions.length > 0 && appFrequentQuestions.map((question, index) => {
              return (
                <div>
                  <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginBottom: '4%', alignItems: 'center'}}>
                    {!question.editingQuestion && <p>{question.question}</p>}
                    {question.editingQuestion && <div style={{display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center'}}>
                      <textarea value={appFrequentQuestions[index].question} onChange={(e) => handleQuestionsChange(e.target.value, index,'question')}></textarea>
                      <Button onClick={() => {handleQuestionUpdate(index, appFrequentQuestions[index].question, "question")}}>Atualizar Pergunta</Button>  
                    </div>}
                    {!question.editingAnswer && <p>{question.answer}</p>}
                    {question.editingAnswer && <div style={{display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center'}}>
                      <textarea value={appFrequentQuestions[index].answer} onChange={(e) => handleQuestionsChange(e.target.value, index,'answer')}></textarea>
                      <Button onClick={() => {handleQuestionUpdate(index, appFrequentQuestions[index].answer, "answer")}}>Atualizar Resposta</Button>  
                    </div>}
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', gap: 15}}>
                    <Button variant='danger' onClick={() => handleRemoveFrequentQuestion(index)}>Excluir</Button>
                    {!question.editingQuestion && <Button onClick={() => handleShowQuestionAndAnswer(index, 'question')}>Editar - Pergunta</Button>}
                    {question.editingQuestion && <Button onClick={() => handleShowQuestionAndAnswer(index, 'question')}>Cancelar Edição - Pergunta</Button>}
                    {!question.editingAnswer && <Button onClick={() => handleShowQuestionAndAnswer(index, 'answer')}>Editar - Resposta</Button>}
                    {question.editingAnswer && <Button onClick={() => handleShowQuestionAndAnswer(index, 'answer')}>Cancelar Edição - Resposta</Button>}
                  </div>
                    <div style={{height: 1, backgroundColor: '#d1d1d1', width: '100%', marginTop: '3%', marginBottom: '3%'}}></div>
                </div>
              )
            })}
            <div style={{display: 'flex', flexDirection: 'row', gap: 20, marginTop: '5%'}}>
              <textarea type="text" value={newQuestion} placeholder={'Nova pergunta'} onChange={(e) => {setNewQuestion(e.target.value)}}></textarea>
              <textarea type="text" value={newAnswer} placeholder={'Nova resposta'} onChange={(e) => {setNewAnswer(e.target.value)}}></textarea>
              <Button disabled={!newAnswer || !newQuestion} onClick={() => handleAddFrequentQuestion()}>Adicionar Nova Questão</Button>
            </div>
          </Card>
        
          <br></br>
        </Card.Body>
      </Card>
      <div className='w-100 text-center mt-2'>
        <Link to='/dashboardappv2'>Voltar</Link>
      </div>
    </>
  )
}